// Breakpoints
$larger-than-mobile: "min-width: 426px" !default;
$larger-than-phablet: "min-width: 640px" !default;
$larger-than-phablet-plus-one: "min-width: 641px" !default;
$larger-than-tablet: "min-width: 750px" !default;
$larger-than-ipad: "min-width: 833px" !default;
$smaller-than-ipad: "max-width: 832.9px" !default;
$larger-than-desktop: "min-width: 1025px" !default;
$larger-than-desktophd: "min-width: 1200px" !default;
$desktop-header-burger: "max-width: 1311.9px" !default;
$desktop-header: "min-width: 1312px" !default;
$desktop-1399-break-point: "1399.9px";
$desktop-wide-screen: "1920px";
$old-mobile-min: "320px";
$mobile-min: "426px";
$phablet-min-width: "640px";
$phablet-min-width-plus-one: "641px";
$phablet-min-width-minus-one: "639px";
$phablet-min-width-minus: "639.9px";
$mobile-min-width-minus: "414px";
$desktop-min-width-minus: "1024.9px";
$tablet-min-width: "750px";
$tablet-min-width-minus: "749.9px";
$tablet-772-brek-point: "772px";
$ipad-min-width: "835px";
$ipad-min-width-minus: "833.9px";
$desktop-min-width: "1025px";
$desktophd-min-width: "1200px";
$iphone-max-width: "475.9px";

// Responsive Grid System
$grid__bp-sm: 426;
$grid__bp-md: 640;
$grid__bp-lg: 750;
$grid__bp-xl: 1025;
$grid__bp-max: 1360;
$grid__cols: 12;

// Primary Colors
$primary-color: #EF426F !default;
$primary-transparent: #ef426f05;
$primary-opacity-80: #F2688C;
$primary-opacity-60: #F58EA9;
$primary-opacity-40: #F9B3C5;
$primary-opacity-20: #FCD9E2;
$primary-opacity-10: #FDECF1;
$primary-opacity-5: #FEF6F8;
$primary-shade-900: #76032C;
$primary-shade-800: #9A003B;
$primary-shade-700: #BB0B4B;
$primary-shade-600: #D7285D;
$primary-shade-500: #EF4671;
$primary-shade-400: #FF6686;
$primary-shade-300: #FF879E;
$primary-shade-200: #FFA9B8;
$primary-shade-100: #FFCBD3;
$primary-shade-50: #FFEEF0;

// Secondary Colors
$secondary-color: #FFB81C !default;
$secondary-opacity-80: #FFC649;
$secondary-opacity-60: #FFD477;
$secondary-opacity-40: #FFE3A4;
$secondary-opacity-20: #FFF1D2;
$secondary-opacity-10: #FFF8E8;
$secondary-opacity-5: #FFFCF4;
$secondary-shade-900: #553200;
$secondary-shade-800: #6D4200;
$secondary-shade-700: #865400;
$secondary-shade-600: #9E6700;
$secondary-shade-500: #B67B00;
$secondary-shade-400: #CC9000;
$secondary-shade-300: #E0A73D;
$secondary-shade-200: #F0BF70;
$secondary-shade-100: #FAD8A6;
$secondary-shade-50: #FFF2E1;

// Third Colors Dark
$third-color-dark: #253746 !default;
$third-dark-opacity-80: #515F6B;
$third-dark-opacity-60: #7C8790;
$third-dark-opacity-40: #A8AFB5;
$third-dark-opacity-20: #D3D7DA;
$third-dark-opacity-10: #E9EBEC;
$third-dark-opacity-5: #F4F5F6;
$third-dark-shade-900: #2F3A45;
$third-dark-shade-800: #3E4D5A;
$third-dark-shade-700: #4F6070;
$third-dark-shade-600: #627485;
$third-dark-shade-500: #76889A;
$third-dark-shade-400: #8C9DAD;
$third-dark-shade-300: #A4B2C0;
$third-dark-shade-200: #BDC8D3;
$third-dark-shade-100: #D7DEE5;
$third-dark-shade-50: #F1F4F6;

// Third Colors Light
$third-color-light: #A4BCC2 !default;
$third-light-opacity-80: #B6C9CE;
$third-light-opacity-60: #C8D7DA;
$third-light-opacity-40: #DBE4E7;
$third-light-opacity-20: #EDF2F3;
$third-light-opacity-10: #F6F8F9;
$third-light-opacity-5: #FBFCFC;
$third-light-shade-900: #2E3B3F;
$third-light-shade-800: #3C4E53;
$third-light-shade-700: #4D6267;
$third-light-shade-600: #5F767B;
$third-light-shade-500: #748A90;
$third-light-shade-400: #8A9FA4;
$third-light-shade-300: #A2B4B8;
$third-light-shade-200: #BBC9CC;
$third-light-shade-100: #D6DEE1;
$third-light-shade-50: #F1F4F5;


// Colors
$grey: #75787B !default;
$grey-transparent-01: #4b4b4b1a;
$grey-transparent-02: #4b4b4b33;
$grey-transparent-03: #4b4b4b08;
$grey-transparent-04: #4b4b4b0a;
$grey-transparent-06: #4b4b4b0f;
$grey-transparent-07: #4b4b4b12;
$grey-transparent-08: #4b4b4b14;
$white: #FFF !default;
$black: #000000 !default;
$black-opacity-90: #333;
$black-opacity-80: #666;
$black-opacity-70: #999;
$black-opacity-60: #CCC;
$black-opacity-50: #E5E5E5;
$black-opacity-40: #F2F2F2;
$black-opacity-20: #F9F9F9;
$black-opacity-10: #FCFCFC;
$black-opacity-5: #FEFEFE;
$black-shade-900: #393939;
$black-shade-800: #4B4B4B;
$black-shade-700: #5E5E5E;
$black-shade-600: #727272;
$black-shade-500: #868686;
$black-shade-400: #9B9B9B;
$black-shade-300: #B0B0B0;
$black-shade-200: #C6C6C6;
$black-shade-100: #DDD;
$black-shade-50: #F3F3F3;

$border-color: #979797 !default;
$link-color: $primary-color !default;
$text-color: $black !default;
$input-border: #bebebe !default;

$footer-bg: #042025 !default;
$footer-bg-alt: #446369 !default;
$header-bg: #ffffff !default;

$green: #00D856 !default;
$green-opacity-80: #33E078;
$green-opacity-60: #66E89A;
$green-opacity-40: #99EFBC;
$green-opacity-20: #CCF7DD;
$green-opacity-10: #E5FBEE;
$green-opacity-5: #F2FDF7;
$green-shade-900: #004700;
$green-shade-800: #005D00;
$green-shade-700: #007300;
$green-shade-600: #008A01;
$green-shade-500: #009F23;
$green-shade-400: #00B442;
$green-shade-300: #4EC866;
$green-shade-200: #84D98D;
$green-shade-100: #B6EAB8;
$green-shade-50: #E7F8E7;

$orange: #F58520 !default;
$orange-opacity-80: #F79D4D;
$orange-opacity-60: #F9B679;
$orange-opacity-40: #FBCEA6;
$orange-opacity-20: #FDE7D2;
$orange-opacity-10: #FEF3E9;
$orange-opacity-5: #FFF9F4;
$orange-shade-900: #642700;
$orange-shade-800: #823300;
$orange-shade-700: #9F4300;
$orange-shade-600: #BA5500;
$orange-shade-500: #D26A00;
$orange-shade-400: #E7812A;
$orange-shade-300: #F79B52;
$orange-shade-200: #FFB67E;
$orange-shade-100: #FFD2AF;
$orange-shade-50: #FFF0E4;

$red: #D80000 !default;
$red-opacity-80: #E03333;
$red-opacity-60: #E86666;
$red-opacity-40: #EF9999;
$red-opacity-20: #F7CCCC;
$red-opacity-10: #FBE5E5;
$red-opacity-5: #FDF2F2;
$red-shade-900: #7C0000;
$red-shade-800: #A10000;
$red-shade-700: #C30000;
$red-shade-600: #E21909;
$red-shade-500: #FB3D21;
$red-shade-400: #FF5F3E;
$red-shade-300: #FF8260;
$red-shade-200: #FFA688;
$red-shade-100: #FFCAB5;
$red-shade-50: #FFEDE6;

$pink: #E02859 !default;

// Button Action Colors
$hover: $secondary-shade-300;
$focus: $primary-shade-800;

// Statuses Colors
$done: $green-shade-300;
$new: $primary-shade-300;
$pending: $orange-shade-300;
$failed: $red-shade-400;

// Google Fonts Import
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);

// aktiv-grotesk Font Import From Adobe
@import url(https://use.typekit.net/soc5hge.css);

// Base Font
$base-font-family: "aktiv-grotesk", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$secondary-font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

// Font Weights
$light: 300;
$regular: 400;
$bold: 700;
$extra-bold: 800;

// Misc
$global-radius: 1px !default;
$assets-path: "/assets/img/" !default;