@import "../modules/all";

// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Summit
// are based on 10px sizing. So basically 1.5rem = 15px :)

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-size: 17px; // currently ems cause chrome bug misinterpreting rems on body element
  line-height: 1.18;
  font-weight: normal;
  font-family: $base-font-family;
  color: $black;
  max-width: 2000px;
  margin: 0 auto;
  overflow-x: hidden;

  @media (#{$larger-than-mobile}) {
    font-size: 13px;
    line-height: 1.18;
  }

  @media (#{$larger-than-desktop}) {
    font-size: 22px;
  }
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: darken($link-color, 5%);
  }
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $grey;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

[class*="col"] {

  img {
    max-width: 100%;
  }
}

input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    font-family: $base-font-family;
    height: 45px!important;
    padding: 22px 20px!important;
    background-color: #fff;
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    display: inline-block;
  }
}
