.modal-open {
  overflow: hidden;

  .account-nav-bar {
    z-index: 1;
  }
}

.cdk-global-scrollblock {
  position: unset !important;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 42%) !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
  width: auto!important;
}

.mat-dialog-container,
.mat-mdc-dialog-container,
.mdc-dialog__surface {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 19px !important;
}

.modal {
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;
  transition: opacity, visibility 0.3s 0.12s;
  max-width: 90vw;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (#{$larger-than-desktop}) {
      width: 100%;
    }
  }

  .modal-wrapper,
  .model-container {
    z-index: 9999;
    background-color: #fff;
    box-shadow: 0px 5px 12px 0px rgba(239, 66, 111, 0.04), 0px 7px 16px 0px rgba(75, 75, 75, 0.23), 0px 4px 4px 0px rgba(75, 75, 75, 0.04);
    border-radius: 16px;
    max-height: 90vh;
    overflow: hidden;
    max-width: 700px;
    width: 100%;
    padding: 24px 24px 32px !important;
    position: relative;

    @media (max-width: $phablet-min-width-minus) {
      padding: 16px 16px 24px !important;
      max-width: 90vw;
    }

    .modal-header {
      padding: 12px 36px 0;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;

      @media (max-width: $phablet-min-width-minus) {
        padding: 12px 28px 0;
        margin-bottom: 16px;
      }

      .modal-heading {
        color: #000;
        width: 100%;
      }
    }

    .close-icon {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      @media (max-width: $phablet-min-width-minus) {
        width: 24px;
        height: 24px;
        top: 16px;
        right: 16px;
      }
    }

    .modal-body {
      .modal-content {
        text-align: center;
        font-size: rem-calc(18px);
        line-height: 28px;

        @media (max-width: $phablet-min-width-minus) {
          font-size: rem-calc(16px);
          line-height: 24px;
        }

        .row {
          margin: 0;
        }

        .modal-actions {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin: 0;
          margin-top: 24px;
          padding: 0;
          text-align: center;
          gap: 16px;

          @media (max-width: $phablet-min-width-minus) {
            margin-top: 20px;
          }

          @media (max-width: $mobile-min-width-minus) {
            flex-wrap: wrap;
            gap: 16px;
          }

          .transparent {
            margin-right: 0;
          }
        }
      }
    }

    &.full {
      width: 100%;

      @media (#{$larger-than-desktop}) {
        width: 85%;
      }
    }

    &.ios-setup-modal2 {
      height: 100%;

      @media (#{$larger-than-phablet}) {
        width: 600px;
      }

      @media (max-width: 640.9px) {
        width: 345px;
      }

      .modal-content {
        button {
          &.primary {
            background-color: white !important;
            color: $primary-color !important;
          }

          &.secondary {
            background-color: $primary-color !important;
            color: white !important;
          }
        }
      }
    }

    &.migration-flows-popup {
      height: 100%;

      @media (min-width:700px) {
        width: 700px;
      }

      @media (max-width:699.9px) {
        width: 600px;
      }

      @media (max-width: 610.9px) {
        width: 300px;
      }

      .modal-header {
        @media (max-width:1199.9px) {
          padding: 1em 1em 0;
        }
      }

      .modal-heading {
        margin-top: 10px;
        text-align: left;
        margin-left: 0px;

        @media (max-width: 610.9px) {
          text-align: center;
          font-size: rem-calc(22px);
          margin-left: auto;
          line-height: 26px;
        }
      }

      .modal-close {
        background: none;
      }

      .modal-body {
        overflow-x: hidden;

        .modal-content {
          padding: 0em 3.3em;

          @media(max-width:1199.9px) {
            padding: 0em 2.3em;
          }
        }

        .swiper-container {
          width: 100%;
          max-width: 100%;
        }

        .swiper-pagination {
          text-align: left;

          @media(max-width:610.9px) {
            text-align: center;
          }

          .swiper-pagination-bullet-active {
            background: $primary-color;
          }
        }

        .flex-display {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 6px;

          @media (max-width:699.9px) {
            padding: 0 0em;
          }

          @media (max-width: 610.9px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-top: 23px;
            padding: 0px;
          }

          .desc {
            max-width: 307px;
            width: 100%;
            text-align: left;
            font-size: 13px;
            line-height: 22px;

            @media (max-width: 610.9px) {
              text-align: center;
            }
          }

          .image {
            width: 273px;
            height: 245px;

            @media (max-width:699.9px) {
              width: 249px;
              height: 223px;
            }

            @media(max-width:610.9px) {
              margin-bottom: 30px;
            }
          }
        }

        .actions {
          display: flex;
          margin-bottom: 60px;
          align-items: center;

          @media(max-width:610px) {
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          button {
            width: 130px;
            font-size: rem-calc(15px);
            padding: 10px;

            &.last {
              width: 215px;
              height: 44px;
              padding: 6px;
            }
          }

          .not-interested {
            color: $secondary-color;
            font-size: rem-calc(12px);
            margin-left: 20px;
            line-height: 15px;

            @media(max-width:610px) {
              margin-left: 0px;
              margin-top: 18px;
            }
          }
        }
      }

      .modal-content>* {
        padding: 0em 0em;
        margin-bottom: 20px;
      }

      .modal-actions {
        button {
          position: relative;
          right: 200px;
          bottom: 50px;
          font-size: rem-calc(15px);

          @media (max-width:699.9px) {
            right: 150px;
          }

          @media (max-width: 610.9px) {
            right: 0px;
            bottom: 10px;
          }
        }
      }
    }

    &.pin-used {
      .modal-body {
        .modal-content {
          .row {
            button {
              &.primary {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &.skip-modal {
      max-width: 700px;
      width: 100%;

      .modal-body {
        .modal-content {
          .modal-actions {
            .primary {
              margin-right: 15px;
              width: 200px;
              background-color: $primary-color;
              color: white;
            }

            button {
              &:nth-child(2) {
                background-color: transparent;
                border: 1px solid $primary-color;
                color: $primary-color;
              }
            }

            @media (max-width: 639.9px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 10px auto;

              .primary {
                margin-bottom: 10px;
                margin-right: 0px;
              }
            }
          }
        }
      }
    }

    &.revert-cancel-modal {
      max-width: 100%;

      .modal-body {
        .modal-content {
          .row {
            padding: 10px 25px !important;
          }

          .modal-actions {
            .primary {
              margin-right: 10px;
            }

            @media (max-width: 859.9px) {
              display: flex;
              flex-direction: column;
              align-items: center;

              .primary {
                margin-right: 0;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    &.quick-topup-modal {
      max-width: 100%;

      .modal-header {
        h2 {
          color: $primary-color;

          @media (max-width: 639.9px) {
            font-size: 26px;
          }
        }

        text-align: center;
        border: none;
        height: 80px;
        box-shadow: 0 1px 0px 0 #80808038;
      }

      .modal-content {
        padding: 0;
      }

      .modal-body {
        .modal-content {
          font-weight: bold;
          color: black;

          &>* {
            padding-left: 22px;
            padding-right: 0;
          }

          fieldset {
            text-align: left;

            label {
              text-transform: uppercase;
              color: #666666;

              &.validation-message {
                color: red;
                text-transform: lowercase;
              }
            }

            input {
              height: 45px;
              width: 366px;
              margin-bottom: 22px;

              @media (max-width: $iphone-max-width) {
                width: unset;
              }
            }
          }

          .modal-actions {
            text-align: center;
            float: left;
            margin-top: -34px;

            .twelve {
              @media (min-width: 1025px) {
                padding-left: 57px;
              }

              .button {
                width: 220px;
              }
            }
          }

          .row {
            margin-bottom: 20px;

            .twelve {}

            .row {
              text-align: left;
              max-width: 580px;
              margin-right: -15px;
              margin-left: -15px;

              .twelve {
                width: unset;
                float: left;
                font-size: 16px;
                margin-top: 24px;

                @media (max-width: $iphone-max-width) {
                  width: 93%;
                }
              }
            }
          }
        }
      }
    }

    &.wifi-calling-modal {
      max-width: 600px;

      @media (max-width: 639.9px) {
        max-width: 575px;
      }

      @media (max-width: $iphone-max-width) {
        max-width: 288px;
      }

      border-radius: 11px;
      width: 100%;

      .modal-header {
        padding: 30px 20px 10px;

        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          font-size: rem-calc(24px);

          @media (max-width: 1024.9px) {
            font-size: rem-calc(20px);
          }

          text-wrap: nowrap;

          @media (max-width: 480.9px) {
            text-wrap: wrap;
          }

          font-weight: 700;
          line-height: 33px;
          width: 100%;
          max-width: 256px;
        }
      }

      .modal-body {
        max-height: calc(80vh - 65px);
        overflow-x: hidden;

        .modal-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 15px;

          .form-header {
            padding: 0 10px;
            font-size: rem-calc(15px);
            line-height: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            font-family: $base-font-family;
          }

          .form-description {
            padding: 0 10px;
            font-size: rem-calc(14px);
            line-height: 22px;
            font-family: $base-font-family;
            text-align: left;
          }

          .form-section {
            padding: 10px;

            &:first-child {
              padding: 0px;
            }

            fieldset {
              text-align: left;
              width: 100%;
              margin-bottom: 15px;

              input {
                width: 100%;
                height: 45px;
              }

              label {
                margin-bottom: 9px;
              }

              .icon-search {
                display: none;
              }
            }

            .address-lookup {
              padding: 0;
            }

            .four {
              padding: 0;
            }
          }

          .checks {
            padding: 0 20px 0 10px;
            text-align: left;

            .checkbox {
              display: flex;
              align-items: baseline;
              position: relative;

              .checkbox-label {
                padding-left: 30px;
                font-size: rem-calc(14px);
                cursor: pointer;

                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  margin: 4px;
                  width: 20px;
                  height: 20px;
                  transition: transform 0.28s ease;
                  border-radius: 5px;
                  border: 1px solid $input-border;
                }

                &:after {
                  content: "";
                  display: block;
                  width: 10px;
                  height: 5px;
                  border-bottom: 2px solid $primary-color;
                  border-left: 2px solid $primary-color;
                  transform: rotate(-45deg) scale(0);
                  transition: transform ease 0.25s;
                  position: absolute;
                  top: 11px;
                  left: 10px;
                }
              }

              input[type="checkbox"] {
                width: auto;
                opacity: 0.00000001;
                position: absolute;
                left: 0;
                margin-left: -20px;

                &:checked~label {
                  &:before {
                    border: 1px solid $input-border;
                  }

                  &:after {
                    transform: rotate(-45deg) scale(1);
                  }
                }

                &:focus+label::before {
                  outline: 0;
                }
              }
            }

            .warning {
              padding-left: 30px;

              .note {
                color: $secondary-color;
                font-style: italic;
                font-size: rem-calc(14px);
                line-height: 22px;
              }
            }
          }

          .modal-actions {
            margin: 0 auto 30px;

            .button {
              width: 135px;
              font-size: rem-calc(20px);
              padding: 8px;
            }
          }
        }

        .modal-content>* {
          padding: 0;
        }
      }
    }

    &.wifi-result-modal {
      width: 302px;
      border-radius: 11px;

      .modal-header {
        padding: 30px 20px 10px;

        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          font-size: rem-calc(25px);
          font-weight: 500;
          line-height: 33px;
          width: 100%;
        }
      }

      .modal-body {
        .modal-content {
          .row {
            padding: 0 26px;
            font-size: rem-calc(14px);
          }
        }
      }
    }

    &.edit-wifi-modal {
      .modal-body {
        .modal-content {
          .twelve {
            display: none;
          }
        }
      }
    }

    &.esim-replacement {
      width: 700px;

      @media (max-width: $desktop-min-width-minus) {
        width: 600px;
      }

      @media (max-width: $phablet-min-width-minus) {
        width: 100%;
      }

      .modal-body {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .title-bottom {
            margin-bottom: 4px;
          }

          .bold-bottom {
            margin-bottom: 8px;
          }

          .re-captcha--resizing {
            margin-top: 16px;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    &.disable-wifi-modal {
      max-width: 600px;

      @media (max-width: 639.9px) {
        max-width: 575px;
      }

      @media (max-width: $iphone-max-width) {
        max-width: 288px;
      }

      border-radius: 11px;

      .close-icon {
        width: 24px;
        height: 24px;
        top: 14px;
        right: 16px;
      }
      .modal-header {
        padding: 15px 20px 10px;
        margin-bottom: 0;
        @media(max-width: $phablet-min-width-minus) {
          padding: 24px 20px 10px;
        }
        .modal-heading {
          width: 100%;
        }
      }

      .modal-body {
        .modal-content {
          padding: 0 0 20px;

          .modal-actions {
            margin: 0 auto;
            @media(max-width: $phablet-min-width-minus) {
             gap: 8px;
            }
            .button {
              &.primary {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &.confirm-password-modal {
      @include modal-layout;

      .modal-body {
        .modal-content {
          fieldset {
            margin: 0;
            max-width: 520px;
            width: 90%;

            input {
              margin: 0;
            }

            .validation-message {
              text-align: left;
            }
          }

          .modal-actions {
            .button {
              width: 117px;

              @media (max-width: $phablet-min-width-minus) {
                width: 104px;
              }
            }
          }
        }
      }
    }

    &.confirm-modal {
      .modal-content .modal-actions {
        flex-direction: row-reverse;

        .button {
          background-color: transparent;
          border: 2px solid $primary-color;
          color: $primary-color;
          width: 257px;

          &.primary {
            background-color: $primary-color;
            color: white;
            margin-left: 10px;
          }

          @media (max-width: $phablet-min-width) {
            width: 216px;
            margin: 0;

            &.primary {
              margin-left: 0;
            }
          }
        }

        @media (max-width: $phablet-min-width) {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin-top: 8px;

          &::before, &::after {
            content: none;
          }
        }
      }
    }

    &.select-cc-modal {
      width: 730px;

      @media (max-width: 750px) {
        width: 100%;
      }

      @media (max-width: 414px) {
        width: 89%;
      }

      .modal-header {
        width: 100%;
        text-align: left;
        padding: 1em 2em 0;

        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          width: 100%;
          text-align: left;
          font-size: rem-calc(22px);
          line-height: 28px;

          @media (max-width: 750px) {
            font-size: rem-calc(28px);
            text-align: center;
            line-height: 37px;
          }
        }
      }

      .modal-body {
        .modal-content {
          text-align: left;

          .content {
            padding: 13px;
            display: block;

            .mdn {
              font-size: rem-calc(15px);
              line-height: 17px;
              margin-bottom: 0;
            }
          }

          .payments {
            display: inline-block;
            padding: 13px;

            .action {
              display: inline-block;
              margin-top: 25px;


              .button {
                padding: 11px;
                font-size: rem-calc(20px);
                width: 250px;
              }
            }

            .payments-list {
              display: inline-block;
              height: 37px;
              font-size: rem-calc(15px);
              margin-right: 10px;
            }
          }

          @media (max-width: 750px) {
            text-align: center;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .content {
              .mdn {
                font-size: rem-calc(13px);
              }
            }

            .payments {
              .payments-list {
                margin: auto;
                margin-bottom: 20px;
                font-size: rem-calc(13px);
                display: block;
              }

              .action {
                display: block;

                .button {
                  width: 243px;
                  font-size: rem-calc(14px);
                }
              }
            }
          }
        }
      }
    }

    &.edit-payment-modal {
      width: 100%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
      }

      .modal-body {
        .modal-content {
          text-align: left;

          .content {
            display: flex;
            text-align: left;

            .note {
              .link {
                color: $primary-color;
                cursor: pointer;
              }
            }
          }

          .form-section {
            display: inline-block;
            text-align: left;
            width: 100%;
            padding: 13px;

            label {
              display: inline;
              font-size: rem-calc(15px);
              line-height: 17px;
              font-weight: bold;
              padding-right: 10px;
            }

            .validation-message {
              display: table-cell;
              text-transform: inherit;
              padding: 10px 0;
            }

            .required-field {
              color: #D94A47;
            }

            .first-row {
              text-align: left;
              margin-bottom: 20px;

              .cardNumber-section {
                width: 55%;
                display: inline-block;

                .modal-label {
                  font-weight: bold;
                  font-size: rem-calc(15px);
                  line-height: 17px;
                }
              }

              .name-section {
                width: 45%;
                display: inline-block;
              }
            }

            .second-row {
              text-align: left;

              .expiry-section {
                width: 63%;
                display: inline-block;

                .sperator {
                  font-size: rem-calc(40px);
                  position: relative;
                  top: 9px;
                  padding: 0 4px;
                }

                .select {
                  width: 125px;
                  margin-right: 0;

                  select {
                    height: 30px;
                    width: 120px;
                    padding: 4px 10px;
                    font-size: rem-calc(13px);

                    @media (min-width: 1200px) {
                      width: 126px;
                    }
                  }

                  .select-arrow {
                    top: 12px;
                  }
                }
              }

              .cvv-section {
                width: 36%;
                display: inline-block;
              }
            }

            &.address-form {
              .title {
                font-family: $base-font-family;
                font-size: rem-calc(15px);
                line-height: 17px;
                margin-bottom: 25px;
              }

              .populated {
                .address {
                  font-size: rem-calc(17px);
                  line-height: 22px;
                  margin-bottom: 7px;

                  .edit {
                    padding-left: 10px;
                    color: $primary-color;
                    cursor: pointer;

                    img {
                      background-color: $primary-color;
                      padding: 5px;
                      border-radius: 52%;
                    }
                  }
                }

                .responsive {
                  display: none;
                }

                @media (max-width: 639.9px) {
                  .address {
                    .edit {
                      display: none;
                    }
                  }

                  .responsive {
                    display: block;
                    color: white;
                    cursor: pointer;
                    background-color: $primary-color;
                    padding: 8px;
                    text-align: center;
                    font-size: rem-calc(15px);
                  }
                }
              }

              .addressForm {
                margin-bottom: 25px;

                input {
                  padding: 5px 10px;
                }

                .addressName-section {
                  width: 50%;
                  display: inline-block;

                  input {
                    width: 277px;
                  }
                }

                .address-section {
                  width: 48%;
                  display: inline-block;

                  .ngui-auto-complete-wrapper {
                    display: inline;

                    input {
                      width: 250px;
                    }
                  }
                }

                .details-section {
                  display: inline-block;
                  width: 53%;

                  .state-section {
                    display: inline-block;

                    input {
                      width: 120px;
                    }
                  }

                  .city-section {
                    display: inline-block;
                    padding-left: 5px;

                    input {
                      width: 120px;
                    }
                  }
                }

                .postal-section {
                  display: inline-block;
                }
              }
            }

            &.modal-actions {
              display: contents;

              .button {
                width: 220px;
                padding: 11px;
                font-size: 18px;
              }

              @media (max-width: 639.9px) {
                display: inline-block;
                margin: auto;
                text-align: center;
              }
            }

            @media (max-width: 1024.9px) {
              .first-row {
                .cardNumber-section {
                  width: 100%;
                  margin-bottom: 20px;
                }

                .name-section {
                  width: 100%;

                  input {
                    width: 410px;
                  }
                }
              }

              .second-row {
                .expiry-section {
                  width: 100%;
                  margin-bottom: 6px;

                  label {
                    padding-right: 27px;
                  }

                  .select {
                    width: 200px;

                    @media (min-width: 640px) and (max-width: 686.9px) {
                      width: 47%;
                    }

                    select {
                      width: 200px;

                      @media (min-width: 640px) and (max-width: 686.9px) {
                        width: 100%;
                      }
                    }
                  }
                }

                .cvv-section {
                  width: 100%;

                  label {
                    margin-right: 58px;
                  }
                }
              }

              &.address-form {
                .addressForm {
                  .addressName-section {
                    width: 100%;
                    margin-bottom: 20px;

                    label {
                      margin-right: 46px;
                    }

                    input {
                      width: 410px;
                    }
                  }

                  .address-section {
                    width: 100%;

                    .ngui-auto-complete-wrapper {
                      input {
                        width: 412px;
                      }
                    }
                  }

                  .details-section {
                    width: 100%;
                    margin-bottom: 20px;

                    .state-section {
                      label {
                        margin-right: 49px;
                      }

                      input {
                        width: 167px;
                      }
                    }

                    .city-section {
                      label {
                        margin-left: 18px;
                        margin-right: 18px;
                      }

                      input {
                        width: 167px;
                      }
                    }
                  }

                  .postal-section {
                    width: 100%;

                    label {
                      margin-right: 25px;
                    }
                  }
                }
              }
            }

            @media (max-width: 833.9px) {
              label {
                display: block;
              }

              &.address-form {
                .addressForm {
                  .details-section {
                    .city-section {
                      label {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }

            @media (max-width: 639.9px) {
              label {
                font-size: rem-calc(17px);
                line-height: 20px;
              }

              .first-row {
                .name-section {
                  input {
                    width: 100%;
                  }
                }
              }

              .second-row {
                .expiry-section {
                  .select {
                    width: 45%;

                    select {
                      width: 100%;
                    }
                  }
                }

                .cvv-section {
                  input {
                    width: 100%;
                  }
                }
              }

              &.address-form {
                .addressForm {
                  .addressName-section {
                    input {
                      width: 100%;
                    }
                  }

                  .address-section {
                    .ngui-auto-complete-wrapper {
                      input {
                        width: 100%;
                      }
                    }
                  }

                  .details-section {
                    .city-section {
                      display: block;
                      padding-left: 0;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.shipping-address-modal {
      .modal-content {
        align-items: center;
        display: flex;
        flex-direction: column;

        .form-section {
          max-width: 500px;
        }

        .payment-form {
          .four {
            margin: 0;
            padding-bottom: 0;

            input {
              margin: 0;
            }
          }
          
          .validation-message {
            margin-top: 4px;
            margin-bottom: 0;
          }
        }

        .validation-message {
          line-height: 22px;
        }

        .twelve {
          width: 100%;
          margin: 0;
          padding-top: 0;
          text-align: left;

          &.address-lookup {
            input {
              width: 66.66666666666666%;

              @media (max-width: $phablet-min-width) {
                width: 100%;
              }
            }
          }
        }

        label {
          text-align: left;
        }

        input {
          width: 100%;
          height: 45px;
          margin-bottom: 16px;
        }

        i {
          display: none;
        }

        .radio {
          display: inline-flex;
        }

        .row {
          margin-bottom: 0;
        }

        .modal-actions {
          .button {
            &.secondary {
              display: none;
            }
          }
        }
      }

      @media (#{$larger-than-desktop}) {
        width: 960px;
      }

      @media (#{$larger-than-phablet}) {
        .eight {
          width: 66.66666666666666%;
          padding: 10px;
        }

        .four {
          width: 33.33333333333333%;
          padding: 10px;
        }

        .twelve {
          padding: 10px;
        }
      }

      @media (max-width: $phablet-min-width) {
        .modal-body {
          input {
            height: 45px;
          }

          .modal-actions {
            float: none;
            text-align: center;
          }
        }
      }
    }

    &.payment-method-modal {
      width: 100%;
      max-width: 960px;

      .modal-header {
        //  position: relative;
        background-color: #fff;

        //  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
        //  border-bottom: 1px solid #e8e8e8;
        //  padding: 1em 2em;
        //  text-align: left;
        .modal-heading {
          text-align: left;
          width: 100%;
          margin: 12px;
          font-size: rem-calc(22px);
        }

        .modal-close {
          background-color: transparent;
        }
      }

      .modal-content {
        >* {
          //  display: block;
          display: flex;
          justify-content: flex-start;
          padding: 0;
        }

        .form-section {
          .credit-image {
            display: none;
          }
        }

        fieldset {
          text-align: left;
          margin-bottom: 15px;
        }

        h4 {
          text-align: left;
          margin-bottom: 10px;
        }

        label {
          text-align: left;
        }

        input {
          width: 100%;
          height: 45px;
          margin-bottom: 20px;
        }

        select {
          height: 45px;
          padding: 2px 10px;
        }

        .select-arrow {
          top: 12px;
        }

        i {
          display: none;
        }

        .radio {
          display: inline-flex;
        }

        .modal-actions {
          margin: 10px 0;

          .button {
            width: 213px;
            padding: 10px;
          }
        }

        @media (max-width: 1024px) {
          .row {
            margin: 10px 15px;

            .four {
              padding-right: 10px;
            }
          }

          .radio {
            margin-left: 15px;
          }
        }
      }
    }

    &.SIM-replacement-modal {
      .modal-content {
        padding: 0;
        overflow: hidden;

        .number {
          font-weight: bold;
        }
      }
    }

    &.Sim-replacement-iccid-modal {
      width: 700px;
      max-width: 97%;

      @media (max-width: $phablet-min-width-minus) {
        width: 97%;
      }

      .validation-message {
        text-align: left;
      }

      .modal-body {
        height: 100%;
        max-height: calc(70vh - 100px);
      }

      .modal-content {
        overflow: hidden;

        @media (max-width: $phablet-min-width-minus) {
          padding: 0;
        }

        .modal-actions {
          margin-top: 0px;
        }

        .button {
          display: none;

          &.primary {
            display: inline-block;
            width: 134.8px;
            font-size: rem-calc(20px);
            font-family: $base-font-family;
            padding: 11px;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }

        .primary-col {
          margin: 0 auto;
          margin-top: 12px;
          color: $primary-color;
          font-size: 16px;
          text-align: center;
          width: fit-content;

          @media (max-width: $desktop-min-width-minus) {
            max-width: 385px;
          }

          a {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
              width: 20px;
              height: 20px;

              @media (max-width: $phablet-min-width-minus) {
                width: 16px;
                height: 16px;
              }

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .iccid-part {
          border-radius: 6px;
          border: 1px solid $secondary-shade-200;
          margin: 4px 0 20px;
          padding: 8px;

          @media (max-width: $phablet-min-width-minus) {
            margin-bottom: 16px;
            padding: 4px;
          }

          img {
            width: 203px;
            height: 100px;
            margin-top: 16px;

            @media (max-width: $phablet-min-width-minus) {
              width: 183px;
              height: 90px;
              margin-top: 12px;
            }
          }
        }

        input {
          width: 100%;
          height: 45px;
          padding: 0 10px;
        }

        .label {
          font-size: rem-calc(15px);
          font-family: $base-font-family;
          margin-bottom: 15px;
          line-height: 17px;
          margin-top: 10px;
          text-align: left;
        }
      }

      .captcha {
        margin-bottom: 0;

        .re-captcha--resizing {
          display: flex;
          justify-content: center;
        }
      }

      .modal-content>* {
        padding: 0;
        display: block;
      }
    }

    &.confirm-swap-modal {
      max-width: 700px;
      width: 90%;

      .modal-header {
        .modal-close {
          background-color: transparent;
        }
      }

      .modal-content {
        padding: 0;

        .row {
          padding-top: 0;
          padding-bottom: 0;
        }

        p {
          margin-bottom: 5px;
        }

        .question {
          font-size: rem-calc(22px);
          font-family: $base-font-family;
          line-height: 28px;
          margin-bottom: 0;
        }

        .confirm {
          font-size: rem-calc(22px);
          font-family: $base-font-family;
          line-height: 28px;
        }

        .button {
          width: 134.8px;
          font-size: rem-calc(20px);
          font-family: $base-font-family;
          padding: 11px;
          margin-top: 0px;
        }

        .note {
          margin-top: 12px;

          .details {
            font-size: rem-calc(13px);
            font-family: $base-font-family;
            line-height: 15px;
          }
        }

        @media (max-width: 640px) {
          overflow: hidden;

          .question {
            font-size: rem-calc(18px);
            line-height: 20px;
          }

          .confirm {
            font-size: rem-calc(18px);
            line-height: 20px;
          }
        }

        @media (max-width: 425.9px) {
          b {
            display: block;
            margin-top: 5px;
          }

          .modal-actions {
            margin: 0;
          }
        }
      }

      .modal-content>* {
        padding: 0.5em 2em;
      }
    }

    &.success-swap-modal {
      max-width: 486.6px;
      width: 100%;

      @media (min-width: 1025px) {
        height: 517.3px;
      }

      .modal-header {
        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          font-size: rem-calc(25px);
          line-height: 33px;
          font-family: $base-font-family;
          font-weight: bold;
        }
      }

      .modal-content {
        .subHeader {
          font-size: rem-calc(17px);
          line-height: 25px;
          font-family: $base-font-family;
          font-weight: bold;
        }

        .details {
          font-size: rem-calc(17px);
          line-height: 25px;
          font-family: $base-font-family;
        }

        .modal-actions {
          margin-top: 0;
        }
      }

      .modal-content>* {
        padding: 0.5em 1em;
      }
    }

    &.send-receipt-email-modal {
      .modal-header {
        .modal-close {
          background-color: transparent;
        }
      }

      .modal-content {
        padding: 0.2em 1em;

        .row {
          padding: 0;
          width: 100%;
          max-width: 100%;
          .details {
            font-family: $base-font-family;
            max-width: 540px;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .modal-actions {
          margin: unset;
          padding: unset;
          display: flex;
          gap: 12px;
          margin-top: 12px;
          
          .primary {
            background-color: $primary-color;
            color: white;
            padding: 10px 20px;
          }

          .secondary {
            border: 1px solid;
            padding: 10px 20px;
          }
        }
      }
    }

    &.custom-modal-voucher-results {
      border: 1px solid $input-border;
      width: 100%;

      .modal-header {
        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          width: 100%;
          font-size: rem-calc(38px);
          font-family: $base-font-family;
          line-height: 26px;
          margin-top: 20px;
        }
      }

      .modal-content {
        .message {
          font-size: rem-calc(22px);
          line-height: 26px;
          font-family: $base-font-family;
          color: $black;
          font-weight: normal;

          &.balance {
            font-weight: bold;
            color: $black;

            .text-color-alert {
              color: $black;
            }
          }
        }
      }

      .modal-content>* {
        display: block;
      }

      .modal-actions {
        margin: 0;

        .button {
          margin-left: auto;
          margin-right: auto;
          width: 145px;
          padding: 10px;
          font-family: $base-font-family;
        }
      }

      @media (#{$larger-than-desktop}) {
        width: 960px;
      }

      @media (max-width: 750px) {
        .modal-header {
          .modal-heading {
            line-height: 36px;
          }
        }
      }

      @media (max-width: 640px) {
        .modal-header {
          .modal-heading {
            font-size: rem-calc(31px);
            line-height: 41px;
            max-width: 186px;
          }

          .modal-close {
            background-color: #ccc;
          }
        }

        .modal-content {
          .row {
            padding: 0 30px;
          }

          .message {
            font-size: rem-calc(17px);
            line-height: 20px;
          }
        }
      }
    }

    &.five-toggle-modal {
      max-width: 580px;
      width: 97%;

      .close-icon{
        width: 24px;
        height: 24px;
        top: 14px
      }
      .modal-header {
        padding: 0px;

        .modal-heading {
          font-size: rem-calc(24px);
          white-space: nowrap;
          margin: 0 auto;
        }
      }

      .modal-body {
        min-height: unset;
        overflow: hidden;
        .modal-content {
          padding: 12px;
          padding-top: 12px;
          @media(max-width: $phablet-min-width-minus) {
            padding: 0px;
          }
          .row {
            padding: 0;
            margin-bottom: 0;

            div {
              max-width: 510px;
              width: 100%;
            }
          }

          .modal-actions {
            margin-top: 24px;

            a {
               padding: 0;
              .arrow {
                width: 18px;
                height: 13.44px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
    &.select-addon-modal {
      width: 100%;
      max-width: 700px;
      border: 1px solid $input-border;

      .close-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }

      .modal-header {
        padding: 18px 36px 0;

        .modal-heading {
          margin: 0;
          margin-bottom: 24px;
        }
      }

      .modal-content {
        .row {
          display: flex;
          justify-content: center;
          gap: 12px;

          &:first-child {
            display: none;
          }
        }
      }

      .button {
        &.primary {
          order: 2;
        }
      }

      @media (max-width: $phablet-min-width-minus) {
        max-width: 96%;

        .close-icon {
          top: 10px;
          right: 10px;
        }

        .modal-header {
          padding: 8px 16px;

          .modal-heading {
            width: 100%;
            margin-bottom: 0;
          }
        }

        .modal-content {
          padding: 0 17px;

          .modal-actions {
            margin-top: 0;
          }

          .row {
            flex-direction: column;
            gap: 8px;
          }

          .button {
            &.primary {
              order: 2;
            }
          }
        }
      }
    }

    &.confirm-change-checkout-modal {
      max-width: 629px;
      padding: 0.5em 1em;
      width: 100%;

      .modal-heading {
        font-size: rem-calc(25px);
        line-height: 25px;
      }

      .modal-header {
        .modal-close {
          background-color: white;
        }
      }

      .modal-content {
        padding: 0 24px;
      }

      .twelve {
        font-size: rem-calc(17px);
        line-height: 20px;
        padding: 0 27px 0;
      }

      .modal-actions {
        .button {
          width: 112px;
          margin-left: 10px;
          background-color: transparent;
          border: 2px solid $primary-color;
          color: $primary-color;
          padding: 10px 20px;

          &.primary {
            background-color: $primary-color;
            margin-left: 0px;
            color: white;
          }
        }
      }

      @media (max-width: $tablet-min-width) {
        .row {
          &:first-child {
            padding: 0 1em;
          }

          .twelve {
            padding: 0;
          }
        }
      }

      @media (max-width: $phablet-min-width-minus) {
        max-width: 490px;

        .modal-heading {
          padding: 0 1.1rem;
          font-size: rem-calc(20px);
          line-height: 30px;
        }
        .modal-content {
          padding: 0;
        }
        .row {
          &:first-child {
            padding: 0 1em;
          }
        }

        .twelve {
          padding: 0 27px;
          font-size: 15px;
        }

        .modal-actions {
          .button {
            margin-left: 10px;

          }
        }
      }

      @media (max-width: 414.9px) {
        .modal-actions {
          button {
            margin-left: 0px !important;
            padding: 10px 18px;
          }

          .primary {
            margin-left: 0px !important;
          }
        }
      }
    }

    &.change-pin-modal {
      border: 1px solid $input-border;
      max-width: 715px;
      width: 100%;
      //  height: 395px;
      max-height: none;

      .modal-close {
        background-color: white;
      }

      .modal-header {
        .modal-heading {
          width: 100%;
          font-size: rem-calc(38px);
          font-family: $base-font-family;
          line-height: 50px;
        }
      }

      .modal-body {
        .twelve {
          width: 100%;
          max-width: 590px;
        }

        label {
          font-size: rem-calc(15px);
          font-family: $base-font-family;
          line-height: 17px;
        }

        input {
          margin: 0px auto 0;
          height: 45px;
          border: 1px solid $input-border;
          max-width: 454px;
          width: 100%;
        }

        span {
          font-size: rem-calc(17px);
          line-height: 20px;
          font-family: $base-font-family;
        }

        .button {
          width: 213px;
          padding: 10px 30px;
          font-size: rem-calc(20px);

          &.secondary {
            margin-left: 15px;
          }
        }
      }

      @media (max-width: $tablet-min-width) {
        .modal-header {
          padding: 20px 15px 0;
        }
      }

      @media (max-width: $phablet-min-width) {
        .modal-header {
          padding: 20px 21px 0;
        }

        .modal-header {
          .modal-heading {
            font-size: rem-calc(31px);
            line-height: 41px;
          }
        }

        .modal-body {
          .twelve {
            &:nth-child(2) {
              padding: 0 23px;
            }
          }

          label {
            font-size: rem-calc(17px);
            line-height: 20px;
          }

          input {
            height: 45px;
            margin: 0px auto 25px;

            &.edit-input {
              @media (max-width: 639.9px) {
                width: 66%;
                margin-left: 39px;
              }
            }
          }

          .button {
            width: 145px;

            &.secondary {
              margin-left: 10px;
            }
          }
        }
      }
    }

    &.timer-alert-modal {
      text-align: center;

      .modal-header {
        padding-top: 16px;
      }

      .desc {
        &.last {
          margin-bottom: 24px;
          margin-top: 4px;
        }
      }

      .timer {
        color: $secondary-color;
      }

      .modal-actions {
        flex-wrap: wrap;
      }

      .button {
        width: 217px;
      }

      @media (max-width: $phablet-min-width) {
        .desc {
          &.last {
            margin-bottom: 16px;
          }
        }

        .button {
          width: 172px;
        }
      }
    }

    &.clean-cart-modal {
      .modal-body {
        .twelve {
          margin-top: 4px;
        }
      }
    }

    &.impacted-modal {
      max-width: 800px;
      width: 100%;

      @media (max-width: 641px) {
        margin-top: 0;
      }

      @media (max-width: 355px) {
        width: 88%;
      }

      margin-top: 0;

      .modal-header {
        .modal-close {
          background-color: white;
        }

        .modal-heading {
          font-size: rem-calc(38px);
          font-family: $base-font-family;
          line-height: 50px;
          width: 100%;

          @media (max-width: $desktop-min-width-minus) {
            font-size: rem-calc(24px);
            line-height: 36px;
            width: 88%;
          }
        }
      }

      .modal-content {
        padding: 0 1em 1.2em;

        @media (max-width: 641px) {
          overflow-y: visible;
          max-height: 300px;
          margin-top: 0;
          padding: 0 1em 0;

          &.row {
            margin-bottom: 0;
          }
        }

        margin: 20px;
      }

      .modal-body {
        .twelve {
          font-size: rem-calc(17px);
          line-height: 20px;
          max-width: 720px;
          width: 100%;
        }

        .not-working-link {
          color: $primary-color;
          cursor: pointer;

          @media (max-width: 641px) {
            margin-bottom: 0;
          }
        }

        .modal-actions {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 641px) {
            padding-bottom: 0;
          }

          .button {
            background-color: transparent;
            width: 350px;
            font-size: rem-calc(20px);
            font-family: $base-font-family;
            padding: 10px 10px;
            margin-bottom: 10px;
            margin-top: 0;
            text-transform: capitalize;
            color: $primary-color;
            border: 2px solid;

            &.primary {
              border: none;
              background-color: $primary-color;
              color: white;
            }
          }
        }
      }

      @media (max-width: 641px) {
        .modal-header {
          padding: 10px 30px;

          @media (max-width: 355px) {
            padding: 13px 10px;
          }

          .modal-heading {
            font-size: rem-calc(24px);
            line-height: 32px;
          }
        }

        .modal-body {
          .twelve {
            font-size: rem-calc(17px);
            line-height: 20px;
          }

          .modal-actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            .button {
              width: 300px;
              font-size: rem-calc(14px) !important;
              margin-bottom: 10px;
              margin-top: 0;
            }
          }
        }
      }
    }

    &.add-number-modal {
      width: 486.6px;
      min-height: 258px;

      .modal-header {
        padding: 10px 35px;

        .modal-close {
          background-color: white;
        }

        .modal-heading {
          text-align: left;
          margin: 10px 0;
          width: 100%;
          font-size: rem-calc(20px);
          line-height: 27px;
        }
      }

      .modal-body {
        .row {
          padding: 5px 5px 0;
          display: block;
        }

        .twelve {
          width: 100%;
          text-align: left;

          .phone-number-section,
          .verification-code-section {
            text-align: left;

            input {
              width: 100%;
              height: 45px;
            }

            label {
              font-size: rem-calc(15px);
              line-height: 17px;
              text-align: left;
            }
          }
        }

        .message {
          display: none;
        }

        .modal-actions {
          margin: 0;
          text-align: right;

          .cancel {
            display: none;
          }

          .button {
            width: 134.8px;
            padding: 10px 00px;
            font-size: rem-calc(20px);

            &.primary {
              background-color: $primary-color;
            }
          }
        }
      }

      @media (max-width: $phablet-min-width) {
        border: 1px solid $grey;
        width: 100%;
        max-width: 346px;
        min-height: 400px;

        .modal-header {
          padding: 15px 10px 0;

          .modal-heading {
            text-align: center;
            font-size: rem-calc(31px);
            line-height: 41px;
          }

          .modal-close {
            top: 6px;
            right: 5px;
          }
        }

        .modal-content {
          padding: 0 20px;
        }

        .modal-body {
          .row {
            padding: 3px 5px;
          }

          .twelve {
            .phone-number-section {
              input {
                height: 45px;
              }
            }
          }

          .modal-actions {
            text-align: center;

            .button {
              width: 145px;
              padding: 8px 0px;
            }
          }
        }
      }
    }

    &.successful-activation-modal {
      width: 486.6px;
      min-height: 258px;

      .modal-header {
        padding: 10px 35px;

        .modal-close {
          background-color: white;
        }

        .modal-heading {
          text-align: center;
          margin: 25px 0 0;
          width: 100%;
          font-size: rem-calc(25px);
          line-height: 33px;
        }
      }

      .modal-body {
        .modal-content {
          padding: 10px;
        }

        .row {
          padding: 5px 5px 0;
          font-size: rem-calc(17px);
          line-height: 20px;
          width: 300px;
        }

        .modal-actions {
          .button {
            width: 134.8px;
            padding: 10px 0px;
            font-size: rem-calc(20px);

            &.primary {
              background-color: $primary-color;
            }
          }
        }
      }

      @media (max-width: $phablet-min-width) {
        border: 1px solid $grey;
        width: 100%;
        max-width: 346px;
        min-height: 285px;

        .modal-header {
          padding: 15px 10px 0;

          .modal-heading {
            text-align: center;
            font-size: rem-calc(31px);
            line-height: 41px;
            width: 274px;
            margin: 20px auto 10px;
          }
        }

        .modal-content {
          padding: 0 20px;
        }

        .modal-body {
          .row {
            padding: 3px 5px;
            font-size: rem-calc(15px);
            line-height: 17px;
          }

          .modal-actions {
            text-align: center;
            margin-bottom: 20px;

            .button {
              width: 145px;
              padding: 8px 0px;
            }
          }
        }
      }
    }

    &.change-plan-modal {
      @include modal-layout;

      .first {
        margin-bottom: 4px;
      }

      .modal-actions {
        flex-direction: column;

        button {
          max-width: 266px;
          width: 100%;
        }
      }
    }

    &.change-expired-plan-modal {
      .modal-body {
        .modal-content {
          padding: 0;

          .twelve {
            float: none;
            padding: 0;
            width: 100%;

            .first {
              margin-bottom: 4px;
            }
          }
        }
      }

      .button {
        &.secondary {
          border: none;
          font-size: rem-calc(18px);
          min-width: unset;
          padding: 8px;
        }

        @media (max-width: $phablet-min-width) {
          &.secondary {
            font-size: rem-calc(16px);
          }
        }
      }
    }

    &.successPhoneModal {
      .modal-body {
        .modal-content {
          .success-message {
            .message {
              margin-bottom: 8px;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.unlockedModal {
      width: 100%;
      max-width: 378px;
      border: 2px solid $input-border;

      .modal-header {
        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          line-height: 22px;
          font-size: rem-calc(18px);
          text-align: left;
          width: 100%;
          margin: 12px;

          @media (max-width: $desktop-min-width-minus) {
            margin: 12px 0 0 36px;
          }
        }
      }

      .modal-body {
        .modal-content {
          .row {
            padding: 0 30px;
            text-align: left;

            .question-section {
              .question {
                font-weight: bold;
                font-size: rem-calc(14px);
                line-height: 22px;
                margin-bottom: 0;
              }

              .answer {
                margin-bottom: 20px;
                font-size: rem-calc(14px);
                line-height: 22px;
              }
            }
          }

          .modal-actions {
            margin: 0 auto 30px;
            text-align: center;

            .button {
              font-size: rem-calc(14px);
            }
          }
        }
      }
    }

    &.check-compatibility-modal {
      width: 700px;
      max-width: 100%;

      @media (max-width: $phablet-min-width-minus) {
        width: 575px;
      }

      @media (max-width: 479.9px) {
        max-width: calc(100% - 16px);
      }
    }

    &.frequencyModal {
      border: 1px solid $input-border;
      width: 100%;
      max-width: 531px;

      .modal-header {
        text-align: left;

        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          line-height: 37px;
          font-size: rem-calc(28px);
          text-align: left;
          width: 100%;
          max-width: 357px;
          margin: 12px 5px;

          @media (max-width: $desktop-min-width-minus) {
            margin: 20px 36px 10px;
          }

          @media (max-width: $phablet-min-width-minus) {
            font-size: rem-calc(22px);
            line-height: 28px;
          }
        }
      }

      .modal-body {
        .modal-content {
          padding-top: 0;

          .row {
            padding: 20px;

            .frequencies {
              .freq {
                text-align: left;
                font-size: rem-calc(17px);
                line-height: 24px;
                max-width: 441px;

                @media (max-width: $phablet-min-width-minus) {
                  font-size: rem-calc(15px);
                  line-height: 22px;
                  max-width: 376px;
                }
              }
            }

            &.modal-actions {
              display: none;
            }
          }
        }
      }

      @media (max-width: 470px) {
        width: 90%;

        .modal-header {
          .modal-heading {
            margin: 20px 0 10px;
          }
        }

        .modal-body {
          .modal-content {
            padding-left: 10px;

            .row {
              padding-left: 10px;
            }
          }
        }
      }
    }

    &.portRequiredModal {
      width: 100%;
      max-width: 822px;
      border: 1px solid $input-border;
      border-radius: 11px;

      .modal-header {
        text-align: left;

        .modal-close {
          background-color: transparent;
        }
      }

      .modal-body {
        .modal-content {
          .row {
            margin-bottom: 30px;
            font-weight: bold;
            font-size: rem-calc(22px);
            line-height: 28px;
            padding: 30px 30px 0;
          }

          .button {
            width: 197px;
            padding: 11px;
            font-size: rem-calc(17px);
            background-color: $secondary-color;

            &.primary {
              background-color: $primary-color;
              margin-right: 10px;
            }

            @media (max-width: 750px) {
              display: block;

              &.primary {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &.voucher-info-modal {
      width: 100%;
      max-width: 822px;
      border: 1px solid $input-border;
      border-radius: 11px;

      .modal-header {
        .modal-close {
          background-color: transparent;
        }

        .modal-heading {
          font-size: rem-calc(38px);
          font-family: $base-font-family;
          line-height: 50px;

          @media (max-width: 1025px) {
            font-size: rem-calc(31px);
            line-height: 10px;
            margin-top: 25px;
          }
        }
      }

      .button {
        width: 145px;
        height: 45px;
        line-height: 27px;
      }

      .row {
        padding: 10px;

        &.modal-actions {
          padding-bottom: 30px;
        }
      }
    }

    &.iccid-info-modal {
      width: 100%;
      max-width: 720px;
      border: 1px solid $input-border;
      border-radius: 11px;

      .modal-header {
        .modal-heading {
          font-size: rem-calc(25px);
          font-family: $base-font-family;
          line-height: 33px;
        }

        @media (max-width: 833.9px) {
          .modal-heading {
            font-size: rem-calc(31px);
            line-height: 41px;
          }

          .modal-close {
            background-color: transparent;
          }
        }
      }

      .button {
        background-color: transparent;
        border: 2px solid $primary-color;
        color: $primary-color;
        width: 149.8px;
        padding: 7px;
        font-size: rem-calc(20px);
        line-height: 27px;
        margin-top: -41px;

        @media (max-width: 833.9px) {
          width: 145px;
        }
      }

      .row {
        padding: 10px;

        &.modal-actions {
          margin-bottom: 30px;

          @media (max-width: 833.9px) {
            padding-top: 60px;
            //  margin-bottom: 50px;
          }

          @media (max-width: 427px) {
            padding-top: 130px;
            margin-bottom: 50px;
          }
        }
      }

      .text-content-holder {
        margin-bottom: 25px;

        .text {
          font-size: rem-calc(18px);
          margin-bottom: 20px;
          line-height: 21px;
          font-family: $base-font-family;
          display: inline-block;
          max-width: 581px;
        }
      }

      .image-container {
        position: relative;
        display: flex;
        align-items: center;
        //  justify-content: center;
        flex-direction: row;
        min-height: max-content;
        justify-content: left;
        padding-left: 0px;

        .pointer-holder {
          position: absolute;
          height: 120%;
          background-color: $black;
          width: 1px;
          z-index: 2;
          //top:222px;
          left: 248px;
          top: -49px;
          color: black;
          font-weight: bold;

          @media (min-width: 426px) {
            transform: rotate(90deg);
            // background-color: $input-border;
            color: black;
            font-weight: bold;
          }
        }

        img {
          width: 221.7px;
          height: 138.7px;
          left: 0;
          /*position: absolute;*/
        }

        .image-pointer {
          font-size: 0.9rem;
          line-height: 22px;
          position: absolute;
          top: 27px;
          width: 208px;
          text-align: left;
          left: 346px;
          font-weight: bold;
          color: black;

          @media (min-width: 834px) {
            right: 0;
            font-weight: bold;
            // max-width: 167px;
            //width:
          }
        }

        .rect {
          background-color: #f2e8f4;
          width: 90.7px;
          height: 13.3px;
          // margin-top: 207.2px;
          position: absolute;
          left: 96.3px;
          text-align: left;
          font-size: rem-calc(7px);

          &.rect1 {
            top: 28.4px;
          }

          &.rect2 {
            top: 46.4px;
          }

          &.rect3 {
            top: 73px;
          }

          &.rect4 {
            top: 78px;
            display: none;
          }
        }

        .big-rect {
          background-color: #f2e8f4;
          height: 9.4px;
          width: 56.7px;
          position: absolute;
          left: 325px;
          font-size: rem-calc(7px);
          text-align: left;

          &.rect1 {
            top: 61px;
          }

          &.rect2 {
            top: 72px;
          }

          &.rect3 {
            top: 83px;
          }

          &.rect4 {
            display: none;
            top: 94px;
          }
        }

        @media (max-width: 833.9px) {
          // justify-content: center;
          margin-left: 40px;

          .image-pointer {
            // left: 5px;
            //   left: 195px;
            top: 25px;
            width: 230px;
            font-size: 0.8em;
            text-align: center;
          }

          /* .pointer-holder {
          //   height: 69%;
           //  left: 238px;
             left: 388px;
             top: 80px;
           }
           .rect {
            // left: 225px;
          //  left: 372px;
           }
           .big-rect {
           //  left: 193px;
            // left: 342px;
             height: 14.6px;
             width: 87.6px;
             font-size: rem-calc(10px);
             &.rect1 {
               top: 205px;
             }
             &.rect2 {
               top: 223px;
             }
             &.rect3 {
               top: 241px;
             }
             &.rect4 {
               top: 259px;
             }
           }*/
        }

        @media (max-width: 825px) {
          .image-pointer {
            width: 188px;
            left: 246px;
            top: 25px;
          }

          .pointer-holder {
            top: -16px;
            height: 73%;
            left: 189px;
          }
        }

        @media (max-width: 689px) {
          .image-pointer {
            width: 151px;
            left: 246px;
            top: 25px;
          }

          .pointer-holder {
            top: -16px;
            height: 73%;
            left: 189px;
          }
        }

        @media (max-width: 640px) {
          margin-left: 0;
        }

        @media (max-width: 477px) {
          .image-pointer {
            width: 128px;
            left: 227px;
            top: 25px;
          }
        }

        @media (max-width: 426.9px) {
          justify-content: center;

          .image-pointer {
            //max-width: 155px;
            width: 230px;
            left: 22px;
            top: 183px;
          }

          .pointer-holder {
            top: 52px;
            height: 97%;
            left: 180px;
          }

          .rect {
            left: 148px;
          }

          .big-rect {
            //  left: 193px;
            width: 113.3px;
            height: 71.7px;
            border: solid 0.5px #1a1818;
            left: 200px;
            // height: 14.6px;
            // width: 87.6px;
            font-size: rem-calc(10px);

            &.rect1 {
              top: 205px;
            }

            &.rect2 {
              top: 223px;
            }

            &.rect3 {
              top: 241px;
            }

            &.rect4 {
              display: none;
              top: 259px;
            }
          }
        }

        @media (max-width: 406px) {
          .rect {
            left: 140px;
          }
        }

        @media (max-width: 390px) {
          .rect {
            left: 129px;
          }
        }

        @media (max-width: 376px) {
          .rect {
            left: 129px;
          }

          .pointer-holder {
            left: 184px;
          }

          .image-pointer {
            top: 189px;
          }
        }

        @media (max-width: 360px) {
          .pointer-holder {
            left: 162px;
          }

          .image-pointer {
            top: 186px;
            left: 9px;
          }

          .button {
            margin-top: -64px;
          }

          .rect {
            left: 121px;
          }
        }

        @media (max-width: 326px) {
          .pointer-holder {
            left: 162px;
          }

          .image-pointer {
            top: 186px;
            left: 9px;
          }

          .button {
            margin-top: -64px;
          }

          .rect {
            left: 102px;
          }
        }
      }
    }

    &.pending-confirmation {
      width: 100%;
      max-width: 769px;

      .modal-header {
        padding: 8px 16px 0;

        .modal-heading {
          font-size: rem-calc(24px);
          line-height: 32px;
          margin-bottom: 0;
          margin-top: 0;
        }

        .modal-close {
          background: transparent;
          color: $primary-color;
        }
      }

      .modal-body {
        .modal-content {
          .row {
            padding: 0;
          }

          .note {
            font-size: rem-calc(18px);
            margin-bottom: 5px;
            font-weight: 400;
          }

          .modal-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &::after, &::before {
              content: none;
            }

            .button {
              width: 195px;
            }
              
            @media (max-width: $phablet-min-width-minus) {
              .note {
                font-size: rem-calc(16px);
              }

              .button {
                width: 167px;
              }
            }
          }
        }
      }
    }

    &.activation-info-modal {
      width: 100%;
      max-width: 769px;
      border: 1px solid $input-border;
      border-radius: 11px;

      .modal-header {
        .modal-heading {
          font-size: rem-calc(25px);
          font-family: $base-font-family;
          line-height: 33px;
          margin: 0 auto;

          @media (max-width: 633.9px) {
            width: 262px;
            line-height: 1.32;
          }
        }

        .modal-close {
          background: none;
        }

        @media (max-width: 833.9px) {
          .modal-heading {
            line-height: 41px;
            width: 262px;
          }
        }
      }

      .modal-body {
        overflow-x: hidden;
      }

      .button {
        background-color: $primary-color;
        width: 154px;
        padding: 7px;
        font-size: rem-calc(20px);
        line-height: 27px;
        margin-top: -41px;

        @media (max-width: 833.9px) {
          width: 165px;
        }

        @media (max-width: 633.9px) {
          width: 145px;
        }
      }

      .modal-content {
        padding: 0 0px;

        .row {
          display: block;

          .text-content-holder {
            width: 609px;
            max-width: 609px;
            margin-bottom: 30px;
            font-size: rem-calc(18px);

            @media (max-width: 833.9px) {
              width: 404px;
            }

            @media (max-width: 633.9px) {
              width: 296px;
              padding: 0 16px;
              font-size: rem-calc(17px);
            }
          }

          .image-container {
            margin-bottom: 30px;

            @media (max-width: 633.9px) {
              margin-bottom: 38px;
            }

            .activation-image {
              width: 233px;
              height: 130px;

              @media (max-width: 639.9px) {
                width: 179px;
                height: 100px;
              }
            }
          }
        }
      }
    }

    &.manage-payment-modal {
      max-height: none;
      max-width: 720px;
      width: 100%;

      .modal-header {
        padding: 10px 50px;
        margin: 0;

        .modal-close {
          background-color: white;
        }

        .modal-heading {
          width: 100%;
        }

        @media (#{$larger-than-tablet}) {
          padding: 10px 100px;
        }

        @media (max-width: $mobile-min) {
          padding: 20px 26px 0;
        }
      }

      .modal-content>* {
        padding: 16px 30px 16px 15px;

        @media (max-width: 1024.9px) {
          padding: 12px 15px;
        }
      }

      .modal-body {
        padding: 0px 50px;
        max-height: calc(70vh - 100px);

        @media (max-width: $mobile-min) {
          padding: 10px 23px;
        }
      }

      .modal-content {
        padding: 0;

        .note {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; 
        }

        .modal-actions {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          .modal-hint {
            min-width: 280px;
            text-align: left;
            margin: auto;
            margin-top: 20px;
            a {
              padding: 0;
            }
          }

          @media (max-width: 640px) {
            .modal-hint {
              text-align: center;
              min-width: 0;
            }
          }
        }
      }

      .account-address-details-container {
        &.highlight-card {
          background-color: $primary-opacity-10;
          width: 100%;
          text-align: left;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .custom-checkbox {
            width: 45px;
            margin-top: 3px;
          }

          .details {
            text-align: left;
            padding-top: 0;

            .checkbox-label {
              text-transform: capitalize;
              font-size: rem-calc(15px);
              font-family: $base-font-family;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.compatibility-help-modal-IME {
      width: 100%;
      max-width: 720px;
      border: 1px solid $input-border;
      height: 100%;

      @media (min-width: 641px) and (max-width: 725.9px) {
        overflow: scroll;
      }

      .modal-header {
        .modal-heading {
          width: 100%;
          font-size: rem-calc(25px);
          font-weight: bold;
          line-height: 33px;

          @media (max-width: 1024.9px) {
            font-size: rem-calc(20px);
          }
        }

        .modal-close {
          background: unset;
          top: 7px;
          right: 9px;

          @media (max-width: 640px) {
            right: 5px;
            top: 15px;
          }
        }
      }

      .modal-body {
        padding: 0;

        .modal-content {
          padding: 0;

          .row {
            padding: 0;
          }

          .description-content {
            margin-bottom: 22px;
            max-width: 558px;
            font-size: rem-calc(18px);
            font-family: $base-font-family;
            line-height: 1.56;
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
          }

          .intro {
            margin-bottom: 20px;

            @media (min-width: 641px) and (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              margin-bottom: 16px;
              font-size: rem-calc(14px);
            }
          }

          .note-dial {
            margin-bottom: 9px;

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }
          }

          .p-nowrap {
            @media (min-width: 1025px) {
              white-space: nowrap;
            }

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }

            margin-bottom: 0px;
          }

          .menu-margins {
            margin-top: 9px;
            margin-bottom: 9px;
            width: 100%;

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }
          }

          .button {
            width: 149.8px;
            padding: 10px;
            font-size: rem-calc(20px);
            margin-bottom: 20px;
            font-family: $base-font-family;

            @media (min-width: 1025px) {
              margin-top: 2px;
              margin-left: 5px;
              margin-bottom: 28px;
            }

            @media (max-width: 1024.9px) {
              font-size: rem-calc(16px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(15px);
            }

            &.primary {
              background-color: $primary-color;
              margin: auto;
            }
          }
        }
      }

      @media (max-width: $phablet-min-width) {
        max-width: 315.6px;
        height: auto;
        max-height: none;

        .modal-header {
          .modal-heading {
            font-size: rem-calc(22px);
            line-height: 28px;
            margin: 15px auto 0;
            max-width: 164px;

            @media (max-width: 640px) {
              font-size: rem-calc(18px);
            }
          }

          .modal-close {
            right: 0;
            top: 1px;
          }
        }

        .modal-body {
          .modal-content {
            padding: 0 10px;

            .description-content {
              font-size: rem-calc(15px);
              line-height: 22px;
            }

            .list-content {
              padding: 5px;
              font-size: rem-calc(13px);

              li {
                span {
                  font-size: rem-calc(13px);
                  line-height: 22px;
                  font-weight: bold;
                }
              }
            }

            .modal-actions {
              padding: 0 5px 20px;
            }

            .button {
              width: 145px;
              font-size: rem-calc(20px);
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    &.compatibility-help-modal {
      width: 100%;
      max-width: 720px;
      border: 1px solid $input-border;
      height: 457px;

      @media (min-width: 641px) and (max-width: 725.9px) {
        overflow: scroll;
      }

      .modal-header {
        .modal-heading {
          width: 100%;
          font-size: rem-calc(25px);
          font-family: $base-font-family;
          font-weight: 500;
          line-height: 33px;
        }
      }

      .modal-body {
        padding: 0 15px;

        @media (#{$larger-than-phablet}) {
          padding: 0 30px;
        }

        .modal-content {
          padding: 0.5em 1em;

          .row {
            padding: 0;
          }

          .description-content {
            margin-bottom: 22px;
            max-width: 540px;
            font-size: rem-calc(18px);
            font-family: $base-font-family;
            line-height: 21px;
          }

          .list-content {
            text-align: left;
            font-weight: bold;
            line-height: 30px;
            font-family: $base-font-family;
            margin: 0;
            padding: 5px 15px;
            font-size: rem-calc(18px);

            li {
              ul {
                margin: 0;
                padding: 0;
                list-style: disc;
              }
            }
          }

          .button {
            width: 149.8px;
            padding: 10px;
            font-size: rem-calc(20px);
            font-family: $base-font-family;

            @media (min-width: 1025px) {
              margin-top: 2px;
              margin-left: 5px;
            }

            &.primary {
              background-color: $secondary-color;
            }
          }
        }
      }

      @media (max-width: $phablet-min-width) {
        max-width: 315.6px;
        height: auto;
        max-height: none;

        .modal-header {
          .modal-heading {
            font-size: rem-calc(22px);
            line-height: 28px;
            margin: 15px auto 0;
            max-width: 164px;
          }

          .modal-close {
            right: 0;
            top: 1px;
          }
        }

        .modal-body {
          .modal-content {
            padding: 0 10px;

            .description-content {
              font-size: rem-calc(15px);
              line-height: 22px;
            }

            .list-content {
              padding: 5px;
              font-size: rem-calc(13px);

              li {
                span {
                  font-size: rem-calc(13px);
                  line-height: 22px;
                  font-weight: bold;
                }
              }
            }

            .modal-actions {
              padding: 0 5px 20px;
            }

            .button {
              width: 145px;
              font-size: rem-calc(20px);
            }
          }
        }
      }
    }

    &.usp-pop-up-modal {
      width: 720px;
      min-height: 335px;

      @media (max-width: 888.9px) {
        overflow: scroll;
      }

      @media (min-width: 730px) {
        width: 696px;
        min-height: 262px;
      }

      @media (max-width: 729.9px) {
        width: 300px;
        min-height: 525px;
      }

      @media (max-width: 639.9px) {
        min-height: 417px;
      }


      .modal-header {
        text-align: left;
        padding: 42px 47px;
        padding-top: 0px;

        @media (max-width: 729.9px) {
          padding: 40px 39px;
          padding-top: 0;
          margin-bottom: 40px;
        }

        .modal-heading {
          width: 100%;
          height: 10px;
          font-size: rem-calc(28px);
          font-weight: bold;
          line-height: 1.32;
          text-align: left;
          color: #383838;
          font-family: $base-font-family;

          @media (max-width: 729.9px) {
            margin-bottom: 41px;
          }
        }

        .modal-close {
          background-color: unset;
        }
      }

      .modal-body {
        overflow: hidden;
        max-height: unset;

        .modal-content {
          text-align: left;

          .row {
            padding: 0 34px;

            @media (max-width: 729.9px) {
              padding: 0 24px;
              flex-wrap: wrap;
            }
          }

          .flex-container {
            display: flex;

            @media (max-width: 729.9px) {
              flex-direction: column;
            }
          }

          .pop-header {
            display: inline-block;
            padding-right: 18px;
            width: 310px;
            font-family: $base-font-family;
            margin-bottom: 8px;

            @media (max-width: 729.9px) {
              width: 100%;
              padding: 0;
              p:first-child b{
                display: block;
                margin-left: 20px;
                margin-top: -26px;
              }
            }
            
          }

          .sub-padding {
            padding-left: 18px;
          }

          .modal-actions {
            margin-top: 24px;

            @media (max-width: 729.9px) {
              margin-top: 4px;
            }
          }

          .modal-actions button {
            font-family: $base-font-family;

            @media (max-width: 729.9px) {
              min-width: 0;
            }
          }

          &.secondary {
            margin-left: 16px;
          }

          &.primary {
            margin-left: 38px;
          }
        }
      }
    }

    &.usp-pop-up-modal2 {
      width: 696px;
      max-width: 720px;
      height: 360px;

      @media (max-width: 729.9px) {
        overflow: scroll;
        width: 300px;
        height: 462px;
      }

      .modal-header {
        text-align: left;
        padding: 32px 24px;

        @media (max-width: 729.9px) {
          padding: 24px 18px;
        }

        .modal-heading {
          width: 100%;
          height: 10px;
          font-size: rem-calc(28px);
          font-weight: bold;
          line-height: 1.32;
          text-align: left;
          color: #383838;
          font-family: $base-font-family;

          @media (max-width: 729.9px) {
            max-width: 268px;
            margin-bottom: 40px;
            font-size: rem-calc(26px);
          }
        }

        .modal-close {
          background-color: unset;
        }
      }

      .modal-body {
        overflow: hidden;
        max-height: unset;

        .modal-content {
          text-align: left;
          padding-top: 3px;

          .row {
            padding: 0 34px;

            @media (max-width: 729.9px) {
              padding: 0 24px;
            }
          }

          .flex-container {
            display: flex;
            flex-direction: column;

            @media (max-width: 729.9px) {
              width: 272px;
              height: 197px;
            }
          }

          .pop-header1 {
            display: inline-block;
            padding-right: 52px;
            width: 560px;
            font-family: $base-font-family;
            font-size: rem-calc(17px);
            line-height: 1.18;
            margin-bottom: 20px;

            @media (max-width: 729.9px) {
              width: 290px;
              margin-bottom: 15px;
            }
          }

          .pop-header2 {
            display: inline-block;
            padding-right: 52px;
            width: 560px;
            font-family: $base-font-family;
            font-size: rem-calc(17px);
            line-height: 1;

            @media (max-width: 729.9px) {
              width: 290px;
            }
          }

          .sub-padding {
            padding-left: 18px;
          }

          .modal-actions {
            @media (min-width: 730px) {
              margin-top: 0px;
            }
          }

          .modal-actions button {
            margin-left: 26px;
            font-family: $base-font-family;
            font-size: rem-calc(20px);

            @media (max-width: 1024.9px) {
              margin-left: 0px;
            }

            @media (max-width: 729.9px) {
              width: 181px;
              font-size: rem-calc(16px);
            }
          }

          &.secondary {
            margin-left: 16px;
          }

          &.primary {
            margin-left: 38px;
          }
        }

        .modal-content>*:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.autoPay-help-modal {
      width: 100%;
      max-width: 960px;
      border: 1px solid $input-border;

      .modal-header {
        .modal-heading {
          width: 100%;
          text-align: left;
          margin: 15px 0 0;

          @media (#{$larger-than-phablet}) {
            font-size: rem-calc(25px);
            padding: 0 30px;
            font-family: $base-font-family;
            font-weight: bold;
            line-height: 30px;
          }

          @media (max-width: 1024px) {
            padding: 0 55px;
          }

          @media (max-width: 640px) {
            padding: 0 20px;
          }

          font-size: rem-calc(31px);
          width: 100%;
          line-height: 41px;
        }
      }

      .modal-content {
        text-align: left;
        padding: 0 1em 20px;
        font-size: rem-calc(16px);

        .description-content {
          margin-bottom: 20px;
        }

        .payment-sub {
          margin-bottom: 20px;
        }

        @media (#{$larger-than-phablet}) {
          font-size: rem-calc(17px);
        }
      }

      .modal-actions {
        margin: 0 0 1em;

        .button {
          padding: 10px;
          width: 200px;
          margin-top: 0;
          font-size: rem-calc(14px);
        }

        @media (max-width: 640px) {
          display: block;

          .button {
            display: block;
            margin: 0 auto;
            width: 213px;
            font-size: rem-calc(20px);
          }
        }
      }

      .modal-close {
        background: #fff;
      }

      .modal-content>* {
        padding: 0.5em 1em;

        @media (#{$larger-than-phablet}) {
          padding: 0 50px 20px;
        }
      }
    }

    &.delete-phone-confirm-modal {
      @media (min-width: 1200px) {
        width: 60%;
      }

      .modal-header {
        .modal-heading {
          @media (min-width: 450px) {
            line-height: 28px;
            width: 68%;
          }

          @media (max-width: 450px) {
            line-height: 31px;
            font-size: rem-calc(22px);
          }
        }
      }

      .modal-body {
        .modal-content {
          .row {
            @media (min-width: 640px) {
              padding: 0.5em 1em;
            }

            .button {
              width: 200px;
              height: 43px;
              padding: 0px;

              &:nth-child(1) {
                @media (min-width: 883px) {
                  margin-right: 40px;
                }

                margin-right: 1%;
              }

              &:nth-child(2) {
                background-color: transparent;
                border: 1px solid $primary-color;
                color: $primary-color;
              }
            }
          }
        }
      }
    }

    &.skip-modal {
      width: 100%;
      max-width: 700px;

      @media (max-width: $desktop-min-width-minus) {
        width: 90%;
      }

      @media (max-width: $phablet-min-width-minus) {
        max-width: 552px;
      }

      .modal-body {
        .modal-content {
          .flex {
            display: inline-flex;
            align-items: center;
          }

          .tooltip {
            .tooltipInfo {
              width: 90%;
              top: 150px;
              right: 40px;

              span {
                display: block;
              }

              &::after {
                right: 220px;
              }

              @media (max-width: $phablet-min-width-minus) {
                top: 130px;
                right: 16px;

                &::after {
                  right: 104px;
                }
              }

              @media (max-width: 469px) {
                top: 155px;
                right: 16px;

                &::after {
                  right: 50%;
                }
              }

              @media (max-width: 352px) {
                top: 174px;

                &::after {
                  right: 39%;
                }
              }
            }
          }

          .info {
            width: 17px;
            height: 17px;
            margin-left: 2px;
            margin-bottom: 3px;

            @media (max-width: $phablet-min-width-minus) {
              width: 16px;
              height: 16px;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;
          margin-top: 24px;

          @media (max-width: 840px) {
            flex-direction: column;
          }

          button {
            font-size: rem-calc(16px);
            line-height: 20px;
            font-weight: 700;
            width: 247px;
            padding: 12px 24px
          }
        }

        .check-section {
          display: flex;
          align-items: center;
          justify-content: center;

          .not-sure {
            font-size: rem-calc(18px);
            line-height: 24px;
          }

          .button {
            padding: 12px 8px;
          }

          @media (max-width: $phablet-min-width-minus) {
            flex-direction: column;
          }
        }
      }
    }
    &.delivery {
      width: 100%;
      max-width: 700px!important;
    
      .modal-body {
        .modal-content>* {
          padding: 20px 35px;
        }
    
        .modal-content {
          padding: 20px;
          display: flex;
          flex-direction: column;
    
          .id-section {
            padding: 0;
    
            .id {
              padding: 0px;
              margin-bottom: 10px;
            }
          }
    
          .dates {
            padding: 0;
          }
    
          .details {
            padding: 0 10px;
          }
    
          .date-title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 20px;
            padding: 0;
    
            img {
              margin-right: 10px;
            }
            .title {
              margin-bottom: 0;
            }
          }
    
          .step {
            display: flex;
            justify-content: space-between;
            padding: 0;
    
            .time-details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              max-width: 92px;
    
              .status {
                margin-bottom: 5px;
              }
    
              .location {
                font-size: rem-calc(12px);
              }
            }
          }
    
          .modal-actions {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    &.verify-issue-modal {
      padding: 24px;
      width: 600px!important;
      .modal-header {
        margin-bottom: 0;
        .modal-close {
          top: -7px;
          right: 0;
        }
    
        .modal-heading {
          padding: 0px 12px 0;
          margin: 0;
        }
      }
      .modal-body {
        .modal-content {
          padding: 16px 0 0;
          .row {
            padding: 0;
            .last {
              margin-top: 12px;
              margin-bottom: 0px;
            }
            &.modal-actions {
              display: flex;
              gap: 16px;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              
            }
          }
        }
      }
      @media(max-width: $desktop-min-width-minus) {
        max-width: 600px!important;
        width: 100%!important;
        .modal-header {
          .modal-heading {
            max-width: 345px;
            margin: 0 auto;
          }
        }
      }
      @media(max-width: $phablet-min-width-minus) {
        padding: 16px;
        .modal-header {
          .modal-heading {
            max-width: 324px;
            padding: 0 16px 0;
          }
        }
        .modal-body {
          .modal-content {
            padding: 16px 0 0;
            .row {
              .last {
                margin-top: 8px;
                margin-bottom: 0px;
              }
              &.modal-actions {
                margin-top: 0;
                 flex-direction: column;
                 gap: 16px;
                 .button {
                  width: 173px;
                 }
              }
            }
          }
        }
      }
    }
  }

  .modal-body {
    max-height: calc(90vh - 130px);
    overflow-y: auto;
  }
}

.modal.is-visible,
.modal.fade.in {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal.fade.show {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.8);
  visibility: hidden;
  opacity: 0;
}

.modal-backdrop {
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay,
.modal-backdrop.fade.in {
  opacity: 1;
  visibility: visible;
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}