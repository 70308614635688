h1,
h2,
h3,
h4,
h5,
p {
  font-family: $base-font-family;
  font-style: normal;
  margin: 0;
}

h1,
.h1 {
  font-size: rem-calc(36px);
  line-height: 44px;
  letter-spacing: -0.72px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(26px);
    line-height: 38px;
    letter-spacing: -0.52px;
  }
}

h2,
.h2 {
  font-size: rem-calc(32px);
  line-height: 40px;
  letter-spacing: -0.64px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(24px);
    line-height: 36px;
    letter-spacing: -0.48px;
  }
}

h3,
.h3 {
  font-size: rem-calc(28px);
  line-height: 36px;
  letter-spacing: -0.56px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(22px);
    line-height: 32px;
    letter-spacing: -0.44px;
  }
}

h4,
.h4 {
  font-size: rem-calc(24px);
  line-height: 32px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(20px);
    line-height: 28px;
  }
}

h5,
.h5 {
  font-size: rem-calc(20px);
  line-height: 28px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(18px);
    line-height: 26px;
  }
}

p {
  font-size: rem-calc(18px);
  line-height: 28px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px);
    line-height: 24px;
  }

  &.smaller {
    font-size: rem-calc(16px);
    line-height: 24px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: rem-calc(14px);
    }
  }

  &.smallest {
    font-size: rem-calc(14px);
    line-height: 22px;

    @media (max-width: $phablet-min-width-minus) {
      font-size: rem-calc(12px);
      line-height: 20px;
    }
  }

  &.caption {
    font-size: rem-calc(12px) !important;
    line-height: 20px !important;
  }

  &.label {
    font-size: rem-calc(12px) !important;
    line-height: 12px !important;
  }

  &.footer {
    font-size: rem-calc(10px) !important;
    line-height: 20px !important;
  }
}

// Pre
pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

// Text format helpers
.text-italic {
  font-style: italic;
}

.text-bold,
strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-color-primary {
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.text-color-secondary {
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.text-color-thridly {
  color: $third-color-dark;

  &:hover {
    color: $third-color-dark;
  }
}

.text-color-alert {
  color: $red;
}

.text-color-warning {
  color: $orange;
}

.text-color-success {
  color: $green;
}