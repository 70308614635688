.error-shades {
    box-shadow: 0px 4px 13px rgba(75, 75, 75, 0.1), 0px 2px 9px rgba(75, 75, 75, 0.05),
        0px 6px 7px rgba(161, 0, 0, 0.07);
    border-radius: 38px;
    background: #fbe5e5;
}
.warning-shades {
    background: #fef3e9;
    box-shadow: 0px 4px 13px rgba(75, 75, 75, 0.1), 0px 2px 9px rgba(75, 75, 75, 0.05),
        0px 6px 7px rgba(186, 85, 0, 0.07);
    border-radius: 38px;
}
.success-shades {
    background: #e5fbee;
    box-shadow: 0px 4px 13px rgba(75, 75, 75, 0.1), 0px 2px 9px rgba(75, 75, 75, 0.05),
        0px 6px 7px rgba(0, 115, 0, 0.07);
    border-radius: 38px;
}
.info-shades {
    background: #e5f3fb;
    box-shadow: 0px 4px 13px rgba(75, 75, 75, 0.1), 0px 2px 9px rgba(75, 75, 75, 0.05),
        0px 6px 7px rgba(0, 100, 170, 0.07);
    border-radius: 38px;
}
.popup-shades {
    background: $white;
    box-shadow: 0px 4px 4px rgba(75, 75, 75, 0.04), 0px 7px 16px rgba(75, 75, 75, 0.23),
        0px 5px 12px rgba(24, 140, 217, 0.04);
    border-radius: 38px;
}
.dropdown-shades {
    background: $white;
    box-shadow: 0px 4px 8px rgba(75, 75, 75, 0.03), 0px 7px 28px rgba(75, 75, 75, 0.1),
        0px 5px 14px rgba(24, 140, 217, 0.04);
    border-radius: 38px;
}
.card-shades {
    background: $white;
    box-shadow: 0px 4px 12px rgba(75, 75, 75, 0.03), 0px 8px 30px rgba(75, 75, 75, 0.08),
        0px 5px 16px rgba(24, 140, 217, 0.02);
    border-radius: 38px;
}
.toggle-shades {
    background: $white;
    box-shadow: 0px 1px 2px rgba(57, 57, 57, 0.2), 0px 0.1px 0.3px rgba(57, 57, 57, 0.1);
    border-radius: 38px;
}
.sperator-shades {
    background: $white;
    box-shadow: 0px 0px 2px rgba(57, 57, 57, 0.2), 0px 0px 0.3px rgba(57, 57, 57, 0.1);
    border-radius: 38px;
}
.primary-button-shades {
    background: $primary-color;
    box-shadow: 0px 4px 12px rgba(94, 94, 94, 0.16), 0px 7px 24px rgba(94, 94, 94, 0.2),
        0px 5px 16px rgba(24, 140, 217, 0.12);
    border-radius: 38px;
}
.tooltip-shades {
    background: #003d6b;
    box-shadow: 0px 4px 8px rgba(94, 94, 94, 0.12), 0px 7px 28px rgba(94, 94, 94, 0.18),
        0px 5px 14px rgba(0, 61, 107, 0.12);
    border-radius: 38px;
}
