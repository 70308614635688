.button {
  font-size: rem-calc(20px);
  line-height: 20px;
  font-family: $base-font-family;
  font-weight: $bold;
  letter-spacing: normal;
  margin: 0;
  min-width: 104px;
  padding: 12px 24px;
  outline: none;
  border-radius: 24px;
  background-image: none;
  color: $primary-color;
  cursor: pointer;
  width: fit-content;
  border: none;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px) !important;
    line-height: 16px !important;
    padding: 8px 24px;
    min-width: 96px;
  }

  &:hover {
    color: $hover !important;
  }

  &:focus {
    color: $focus !important;
  }

  &:disabled {
    color: $black-shade-300 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  &.disabled {
    color: $black-shade-300;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.primary {
    color: $white !important;
    background: $primary-color;

    &:hover {
      background: $hover;
    }

    &:disabled {
      background: $black-shade-300;
    }

    &.disabled {
      background: $black-shade-300;
    }
  }

  &.secondary {
    background: $white;
    border: 1px solid $primary-color;

    &:hover {
      border-color: $hover;
    }

    &:disabled {
      border-color: $black-shade-300;
    }

    &.disabled {
      border-color: $black-shade-300;
    }
  }

  &.tertiary {
    font-size: rem-calc(18px);
    line-height: 18px;
    background: unset;
    padding: 8px;

    &:active {
      color: $focus;
    }
  }
}

a {
  font-size: rem-calc(18px);
  line-height: 18px;
  font-weight: $bold;
  padding: 8px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px);
    line-height: 16px;
  }
}