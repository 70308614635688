.shop-phones {
  position: relative;
  padding-top: 75px;

  .phones-arrow {
    width: 10px;
    height: 10px;
    margin-top: -2px;
  }

  .subhead {
    width: 100%;
    margin: 0 auto;
    @media (#{$larger-than-tablet}) {
      width: 640px;
    }
  }

  .subtitle--fontFamily {
    font-family: $base-font-family;
  }

  .subtitle--lineHeight {
    line-height: rem-calc(26px);
  }

  .fontSize22 {
    font-size: rem-calc(22px);
  }

  .fontWeightNormal {
    font-weight: normal;
  }

  .phone {
    .phone-logo {
      height: 50px;

      img {
        max-height: 30px;
        margin-bottom: 30px;
      }
    }

    .phone-image {
      min-height: 290px;

      img {
        max-height: 255px;
      }
    }

    .button {
      font-weight: normal;
      letter-spacing: 0;
      transition: all 0.4s ease-in;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.modal .modal-content>* {
  display: inline-block;
}

#shop-phones,
#phone-models,
#phone-details {
  margin-bottom: 110px;

  @media (#{$larger-than-desktop}) {
    margin-bottom: 200px;
  }

  .back {
    @media (min-width: 320px) {
      margin-bottom: 0px;
      margin-left: 0px;
      margin-top: 20px;
    }

    @media (#{$larger-than-phablet}) {
      margin-bottom: 30px;
      margin-left: 24px;
      margin-top: 0px;
    }

    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.14;
    color: #863399;
    text-align: left;
  }

  select {
    border-radius: 0px;
    padding: 0px 15px;
    margin-bottom: 15px;
    -webkit-appearance: none;
    border-radius: 2px;
    border: 1.2px solid #000;
    width: 130px;
    height: 37.5px;
    font-size: rem-calc(16px);

    @media (#{$larger-than-phablet}) {
      font-size: rem-calc(19px);
    }
  }

  .select-arrow {
    position: absolute;
    border-color: #383838;
    border-width: 0 2px 2px 0;
    padding: 2px;
    margin-top: 24px;
    margin-right: 10px;
    width: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    height: 8px;
    display: block;
    left: 106px;
    top: 19px;
  }
}

#phone-models {
  .phone {
    .phone-image {
      min-height: 290px;
    }
  }
}

#phone-details,
.purchase-phone-band {
  .details-title {
    margin-left: 0;
    margin-right: 0;

    @media (#{$larger-than-phablet}) {
      margin-top: 0;
      margin-left: -20px;
      margin-right: -20px;
    }

    .phone-price {
      font-family: $base-font-family;
      font-weight: 500;
      line-height: 1.2;

      @media (#{$larger-than-phablet}) {
        margin-bottom: 0;
        text-align: right;
        margin-bottom: 15px;
      }
    }

    .summary {
      display: inline-block;
      color: #5c5c5c;
      font-size: rem-calc(17px);
      line-height: 1.35;

      @media (max-width: 640px) {
        text-align: center;
        color: #000;
        line-height: 1.47;
        font-size: rem-calc(15px);
      }
    }

    .no-padding {
      padding: 0;
    }
  }

  .phone-image {
    max-height: 725px;
    margin-bottom: 30px;

    &.primary-image {
      img {
        display: none;

        @media (#{$larger-than-phablet}) {
          display: block;
        }
      }
    }

    &.secondary-image {
      display: none;

      img {
        display: inline;
        padding-top: 20px;

        @media (#{$larger-than-phablet}) {
          display: none;
        }

        @media (max-width: 640px) {
          width: 106.6px;
          height: 220.8px;
        }
      }

      @media (max-width: 640px) {
        display: block;
      }
    }

    &.spec-height {
      @media (max-width: 639.9px) {
        height: 250px;
      }
    }

    @media (#{$larger-than-phablet}) {
      max-height: 550px;
      max-width: 324px;
      width: 45%;

      img {
        max-height: 390px;
        height: 390px;
        width: 206px;

        &.note {
          height: 400px;
          width: 260px;
        }
      }
    }

    @media (max-width: 640px) {
      text-align: center;
    }
  }

  .phone-info-summary {
    .seven {
      @media (min-width: 640px) {
        width: 50%;
      }
    }
    .options {
      label {
        font-size: rem-calc(18px);
        font-family: $base-font-family;

        @media (#{$larger-than-phablet}) {
          font-size: rem-calc(16px);
        }
      }
    }

    @media (#{$larger-than-phablet}) {
      width: 50%;

      .phone-price {
        width: 50%;
        text-align: left;
      }
    }

    @media (#{$larger-than-tablet}) {
      width: 50%;

      .phone-price {
        width: 50%;
        text-align: right;
      }
    }

    @media (#{$larger-than-desktop}) {
      width: 50%;

      .phone-price {
        text-align: right;
      }
    }
  }

  .options {
    margin-top: 20px;
    position: relative;
  }

  .mobile-config {
    display: block;

    @media (#{$larger-than-phablet}) {
      display: none;
    }

    label {
      font-family: $base-font-family;
      font-size: rem-calc(20px);
      color: #383838;
      line-height: 1.2;
    }

    select {
      width: 46%;
    }

    i.arrow.down {
      position: absolute;
      margin-left: -24px;
      margin-top: 13px;
      padding: 3px;
    }
  }

  ul.color-options {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;

    li {
      border: 1px solid white;
      cursor: pointer;
      display: inline-block;
      font-size: rem-calc(12px);
      float: inherit;
      height: auto;
      line-height: 18px;
      margin-bottom: 2px;
      margin-right: 5px;
      padding: 0px 5px;
      width: 45%;
      border: 1px solid #ccc;
      border-radius: 3px;
      transition: all 0.3s ease-in;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (#{$larger-than-phablet}) {
        font-size: rem-calc(14px);
        margin-right: 10px;
        padding: 0 15px 0 10px;
        width: auto;
        height: 34px;
        overflow: inherit;
      }

      &.selected {
        border: 1px solid $primary-color;
      }

      &.selected2 {
        border: 1px solid $primary-color;
      }

      &:hover {
        color: $primary-color;
      }

      .color-swatch {
        border-radius: 15px;
        width: 13px;
        height: 13px;
        margin: 8px 2px 5px 0;
        display: inline-block;
        padding: 5px;
        box-shadow: 0 0 1px #000;
        border: 2px solid #fff;
        margin-bottom: 3px;
        -moz-border-radius: 50px !important;
        border-radius: 18px !important;

        @media (#{$larger-than-phablet}) {
          float: left;
          margin: 8px 7px 5px 0;
          width: 17px;
          height: 17px;
        }
      }

      .hidden-color-swatch {
        width: 24px;
        height: 24px;
        margin: 5px 0px 5px 0;
        display: inline-block;

        @media (#{$larger-than-phablet}) {
          float: left;
          margin: 5px 10px 5px 0;
        }
      }

      .name {
        display: inline-block;
        top: -6px;
        position: relative;

        @media (#{$larger-than-phablet}) {
          margin-top: 7px;
          display: inline-block;
          float: left;
          top: inherit;
        }
      }
    }
  }

  ul.capacity-options {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border: 1px solid white;
      cursor: pointer;
      display: inline-block;
      float: left;
      margin-bottom: 5px;
      margin-right: 10px;
      border-radius: 3px;
      transition: all 0.4s ease;
      font-size: rem-calc(14px);
      border: 1px solid #ccc;
      transition: all 0.3s ease;
      width: 82px;
      height: 30px;
      padding: 6px 0;
      text-align: center;

      @media (#{$larger-than-phablet}) {
        font-size: rem-calc(14px);
      }

      &.selected {
        border: 1px solid $primary-color;
      }

      &.selected2 {
        border: 1px solid $primary-color;
      }

      .option-price {
        color: $grey;
        font-size: rem-calc(12px);
        margin-left: 10px;
      }
    }
  }

  button {
    font-weight: normal;
    letter-spacing: 0;
    display: block;
    margin: 30px auto;

    @media (#{$larger-than-phablet}) {
      margin: 45px 0 0 0;
    }
  }

  .tabs {
    background: #fff;
    font-size: rem-calc(14px);
    line-height: 2;
    margin: 0 auto;
    margin-top: 60px;
    max-width: 800px;
    min-width: 300px;
    position: relative;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;

    @media (#{$larger-than-phablet}) {
      display: block;
      color: $grey;
    }

    .label1 {
      order: 1;
    }

    .product-description {
      order: 2;

      p {
        font-size: rem-calc(12px);
        line-height: 17px;
        color: #5c5c5c;
      }
    }

    .label2 {
      order: 3;
    }

    .features {
      order: 4;
    }

    .label3 {
      order: 5;
      border-bottom: 1px solid #707070;

      @media (#{$larger-than-phablet}) {
        border-bottom: 0;
      }
    }

    .tech-specs {
      order: 6;
    }

    section {
      display: none;
      padding: 10px 0 0;
      transition: all 0.5s ease;

      @media (#{$larger-than-phablet}) {
        padding: 10px 0 0;
      }
    }

    i.arrow.down {
      margin-left: 6px;
      padding: 2px;
      font-weight: bold;
      border-width: 0 1.5px 1.5px 0;
      transition: all 0.4s ease;

      @media (#{$larger-than-phablet}) {
        border-color: $primary-color;
      }
    }

    input {
      display: none;

      &:checked+label {
        color: $grey;
      }

      &:checked+label i.arrow.down {
        border-color: $grey;
        transform: rotate(225deg);
        position: absolute;
        transition: all 0.4s ease;
        margin: 1em;

        @media (#{$larger-than-phablet}) {
          transform: rotate(45deg);
          position: relative;
          margin: inherit;
          margin-left: 6px;
          margin-bottom: 3px;
        }
      }
    }

    label {
      @media (#{$larger-than-phablet}) {
        width: auto;
        text-align: center;
        color: $primary-color;
        border: 1px solid transparent;
      }

      display: inline-block;
      margin: 0 0 -1px;
      padding: 15px 40px 15px 0;
      font-weight: light;
      text-align: left;
      transition: all 0.3s ease-in;
      width: 100%;
      border-top: 1px solid #707070;

      &:hover {
        cursor: pointer;
      }
    }

    #tab1:checked~#content1,
    #tab2:checked~#content2,
    #tab3:checked~#content3 {
      display: block;
    }

    .features {
      .features-container {
        @media (#{$larger-than-mobile}) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }

      .feature {
        font-size: rem-calc(12px);
        min-height: auto;
        padding-bottom: 2em;

        @media (#{$larger-than-phablet}) {
          min-height: 200px;
          padding-bottom: 0;
        }

        &.width-100 {
          @media (min-width: 1025px) {
            width: 100%;
          }
        }

        h4 {
          color: $grey;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 1.3;
        }
      }
    }

    .tech-specs {
      padding: 0;

      .spec-section {
        font-size: rem-calc(12px);

        h4 {
          border: 1px solid $grey;
          background-color: $grey;
          float: left;
          margin-bottom: 10px;
          padding: 5px 10px;
          width: 100%;
        }

        ul.specs {
          float: left;
          list-style: none;
          margin: 0;
          //margin-bottom: 20px;
          padding: 0;
          width: 100%;

          li {
            border-bottom: 1px solid $grey;
            display: block;
            float: left;
            line-height: 1.4;
            padding: 15px 0;
            width: 100%;

            .spec-label {
              display: inline-block;
              font-family: $base-font-family;
              font-weight: $bold;
              float: left;
              letter-spacing: 1px;
              padding-right: 10px;
              text-transform: uppercase;
              width: 50%;
            }

            .spec-value {
              display: inline-block;
              float: left;
              width: 50%;

              i {
                color: $primary-color;
                font-size: rem-calc(18px);
              }
            }
          }
        }
      }
    }
  }
}

.phones {
  &.mobile-shown {
    display: block;

    @media (#{$larger-than-mobile}) {
      display: none;
    }
  }

  &.mobile-hide {
    display: none;

    @media (#{$larger-than-mobile}) {
      display: block;
    }
  }

  .swiper-container {
    max-width: 1500px;
    width: 100%;
    overflow: hidden !important;
    position: relative;
  }

  .swiper-button-next.swiper-button-white {
    position: absolute;
    background-color: $primary-color;
    background-size: 28%;
    height: 33px;
    width: 33px;
    background-position: center;
    background-repeat: no-repeat;
    left: 82%;
    z-index: 1;
    cursor: pointer;
    top: 204px;
    --swiper-navigation-size: 11px;
    color: white;
  }

  .swiper-button-prev.swiper-button-black {
    position: absolute;
    background-color: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28%;
    height: 33px;
    width: 33px;
    right: 15%;
    z-index: 1;
    left: 6%;
    top: 204px;
    --swiper-navigation-size: 11px;
    --swiper-navigation-color: white;
    cursor: pointer;

    &.swiper-button-disabled {
      background-color: #9e9e9e;
    }
  }

  .phone {
    margin-top: 65px;
    text-align: center;
    margin-bottom: 20px;

    .phone-info {
      font-size: rem-calc(18px);
      margin-bottom: 10px;
      min-height: 28px;

      @media (#{$larger-than-mobile}) {
        font-size: rem-calc(15px);
        min-height: 30px;
        margin: 10px 0 15px;
      }

      .cat-name-font-family {
        font-family: $base-font-family;
        font-size: rem-calc(15px);
      }

      .phone-price {
        font-size: rem-calc(18px);
        font-weight: $bold;
        margin-top: 6px;
        line-height: 27px;
      }

      .price-spec-height {
        @media (min-width: 639.9px) {
          display: block;
          height: 86px;
        }
      }
    }
  }

  .out-of-stock {
    .phone-image {
      a {
        cursor: auto;
      }

      img {
        opacity: 0.4;
        cursor: auto;
      }

      .out-of-stock-img {
        height: 0;

        img {
          width: 250px;
          opacity: 0.9;
          position: relative;
          bottom: 180px;
        }
      }
    }

    .phone-info {
      opacity: 0.4;
    }

    button {
      @media (max-width: 424.9px) {
        color: #f33430;
        font-weight: bold !important;
      }
    }
  }
}