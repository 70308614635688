.margin-top{
  @media (max-width: $desktop-min-width) and (min-width: $phablet-min-width){
    margin-top: -50px;
  }
}
.plan-details-section {
  font-family: $base-font-family;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: $black;
  .container-flex {
    max-width: 1320px;
  }
  .mobile-hidden{
    display: none;
    @media (#{$larger-than-mobile}) {
      display: block;
    }
  }
  .mobile-shown{
    display: block;
    @media (#{$larger-than-mobile}) {
      display: none;
    }
    &.sticky{
      height: auto;
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #fff;
      z-index: 1;
      padding: 15px 30px;
      box-shadow: 1px 1px 8px #777;
      .buttons-container{
        margin: 0;
        padding: 0;
        text-align: left;
        a{
          width: 200px;
          float: left;
          margin-right: 0;
          margin-bottom: 0;
          margin-top: 10px;
          &.proceed-btn{
            .button{
              font-size: rem-calc(20px);
              padding: 10px 20px;
            }
          }
          .button{
            min-width: -webkit-fill-available;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 0;
            font-size: rem-calc(18px);
            padding: 16px 20px;
          }
        }
      }
      .add-device{
        font-size: rem-calc(18px);
      }
    }
  }
  .back-to-plans {
    font-size: rem-calc(14px);
    font-weight: normal;
    line-height: 1.14;
    color: $primary-color;
    margin-bottom: 20px;
    @media (max-width: 750px) {
      margin-top: 50px;
    }
  }

  .plan-selected {
    font-size: rem-calc(33px);
    font-family: $base-font-family;
    font-weight: bold;
    line-height: 40px;
    @media (max-width: 1025px) {
      font-size: rem-calc(25px);
      line-height: 33px;
    }
    @media (max-width: 640px) {
      font-size: rem-calc(26px);
      line-height: 30px;
    }
  }
  .plan-title {
    margin-bottom: 15px;
    .content {
      display: inline;
      font-size: rem-calc(22px);
      line-height: 40px;
      font-weight: bold;
      font-family: $base-font-family;
      @media (max-width: 1025px) {
        font-size: rem-calc(18px);
        line-height: 33px;
      }
      @media (max-width: 640px) {
        font-size: rem-calc(18px);
        line-height: 30px;
      }
    }
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #707070;
  }

  .details-section {
    padding-top: 20px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: rem-calc(20px);
    @media (max-width: 1025px) {
      font-size: rem-calc(16px);
      padding-top: 5px;
      padding-bottom: 5px;
    }
    @media (max-width: 640px) {
      font-size: rem-calc(15px);
      padding-top: 15px;
      padding-bottom: 5px;
    }

    line-height: 1.4;

    .details-list {
      list-style: none;
      font-size: rem-calc(20px);
      font-weight: normal;
      line-height: 1.75;
      margin: 0;
      padding: 0;
      -webkit-padding-start: 0px;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      li {
        padding: 0px;
        margin: 0;
        list-style-position: outside;
        display: flex;
        .tick {
        //  content: '✓';
          color: $primary-color;
          margin-right: 5px;
          display: inline;
        }
        .help {
          background-color: $primary-color;
          color: white;
          border-radius: 100px;
          padding: 2px 6px;
          font-size: rem-calc(15px);
          margin-left: 5px;
          cursor: pointer;
        }
        .mobile {
          color: $primary-color;
          cursor: pointer;
        }
      }
      @media (#{$larger-than-phablet}) {
        li {
          height: 40px;
          .mobile {
            display: none;
          }
        }
      }
      @media (max-width: 1025px) {
        font-size: rem-calc(16px);
        line-height: 30px;
        li {
          .help {
            font-size: rem-calc(13px);
          }
        }
      }
      @media (max-width: 750px) {
        line-height: 25px;
      }
      @media (max-width: 640px) {
        font-size: rem-calc(15px);
        line-height: 25px;
        .help {
          display: none;
        }
        .default {
          display: none;
        }
      }
    }
  }
  .total {
    font-size: rem-calc(25px);
    line-height: 40px;
    font-family: $base-font-family;
    font-weight: bold;
    padding-top: 10px;
    @media (max-width: 1025px) {
      font-size: rem-calc(22px);
      line-height: 40px;
    }
    @media (max-width: 640px) {
      font-size: rem-calc(25px);
      line-height: 40px;
    }
  }
  .quantity {
    padding: 0;
    font-size: rem-calc(25px);
    font-weight: bold;
    line-height: 0.81;
    font-family: $base-font-family;
    
    hr {
      margin-top: 3px;
      margin-bottom: 3px;
      border-top: 1px solid #707070;
    }
    select {
      font-family: $base-font-family;
      font-size: rem-calc(17px);
      @media (#{$larger-than-phablet}) {
        font-size: rem-calc(20px);
      }
      line-height: 1.05;
      color: #000000;
    }
    .auto-renew-txt{
      font-size: rem-calc(17px);
      line-height: 20px;
      margin: 6px 0;
      padding-bottom: 10px;
      cursor: pointer;
      @media (max-width: 1025px) {
        font-size: rem-calc(16px);
        line-height: 18px;
      }
      @media (max-width: 640px) {
        font-size: rem-calc(15px);
        line-height: 17px;
      }
    }
  }
  
  
  .add-device {
    padding: 20px 0;
    font-family: $base-font-family;
    font-size: rem-calc(24px);
    @media (#{$larger-than-phablet}) {
      font-size: rem-calc(27px);
    }
    font-weight: 500;
    line-height: 1.3;
  }

  .buttons-container {
    padding: 5px 0;
    .button--margin{
      @media (min-width: 1025px) {
        padding-left: 50px;
        padding-right: 50px;
      }
      @media (max-width: 1025px ) and (min-width: 426px) {
        padding-left: 40px;
        padding-right: 40px;
      }
      @media (max-width: 426px) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .maxContent{
      min-width: max-content;
    }
    .button{
      width: 220px;
      padding: 10px;
      font-size: rem-calc(20px);
      margin-top: 20px;
      @media (max-width: 1025px) {
        width: 200px;
        font-size: rem-calc(17px);
      }
      @media (max-width: 640px) {
        width: 200px;
        font-size: rem-calc(20px);
      }
    }
  }
}

.lineHight{
  line-height: rem-calc(30px);
}

.mainSection--width{
  @media (max-width: 1650px) and (min-width: 1025) {
    width:90%;

  }
}
.checkout-result-page-section {
  .checkout-result {
    text-align: center;
    margin-bottom: 90px;
    .header {
      text-align: center;
      font-family: $base-font-family;
      font-size: rem-calc(38px);
      line-height: 1.32;
      margin-bottom: 10px;
      @media (max-width: $tablet-min-width) and (min-width: $phablet-min-width) {
        margin-top: 60px;
      }
    }
    .description {
      font-size: rem-calc(20px);
      font-family: $base-font-family;
      line-height: 1.25;
    }
    .note {
      max-width: 633px;
      margin: 25px auto 35px;
      .note-description {
        font-size: rem-calc(20px);
        font-family: $base-font-family;
        line-height: 1.25;
        margin-bottom: 0;
      }
    }
    .image {
      img {
        width: 200.6px;
        height: 98px;
      }
    }
    .actions {
      .button {
        width: 213px;
        padding: 10px;
        font-size: rem-calc(20px);
        font-family: $base-font-family;
      }
    }
    .phone-section {
      margin-top: 70px;
      .phone-icon {
        border-radius: 100px;
        margin: 0 auto;
        width: 52.8px;
        height: 52.8px;
        padding: 10px;
        img {
          width: 17.6px;
          height: 29.6px;
        }
      }
      .phone-image {
        margin: 0 auto 30px;
        img {
          width: 54.1px;
          height: 105px;
        }
      }
      .description {
        .title {
          font-size: rem-calc(25px);
          line-height: 1.25;
          font-weight: bold;
        }
        .description-text {
          font-size: rem-calc(20px);
          font-family: $base-font-family;
          line-height: 1.25;
          max-width: 492px;
          margin: 30px auto 55px;
          &.base {
            line-height: 1.25;
            font-family: $base-font-family;
          }
        }
        .description-device {
          font-size: rem-calc(18px);
          font-family: $base-font-family;
          line-height: 24px;
          max-width: 230px;
          font-weight: 500;
          margin: 10px auto;
          &.details {
            margin: 20px auto 0;
            &:last-child {
              margin: 0 auto 30px;
            }
          }
        }
      }
      .phone-actions {
        .button {
          width: 216px;
          padding: 10px;
          font-size: rem-calc(20px);
          &.primary {
            margin-left: 15px;
          }
          &.purchase {
            display: block;
            margin: 0 auto 30px;
          }
          &.change-button {
            width: 213px;
            margin: 0 auto;
            font-size: rem-calc(20px);
            line-height: 27px;

          }
        }
        .change {
          font-size: rem-calc(18px);
          font-family: $base-font-family;
          margin: 0 auto;
        }
      }
    }
  
    @media (max-width: 1025px) {
      .header {
        font-size: rem-calc(27px);
        line-height: 35px;
        margin-bottom: 10px;
        margin-top: 30px;
      }
      .description {
        font-size: rem-calc(15px);
        line-height: 25px;
        max-width: 100%;
        margin: 15px auto 25px;
      }
      .image {
        img {
          width: 164.1px;
          height: 80.2px;
        }
      }
      .note {
        max-width: 486.9px;
        margin: 35px auto;
        .note-description {
          font-size: rem-calc(15px);
          line-height: 25px;
          margin-bottom: 0;
        }
      }
      .actions {
        .button {
          font-size: rem-calc(16px);
          line-height: 21px;
        }
      }
      .phone-section {
        .phone-image {
          img {
            width: 44.3px;
            height: 85.9px;
          }
        }
        .description {
          .title {
            font-size: rem-calc(20px);
            line-height: 25px;
          }
          .description-text {
            font-size: rem-calc(15px);
            line-height: 25px;
            max-width: 399px;
          }
          .description-device {
            font-size: rem-calc(20px);
            line-height: 27px;
            max-width: 270px;
          }
        }
        .phone-actions {
          .change-button {
            font-size: rem-calc(16px);
          }
          .purchase {
            font-size: rem-calc(16px);
          }
          .change {
            font-size: rem-calc(14px);
            line-height: 19px;
          }
        }
      }
    }
    @media (max-width: $phablet-min-width) {
      margin-bottom: 35px;
      .header {
        font-size: rem-calc(26px);
        line-height: 34px;
        margin-bottom: 10px;
        margin-top: 30px;
      }
      .description {
        font-size: rem-calc(17px);
        line-height: 1.29;
        max-width: 316px;
        margin: 25px auto;
      }
      .note {
        max-width: 316px;
        margin: 25px auto 40px;
        .note-description {
          font-size: rem-calc(17px);
          line-height: 27px;
          margin-bottom: 0;
        }
      }
      .actions {
        .button {
          width: 220px;
          font-size: rem-calc(20px);
        }
      }
      
      .phone-section {
        margin-top: 30px;
        .phone-icon {
          width: 38px;
          height: 38px;
          padding: 7px;
          img {
            width: 12.6px;
            height: 21.3px;
          }
        }
        .phone-image {
          img {
            width: 54.1px;
            height: 105px;
          }
        }
        .description {
          .title {
            font-size: rem-calc(20px);
            line-height: 25px;
            margin-bottom: 25px;
          }
          .description-text {
            font-size: rem-calc(18px);
            line-height: 1.33;
            max-width: 302px;
            margin: 0 auto;
            &.base {
              line-height: 1.39;
              max-width: 315px;
              margin: 0 auto 35px;
            }
          }
          .description-device {
            max-width: 270px;
          }
        }
        .phone-actions {
          .button {
            margin: 20px auto;
            display: block;
            &.primary {
              margin: 15px auto;
            }
            &.change-button {
              width: 213px;
              font-size: rem-calc(20px);
            }
            &.purchase {
              font-size: rem-calc(20px);
            }
          }
        }
      }
    }
  }
}
.checkout-page-section {

  @include create-mobile-hidden-classes(inline-block);
  @include create-mobile-shown-classes(inline-block);
  @media (#{$larger-than-phablet}) {
    padding-top: 30px;
    .mobile-hidden-inline {
      display: inline;
    }
    .mobile-shown-inline {
      display: none;
    }
  }
  @media (#{$larger-than-desktop}) {
    padding-top: 60px;
  }
  .container-flex {
    max-width: 1320px;
  }
  .margin-top-35 {
    margin-top: 10px;
    @media (#{$larger-than-mobile}) {
       margin-top: 35px;
    }
  }
  hr {
    border-top: 1px solid #707070;
    @media (#{$larger-than-mobile}) {
      margin: 26px auto;
    }
    @media (#{$larger-than-desktop}) {
      margin: 29px auto;
    }
  }
  .radio-checkbox {
    .delivery-method-label{
      font-size: 12px;
      margin-top:20px;
      line-height: .5em;
      padding:0;
      }
  }
  .order {
    padding-left: 60px;
  }
  .button{
    padding: 9px;
    float: right;
    margin-bottom: 2em;
    font-size: rem-calc(18px);
    width: 145px;
    &#place-your-order-button{
      width: 200px;
      padding: 10px;
      float: left;
      font-size: rem-calc(14px);
      
    }
    @media (#{$larger-than-mobile}) {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
  label, fieldset {
    margin-bottom: 14px;
    @media (#{$larger-than-mobile}) {
      margin-bottom: 16px;
    }
    @media (#{$larger-than-desktop}) {
      margin-bottom: 10px;
    }
  }
  h1 {
    @media (max-width: 640px) {
      padding-top: 25px;
    }
  }
  .card-types{
    height: 40px;
    margin-left: 0;
    margin-top: 0px;
    display: inline-block;
    @media (#{$larger-than-desktop}) {
      margin-left: 0;
      margin-top: 0px;
      float: right;
    }
    @media (max-width: 834px){
      margin-left: -32px;
      transform: scale(0.7);
      float: right;
    }
    @media (max-width: 426px){
      margin-left: -25px;
      margin-top: 3px;
      width: 80%;
      transform: scale(0.8);
  
    }
  }
  .add-payment-plus{
    color: $primary-color;
    transition: all 0.3s ease-in;
    font-size: rem-calc(15px);
    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(22px);
      height: 60px;
      padding-top: 18px;
    }
    cursor: pointer;
    @media (max-width: 704px) {
      padding: 9px 13px 25px 14px;
      height: 36px;
    }
    @media (max-width: 356px) {
      padding: 9px 0px 25px 0px;
      height: 36px;
      font-size: rem-calc(14px);
    }
    & .plus{
      font-size: rem-calc(20px);
      margin-right: 9px;
      line-height: 0;
      font-weight: 100;
      @media (#{$larger-than-phablet}) {
        font-size: 21px;
        margin-right: 7px;

      }
    }
    &:hover{
        transform: scale(1.01);
    }
  }

  h3 {
    font-size: rem-calc(22px);
    margin-bottom: 15px;
    font-family: $base-font-family;
    @media (#{$larger-than-phablet}) {
      margin-bottom: 10px;
      margin-bottom: 18px;
    }
    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(28px);
      margin-bottom: 13px;
      line-height: 37px;
      font-family: $base-font-family;
    }
    @media (max-width: 1025px) {
      &.order-title {
        font-size: rem-calc(18px);
        font-family: $base-font-family;
        line-height: 24px;
      }
    }
    @media (max-width: 834px) {
      &.mobile-shown {
        font-size: rem-calc(26px);
        font-family: $base-font-family;
        font-weight: bold;
        line-height: 34px;
      }
    }
    &.desktop-hidden {
      @media (min-width: $phablet-min-width) {
        display: none;
      }
    }
    &.mobile-hidden{
      margin-bottom: 0;
      display: none;
      @media (#{$larger-than-phablet}) {
        display: block;
      }
    }
  }

  h4 {
    font-size: rem-calc(22px);
    margin-bottom: 0;

    @media (#{$larger-than-mobile}) {
      font-size: rem-calc(17px);
      margin-bottom: 10px;
    }

    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(22px);
      margin-bottom: 13px;
    }
  }
  .padding-container {
    @media (min-width:426px) and (max-width: 640px) {
      padding: 16px 0;
    }
  }
  &.pay {
    padding-top: 0;
  }
  .checkout-content-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
    h1{
      font-size: rem-calc(26px);
      line-height: 27px;
      margin-bottom: 0;
      &.mobile {
        display: none;
      }
      @media (min-width: 834px) {
        font-size: rem-calc(27px);
        line-height: 35px;
        margin-top: 55px;
      }
      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(38px);
        line-height: 50px;
        margin-bottom: 0px;
        margin-top: 50px;
      }
      @media (max-width: 834px) {
        &.mobile {
          display: block;
          font-size: rem-calc(26px);
          font-family: $base-font-family;
          line-height: 34px;
          margin-top: 30px;
        }
        &.desktop {
          display: none;
        }
      }
    }
    .margin-bottom-30{
      margin-bottom: 10px;
      @media (#{$larger-than-phablet}) {
        margin-bottom: 30px;
      }
    }
    .secondary-font{
      @media (#{$larger-than-phablet}) {
        font-size: rem-calc(18px);
      }
      @media (#{$larger-than-desktop}) {
        font-size: rem-calc(22px);
      }
      font-size: rem-calc(18px);
    }
    .checkout-left-side-content {
      .place-order {
        display: flex;
        flex-direction: column;
      }
      .acceptance-terms {
        font-size: rem-calc(12px);
        color: $grey;
        line-height: 18px;
      }
      .checkout-step {
        background-color: #d8f1ff;
        padding: 16px 30px;
        border-radius: 10px;
        height: 60px;
        margin-bottom: 20px;
        color: white;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
        &.checked {
          background-color: white;
          border-bottom: 1px solid $primary-color;
          border-radius: 0;
          cursor: default;
          padding: 10px;
          height: 45px;
          .check-icon {
            background-color: $primary-color;
            padding: 1px 8px;
            margin-right: 15px;
            border-radius: 100px;
            @media(max-width: 1025px) {
              padding: 2px 5px;
            }
            @media (max-width: 834px) {
              padding: 3px 6px;
              margin-right: 5px;
            }
            @media (max-width: 426px) {
              padding: 1px 6px;
            }
          }
          img {
            margin-bottom: 2px;
          }
          .step-title {
            color: $black;
            display: inline-block;
          }
          .edit {
            display: block;
            float: right;
            color: $primary-color;
            font-size: rem-calc(18px);
            font-weight: bold;
            cursor: pointer;
            font-family: $base-font-family;
            @media (max-width: 1025px) {
              font-size: rem-calc(12px);
              line-height: 20px;
            }
            @media (max-width: 834px) {
              font-size: rem-calc(13px);
              line-height: 20px;
            }
          }
        }
        .step-title {
          font-size: rem-calc(22px);
          font-family: $base-font-family;
          line-height: 26px;
          margin-bottom: 0;
          color: $primary-color;
          .plus {
            font-size: rem-calc(28px);
            margin-right: 9px;
            line-height: 0;
            position: relative;
            top: 1px;
            font-weight: 100;
            @media (#{$larger-than-phablet}) {
              font-size: rem-calc(33px);
              margin-right: 13px;
      
            }
          }
        }
        @media(max-width: 1025px) {
          padding: 20px 30px;
          .step-title {
            font-size: rem-calc(15px);
            line-height: 17px;
          }
        }
        @media (max-width: 834px) {
          height: 36px;
          padding: 10px 30px 0;
        }
      }
      .step-content {
        background-color: white;
        overflow: hidden;
        &.info-content {
          .data-container {
            margin-bottom: 30px;
            padding: 0 40px;
            .data-label {
                font-size: 17px;
                line-height: 24px;
                font-family: $base-font-family;
                font-weight: 400;
                margin-bottom: 0;
            }
            .data-content {
                font-size: 17px;
                line-height: 24px;
                font-family: $base-font-family;
                font-weight: bold;
            }
            @media (max-width: 1025px) {
                padding: 0 30px;
                .data-label {
                    font-size: 15px;
                }
                .data-content {
                    font-size: 15px;
                }
            }
            @media (max-width: 834px) {
                padding: 0 20px;
            }
        }
        }
        &.shipping-content {
          border: 1px solid $primary-color;
          border-radius: 10px;
          margin-bottom: 20px;
          &.checked {
            border: none;
            transition-property: all;
	          transition-duration: .5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          }
          .subpage-action {
            padding: 0 20px;
            .button {
              margin-bottom: 30px;
              width: 145px;
              font-size: rem-calc(20px);
              padding: 10px 8px;
              font-family: $base-font-family;
              @media (max-width: 1025px) {
                width: 155px;
                font-size: rem-calc(15px);
              }
              &.disabled {
                cursor: not-allowed;
              }
            }
          }
        }
        &.payment-content {
          border: 1px solid $primary-color;
          border-radius: 10px;
          margin-bottom: 20px;
          padding: 0;
          &.checked {
            border: none;
            transition-property: all;
	          transition-duration: .5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          }
          .subpage-action {
            .button {
              margin-bottom: 30px;
              width: 145px;
              font-size: rem-calc(20px);
              padding: 10px 8px;
              font-family: $base-font-family;
              @media (max-width: 1025px) {
                width: 155px;
                font-size: rem-calc(15px);
              }
              &.disabled {
                cursor: not-allowed;
              }
            }
          }
        }
      }
      .shipping-details{
        padding: 0 60px;
        transition: all .3s ease-out;
        margin-bottom: 30px;
        .data-content {
          font-size: rem-calc(17px);
          line-height: 24px;
          margin-bottom: 2px;
          font-family: $base-font-family;
        }
        @media (max-width: 1025px) {
          padding: 0 50px;
          .data-content {
            font-size: rem-calc(15px);
            line-height: 20px;
          }
        }
        @media (max-width: 1025px) {
          padding: 0 50px;
        }
        @media (max-width: 834px) {
          padding: 0 40px;
        }
      }
      .payment-details {
        padding: 0 60px;
        transition: all .3s ease-out;
        margin-bottom: 30px;
        .data-content {
          font-size: rem-calc(17px);
          line-height: 24px;
          margin-bottom: 2px;
          font-family: $base-font-family;
        }
        @media (max-width: 1025px) {
          padding: 0 50px;
          .data-content {
            font-size: rem-calc(15px);
            line-height: 20px;
          }
        }
        @media (max-width: 1025px) {
          padding: 0 50px;
        }
        @media (max-width: 834px) {
          padding: 0 40px;
        }
      }
      .selected-shipping-address {
        padding: 8px 30px 8px 14px;
        @media (#{$larger-than-desktop}) {
           padding: 11px 30px 10px 14px;
        }
        &.margin-right-20{
          margin-right: 0px;
          @media (#{$larger-than-phablet}) {
            margin-right: 20px;
          }
        }
        .custom-checkbox{
          .checkbox-label{
            width: 100%;
            margin-bottom: 3px;
            display: block;
            @media (#{$larger-than-phablet}) {
              width: 100%;
              margin-bottom: 7px;
            }
            @media (#{$larger-than-desktop}) {
              width: auto;
            }
          }

          .address-info-line{
            margin-left: 7px;
            display: block;
            width: 70%;
            margin-top: 0;
            @media (#{$larger-than-phablet}) {
              width: 70%;
              margin-top: 6px;
            }
            @media (#{$larger-than-desktop}) {
              width: 76%;
              float: left;
            }
          }
            .edit-action{
              text-align: right;
              float: right;
              height: 12px;
              cursor: pointer;
              width: 15px;
              @media (#{$larger-than-desktop}) {
                width: 3%;
              }
          }
        }
      }
    }

    .checkout-right-side-content {
      &.margin-top-35 {
        margin-top: 0;
      }
      @media (#{$larger-than-tablet}) {
        &.margin-top-35 {
          margin-top: 35px;
          margin-bottom: 56px;

        }
      }
    }

    .checkout-left-side-content, .checkout-right-side-content {
      float: left;
      flex-basis: 100%;
      width: 100%;
      padding-bottom: 35px;
      /*height: 100%;*/
      &.hidden {
        display: none;
      }
      .card-container {
        border-radius: 11px;
        .card-header {
          font-size: rem-calc(22px);
          @media (#{$larger-than-tablet}) {
            /*font-size: rem-calc(22px); if i placed it outside card*/
            font-size: rem-calc(22px);
          }
          @media (#{$larger-than-desktop}) {
            font-size: rem-calc(28px);
          }
          &.margin-bottom-0{
            border-bottom: 1px solid #707070;
            padding-bottom: 8px;
            @media (#{$larger-than-phablet}) {
              border-bottom: 0;
            }
            @media (#{$larger-than-desktop}) {
              margin-bottom: 0;
            }
          }
        }

        margin-bottom: 15px;
        padding: 0;

        &.mobile-card-only {
          padding: 0;
          border:0;
          @media (#{$larger-than-mobile}) {
            padding: 0;
          }
          .border-normal{
            width: 100%;
            font-size: rem-calc(13px);
            @media (#{$larger-than-desktop}) {
              font-size: rem-calc(17px);
            }
            &.bg-primary{
              @media (#{$larger-than-desktop}) {
                width: 69%;
              }
            }
          }
        }

        @media (#{$larger-than-phablet}) {
          padding: 26px 23px;
        }

        &.payment-card {
          border: 0;
          .custom-checkbox {
            .checkbox input[type="radio"] {
              visibility: visible;
              left: 3px;
              position: relative;
              display: none;
            }
          }
          @media (#{$larger-than-mobile}) {
            padding: 0;
          }
        }

        @media (#{$larger-than-desktop}) {
          padding: 19px 30px 0 30px;

        }
        &.ime-container{
          text-align: center;
          @media (#{$larger-than-phablet}) {
            text-align: left;
          }
          .blue-circle{
            position: relative;
            img{
              position: absolute;
              top: 10px;
              left: 18px;
            }
          }
          .main-title{
            font-size: rem-calc(18px);
            line-height: 25px;
            font-family: $base-font-family;
            @media (#{$larger-than-phablet}) {
              font-size: rem-calc(17px);
              line-height: 20px;
              font-family: $base-font-family;
              font-weight: bold;
            }
            @media (#{$larger-than-desktop}) {
              font-size: rem-calc(20px);
              line-height: 27px;
            }
          }
          .phone-actions{
            &.phones-change{
              a{
                font-size: rem-calc(14px);
                font-weight: bold;
                &:first-child{
                  padding-right: 22px;
                }
                @media (#{$larger-than-phablet}) {
                  padding-right: 29px;
                  font-size: rem-calc(13px);
                }
                @media (#{$larger-than-desktop}) {
                  padding-right: 20px;
                  font-size: rem-calc(16px);
                }
              }
            }
          }
          @media (min-width: 640px){
          .three {
              width: 25%;
            }
            .nine {
              width: 75%;
            }
          }

        }
        &.phone-card-container {
          padding: 20px 10px 20px;
          @media (max-width: 640px) {
            border: 1px solid $black;
            border-radius: 11px;
            padding: 14px 10px 14px 20px;
          }
          .blue-circle{
            background: #7cbadb;
            border-radius: 50%;
            width: 52.8px;
            height: 52.8px;
            line-height: 50px;
            margin: 0 auto;
            margin-bottom: 1em;
            transform: scale(0.8);
            @media (#{$larger-than-mobile}) {
              transform: scale(1);
            }
          }
          .device-image {
            width: 30%;
            display: block;
            position: relative;
            padding: 0 23px;
            float: left;
            margin-top: 15px;
            margin-bottom: 15px;
            @media (#{$larger-than-phablet}) {
              padding: 0 6px;
              width: 25%;
            }
            @media (#{$larger-than-desktop}) {
              width: 30%;
              padding: 0 6px;
            }
            @media (min-width: 1225px){
              padding: 0 26px;
            }
            @media (max-width: 640px) {
              width: 25%;
            }
            img {
              width: 87px;
              height: 145px;
            }
            @media (max-width: 640px) {
              padding: 0;
              img {
                width: 66px;
                height: 127px;
              }
            }
          }
          .phone-info-summary {
            width: 70%;
            display: block;
            position: relative;
            padding: 0 15px;
            @media (#{$larger-than-phablet}) {
              width: 75%;
            }
            @media (#{$larger-than-desktop}) {
              width: 70%;
            }
            @media (max-width: 640px) {
              width: 75%;
            }
            @media (max-width: 358px) {
              padding: 0 0 0 12px;
            }
            .title {
              padding: 0;
              font-size: rem-calc(18px);
              font-family: $base-font-family;
              line-height: 25px;
              height: auto;
              margin-bottom: 5px;
              @media (#{$larger-than-desktop}) {
                font-size: rem-calc(22px);
                line-height: 28px;
                margin-bottom: 8px;
              }
            }
            .info {
              font-size: rem-calc(18px);
              margin-top: 15px;
            }
            .price-phone{
              font-size: rem-calc(17px);
              line-height: 20px;
              margin: 12px 0;
              @media (#{$larger-than-mobile}) {
                font-size: rem-calc(20px);
                line-height: 23px;
                padding-bottom: 10px;
              }
              @media (#{$larger-than-desktop}) {
                margin: 5px 0;
                padding-bottom: 3px;
              }
            }
            .color-swatch{
              height: 13px;
              width: 13px;
              display: inline-block;
              border-radius: 10px;
              margin-right: 6px;
              top: 2px;
              position: relative;
            }
            .border-box{
              border-radius: 3px;
              border: 1px solid #000;
              margin-right: 8px;
              font-size: 11px;
              line-height: 12px;
              padding : 6px 6px;
              @media (#{$larger-than-phablet}) {
                padding: 6px 3px;
                margin-right: 10px;
              }
              @media (#{$larger-than-desktop}) {
                padding: 6px 8px;
              }
              @media (max-width: 358px){
                padding: 6px 4px;
                margin-right: 5px;
              }
            }
            .phone-actions{
              a{
                font-size: rem-calc(17px);
                text-transform: uppercase;
                font-weight: bold;
                @media (max-width: 640px) and (min-width: 459px){
                  font-size: rem-calc(16px);
                }
                @media (max-width: 1084px) and (min-width: 640px){
                  font-size: rem-calc(13px);
                }
                @media (max-width: 458px) {
                  font-size: rem-calc(14px);
                }
              }
            }
          }
          .title {
            @media (#{$larger-than-phablet}) {
              font-size: 15px;
              line-height: 20px;
            }
            @media (#{$larger-than-desktop}) {
              font-size: rem-calc(24px);
              line-height: 32px;
              max-width: 350px;
              margin: initial;
              padding: 0;
            }
            &.text-center{
              margin: 0 auto;
            }
            font-size: rem-calc(18px);
            line-height: 23px;
            padding: 0 1em;
            font-family: $base-font-family;
            font-weight: bold;
            max-width: 231px;
            @media (max-width: 358px){
              font-size: rem-calc(16px);
            }
          }
          .phone-details {
            display: -webkit-inline-box;
            width: 100%;
            margin-bottom: 0px;
            font-weight: bold;
            .phone-title {
                font-size: rem-calc(20px);
                line-height: 17px;
                width: 68%;
                height: auto;
                margin-bottom: 5px;
            }
            .quantity {
              font-size: rem-calc(15px);
              width: 35%;
            }
            .phone-specs {
              font-size: rem-calc(16px);
              width: 68%;
            }
            @media (max-width: 426px) {
              display: block;
              margin-bottom: 15px;
              .phone-title {
                width: 100%;
              }
              .quantity {
                width: 100%;
              }
            }
          }
          .phone-actions {
            padding-top: 5%;
            @media (max-width: 640px) {
              padding-top: 8%;
            }
            a {
              font-size: rem-calc(20px);
              padding-right: 22px;
            }
            a {
              @media (max-width: 1024px) {
                font-size: rem-calc(16px);
                padding-right: 9px;
              }
              @media (max-width: 1124px) {
                padding-right: 19px;
              }
              @media (max-width:834px) and (min-width: 640px) {
                font-size: rem-calc(13px);
                padding-right: 14px;
              }
              @media (max-width: 640px) {
                font-size: rem-calc(15px);
              }
            }
          }
        }
        &.order-details-card-container {
          padding: 15px 15px 15px 15px;
          border-radius: 11px;
          @media (#{$larger-than-mobile}) {
            padding: 25px 30px 22px 30px;
          }
        }

        &.taxes-card-container {
          padding: 15px 15px 0;
          @media (#{$larger-than-mobile}) {
            padding: 5px 30px;
          }
        }
      }

      .hidden {
        display: none;
      }
    }

    .checkout-left-side-content {
      @media (min-width: 834px) {
        max-width: 46%;
      }

      @media (#{$larger-than-desktop}) {
        max-width: 61%; // why 61%?, don't even ask...you know round numbers don't look nice that's why
      }
      @media (max-width: 834px) {
        padding-bottom: 10px;
      }

      .page-subhead {
        font-family: $base-font-family;
        font-weight: bold;
        margin-bottom: 18px;
        p {
          font-size: rem-calc(20px);
          font-family: $base-font-family;
          font-weight: bold;
        }
        @media (#{$larger-than-phablet}) {
          p {
            font-size: rem-calc(18px);
            font-family: $base-font-family;
          }
        }
        @media (#{$larger-than-desktop}) {
          margin-bottom: 25px;
          p {
            font-size: rem-calc(22px);
            font-family: $base-font-family;
          }
        }

      }
      .description {
        font-size: rem-calc(17px);
        line-height: 22px;
        @media (#{$larger-than-phablet}) {
          font-size: rem-calc(15px);
          line-height: 19px;
        }
        @media (#{$larger-than-desktop}) {
          font-size: rem-calc(22px);
          line-height: 26px;
        }
      }
    }

    .checkout-right-side-content {
      @media (min-width: 834px) {
        max-width: 54%;
        padding-left: 31px;
      }
      @media (#{$larger-than-desktop}) {
        max-width: 39%;
        padding-left: 70px;
      }
      @media (max-width: 834px) {
        padding-bottom: 15px;
      }
    }
  }

  .shipping-address-subpage {
    padding: 14px 20px 10px;
    &.card-container {
      border: none;
      padding: 0 30px!important;
      .card-header {
        font-size: rem-calc(22px)!important;
        line-height: 28px;
        @media (max-width: 1025px) {
          font-size: rem-calc(15px)!important;
          line-height: 20px;
        }
        @media (max-width: 834px) {
          font-size: rem-calc(18px)!important;
          line-height: 24px;
          border-bottom: 1px solid $primary-color!important;
        }
      }
    }
    .highlight-card {
      &.shipping-card {
        padding: 0;
        background-color: transparent;
        .custom-checkbox {
          .address-info-line {
            font-size: rem-calc(15px);
            small {
              font-size: rem-calc(15px);
            }
          }
        }
        @media(max-width: 1025px) {
          .custom-checkbox {
            .checkbox-label {
              font-size: rem-calc(15px);
            }
            .address-info-line {
              font-size: rem-calc(13px);
              small {
                font-size: rem-calc(13px);
              }
            }
          }
        }
        @media(max-width: 834px) {
          .custom-checkbox {
            .checkbox-label {
              font-size: rem-calc(15px);
            }
            .address-info-line {
              font-size: rem-calc(15px);
              small {
                font-size: rem-calc(15px);
              }
            }
          }
        }
      }
      &.add-payment-plus {
        margin-top: 20px;
        &.highlight-none {
          font-size: ren-calc(15px);
        }
        @media (max-width: $phablet-min-width-minus) {
          padding: 9px 0px 25px 4px;
          &.highlight-none {
            white-space: nowrap;
            margin-left: -20px;
          }
        }
      }
    }
    .shipping-address-form-container {
      .shipping-method-form {
        float: left;
        fieldset {
          display: none;
        }
        input {
          height: 45px;
        }
        &.shown {
          fieldset {
            display: block;
          }
        }
        width: 100%;
        .radio-checkbox {
          .radio-checkbox-label {
            span {
              @media (max-width: 1025px) {
                margin-top: 5px;
              }
            }
          }
        }
        @media (#{$larger-than-desktop}) {
          width: 100%;
          padding-right: 0;
          .payment-form {
            .four {
              &:first-child {
                width: 33.3%;
              }
              &:nth-child(2) {
                width: 33.3%;
              }
              &:nth-child(3) {
                width: 33.3%;
              }
            }
          }
        }
        @media (#{$larger-than-desktop}) and (max-width: 1171px) {
          padding-right: 0;
          .form-section {
            .eight {
              width: 64%;
            }
            .four {
              width: 35%;
            }
          }
        }
        &.right-side {
         
          @media (#{$larger-than-desktop}) {
            width: 38%;
            padding-right: 0;
          }
          @media(max-width: 640px) {
            margin-top: 20px;
          }
        }

        .bottom-border {
          border-bottom: 1px solid #707070;
          margin-bottom: 40px;
          @media (#{$larger-than-mobile}) {
            margin-bottom: 32.4px;
          }
          @media (#{$larger-than-desktop}) {
            padding-bottom: 5px;
            margin-bottom: 28px;
          }
        }

        fieldset {
          &.address-lookup {
            @media (#{$larger-than-mobile}) {
              margin-bottom: 0;
              padding-left: 0;
              padding-right: 0px;
            }
            @media (#{$larger-than-desktop}) {
              padding-right: 15px;
              input[type="search"] {
                padding: 10px 15px;
              }
            }

            fieldset {
              margin-bottom: 0;
              padding-left: 0;
             }
             @media (max-width: 1025px){
              .four{
                width: 100%;
              }
            }
              &:last-child {
                padding-right: 0;
                @media(max-width:1024px) {
                  width: 100%;
                }
              }
            }
          }
        }

        .address-lookup {
          padding-right: 0;
          &.eight {
            @media (#{$larger-than-desktop}) {
              padding-right: 15px;
            }
          }

          i.icon-search.input-search-icon {
            position: absolute;
            top: 37px;
            left: 5px;
            display: none;
            @media (#{$larger-than-mobile}) {
              top: 35px;
              display: none;
            }
          }

          .ngui-auto-complete {
            font-size: rem-calc(18px);
          }
        }
      }
      &.subpage-action {
        text-align: right;
      }
    }

    
  }
  @media (max-width: 640px){
    .credit-card-section-container{
      .padding-right-20{
        padding-right: 0;
      }
      .highlight-card{
        padding: 8px 30px 8px 15px;
      }
    }
}
  .payment-subpage {
    padding: 14px 30px 10px;
    .title {
      font-size: rem-calc(22px);
      margin-bottom: 0;
      display: inline-block;
      margin-top: 5px;
      .title-content {
        font-size: rem-calc(22px);
        line-height: 28px;
        font-family: $base-font-family;
        font-weight: bold;
      }
      @media (max-width: 1025px) {
        font-size: rem-calc(18px);
        .title-content {
          font-size: rem-calc(18px);
          line-height: 24px;
        }
      }
    }
    .subpage-action {
      @media (max-width: 1025px) {
        display: flex;
        .button {
          margin: 0 auto 30px;
        }
      }
    }
  /*  .card-types {
      width: 150.7px;
      height: 27.9px;
      margin: 15px 0;
      @media (max-width: 1025px) {
        width: 96.3px;
        height: 17.8px;
      }
    }*/
    @media (max-width: 640px){
    .padding-right-20{
      padding-right: 0;
    }
    .margin-right-20{
      margin-right: 0;
    }
  }
    .card-header {
      margin-bottom: 12px;
      @media (max-width: 640px) and (min-width: 426px) {
        margin-left: 22px;
      }
      

      @media (#{$larger-than-phablet}) {
        margin-bottom: 13px;
      }
      @media (#{$larger-than-desktop}) {
        margin-bottom: 10px;
      }

      &.tablet-only {
        display: none;
        @media (#{$larger-than-mobile}) {
          display: block;
        }
        @media (#{$larger-than-desktop}) {
          display: none;
        }
      }

      &.tablet-hidden {
        display: block;
        @media (#{$larger-than-mobile}) {
          display: none;
        }
        @media (#{$larger-than-desktop}) {
          display: block;
        }
      }
    }

    .voucher-form-container {
      .voucher-form {
        .button-fieldset {
          @media (max-width: 750px) {
            &.mobile-shown {
              display: block;
              .button {
                margin: 0 auto;
                float: none;
              }
            }
          }
        }
        .note {
          p {
            font-size: rem-calc(17px);
            font-family: $base-font-family;
            line-height: 20px;
            margin-bottom: 0;
            &.remove {
             cursor: pointer;
             &.disabled {
               cursor: not-allowed;
               color: $third-color-dark;
             }
            }
          }
          .card-container {
            width: 495px;
            background-color: #f4f4f4;
            border: 1px solid $secondary-color;
            border-radius: 11px;
            height: 50px;
            padding: 14px 5px;
            text-align: center;
            margin-bottom: 0;
          }
          @media (max-width: 1025px) {
            .card-container {
              width: 91%;
              height: 65px;
              padding: 12px 5px;
              margin-bottom: 20px;
            }
            p {
              font-size: rem-calc(15px);
              &.remove {
                padding-top: 0;
                text-align: center;
              }
            }
          }
          @media (max-width: 834px) {
            .card-container {
              width: 100%;
              height: 50px;
            }
          }
          @media (max-width: 640px) {
            .card-container {
              height: 65px;
            }
          }
          @media (max-width: 358px) {
            .card-container {
              padding: 3px 5px;
            }
          }
        }
        .voucher-input {
          margin-bottom: 15px;
          margin-top: -2px;
          height: 45px;
          font-family: $base-font-family;
          font-weight: bold;
          font-size: 15px;

          @media (#{$larger-than-desktop}) {
            margin-bottom: 15px;
            margin-top: 0;
            height: 45px;
          }
          &::placeholder{
            color: #b2b2b2;
          }
        }
        .warning-section {
          .warning {
            font-size: rem-calc(17px);
            font-family: $base-font-family;
            line-height: 20px;
          }
          @media (#{$larger-than-desktop}) {
            max-width: 535px;
          }
          @media (max-width: 834px) {
            .warning {
              font-size: rem-calc(15px);
            }
          }
        }
        .small-subhead {
          font-size: rem-calc(17px);
          margin-bottom: 12px;

          @media (#{$larger-than-mobile}) {
            font-family: $base-font-family;
            font-weight: bold;
            margin-bottom: 15px;
          }
          @media (#{$larger-than-desktop}) {
            font-family: $base-font-family;
            font-size: rem-calc(16px);
            margin-bottom: 16px;
          }
        }

        width: 100%;

        &.right-side {
          @media (#{$larger-than-desktop}) {
            width: 35%;
          }
        }

        .bottom-border {
          border-bottom: 1px solid #707070;
          margin-bottom: 40px;

          @media (#{$larger-than-phablet}) {
            margin-bottom: 32.4px;
          }

          @media (#{$larger-than-desktop}) {
            padding-bottom: 5px;
            margin-bottom: 28px;
          }
        }
        .code-field {
          display: flex;
          .input-container {
            display: inline-flex;
            width: 80%;
          }
          .help-icon {
            background-color: $primary-color;
            color: white;
            padding: 0px 5px;
            border-radius: 100px;
            width: 22px;
            height: 23px;
            margin: 9px 17px 9px 10px;
          }
          .validate-btn{
            font-size: rem-calc(20px);
            line-height: 27px;
            width: 145px;
            padding: 4px;
            height: 43px;
          }
          .tablet-alert {
            display: none;
          }
          
          @media (max-width: 1025px) {
            display: block;
            .input-container {
              width: 100%;
            }
            .help-icon {
              font-size: rem-calc(20px);
              width: 26px;
              height: 26px;
              margin: 4px 0 4px 4px;
              padding: 2px 7px;
            }
            .action-container {
              display: flex;
              justify-content: center;
            }
            .validate-btn {
              font-size: rem-calc(15px);
              line-height: 20px;
            }
            .tablet-alert {
              display: block;
            }
          }
          @media (max-width: 834px) {
            .validate-btn {
              font-size: rem-calc(20px);
            }
          }
        }
        .input-fieldset {
          width: 70%;
          display: block;
          .validation-message {
            font-size: rem-calc(12px);
            margin-top: -11px;
            display: none;
            @media (min-width: $old-mobile-min) {
              display: block;
            }
          }
        }
        .desktop-alert {
          display: block;
        }
        @media (max-width: 1025px) {
          .desktop-alert {
            display: none;
          }
        }
      }
    }

    &.subpage-action {
      text-align: right;
      @media (max-width: 425px ) {
        text-align: center;
      }
    }

    .maxHeight{
      max-height: max-content;
    }

    .credit-card-section {
      display: block;
      @media (max-width: 850px) {
        height: auto;
      }
      @media (#{$larger-than-mobile}) {
        display: none;
      }
      &.shown {
        display: block;
      }
    }
  }

  .checkout-progress-container {
    .flow-indicator {
      margin: 20px 20px 15px 20px;
      @media (min-width: 834px) {
        margin: 50px 20px 0 20px;
      }
      @media (min-width:750px) and (max-width:$ipad-min-width-minus){
        margin: 50px 20px 15px 20px;
      }
      @media (max-width: 640px) {
        margin: 20px 20px 25px;
      }

      .bullets-container {

        width: calc(100% + 40px);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-left: -20px;
 
        .bullet {
          padding: 1px;

          .bullet-content {
            @include push--auto;
          }

          .bullet-text {
            font-size: rem-calc(9px);
            line-height: 10px;

            @media (#{$larger-than-desktop}) {
              font-size: rem-calc(15px);
            }
            @media (max-width: 834px) {
              font-size: rem-calc(11px);
              line-height: 12px;
            }

            width: 55px;
          }

          .bullet-button {
            border: 1px solid $primary-color;
            background-color: white;

            height: 20px;
            width: 20px;
            justify-self: flex-end;
            margin-top: 12px;
            margin-bottom: -16.5px;
            border-radius: 17px;
           position: relative;//..........
            @include push--auto();

          }

          &.first-bullet {
           
            .bullet-text {
              margin-left: -15px;
              text-align: center;
            }
            .bullet-button {
              float: left;
            }
          }

          &.last-bullet {
            margin-top:-10px;
            .bullet-text {
              margin-right: -15px;
            }
            .bullet-button {
              float: right;
            }
          }

          &.current {
            color:$primary-color;
            .button-state {
              border: 1px solid $secondary-color;
              background-color: $secondary-color;
              border-radius: 17px;
              height: 16px;
              width: 16px;
              margin: 1px;
            }
          }

          &.done {
           
            .bullet-button {
              background-color: $primary-color;
            }

            .button-state {
              margin: 5px 2px 4px 3px;
              font-family: 'hero-mobile' !important;
              font-size: 10px;
              color: white;
              transform: rotate(15deg);
            }

            .button-state:before {
              content: "\2713";
            }
          }
        }
      }
    }
  }
  //.............................................
  .order-details-section {
    font-size: rem-calc(15px);
    font-weight: bold;
    letter-spacing: normal;
    line-height: 17px;
   
    &.total{
      padding: 12px 15px 0;
      height: 40px;
      @media (#{$larger-than-desktop}) {
        padding: 8px 30px;
        height: 60px;
      }
      @media (max-width: 1025px) {
        height: 41.8px;
        padding: 1px 30px;
      }
      @media (max-width: 834px) {
        height: 43.7px;
        padding: 1px 30px 0;
      }
      @media (max-width: 426px) {
        height: 37.7px;
        padding: 11px 18px;
      }
    }
    hr {
      display: none;
      @media (#{$larger-than-phablet}) {
        margin: 10px auto;
        display: block;
      }

      &.visible {

        margin: 17px auto;
        display: block;
      }
    }

    .order-details {

      div {
        display: inline-block;
      }
      @media (#{$larger-than-mobile}) {
        padding-right: 15px;
        margin-top: 15px;
      }
      .plan-category-title {
        margin-bottom: 23px;
        line-height: 17px;
        width: 100%;
        .mdn {
          font-size: rem-calc(17px);
          font-family: $base-font-family;
          font-weight: bold;
          margin-bottom: 25px;
          @media(max-width: 640px) {
            font-size: rem-calc(15px); 
          }
        }
        .title {
          width: 60%;
          font-size: rem-calc(16px);
          font-weight: bold;
          float: left;
          text-align: left;
          min-width: max-content;
          @media(max-width:1200px) and (min-width: 1025px) {
            font-size:15px;
          }
          
          // @media (#{$larger-than-phablet}){
          //   font-size: rem-calc(14px);
          // }
          // @media (#{$larger-than-desktop}){
          //   font-size: rem-calc(20px);
          // }
          &.diff-color{
            color: $primary-color;
            @media (#{$larger-than-phablet}){
              color: #000;
            }
          }

          &.normal {
            font-weight: normal;
            font-size: rem-calc(19px);
          }
        }

        .Subtitle--margin{
          @media (max-width: 1066px) and (min-width: 1025px) {
            font-size: rem-calc(15px) !important;
          }
          @media (max-width: 968px) and (min-width: 865px) {
            margin-left: 14px;
          }
          @media (max-width: 865px) and (min-width: 785px) {
            margin-left: 12px;
          }
          @media (max-width: 784px) and (min-width: 710px) {
            margin-left: 10px;
          }
          @media (max-width: 711px) and (min-width: 640px) {
            margin-left: 0px;
          }
          @media (max-width: 640px) and (min-width: 629px) {
            margin-left: 0;
          }
          @media (max-width: 629px) and (min-width: 592px) {
            margin-left: 20px;
          }
          @media (max-width: 592px) and (min-width: 353px) {
            margin-left: 12px;
            //display: none;
          }
          @media (max-width: 353px) and (min-width: 320px) {
            margin-left: 10px;
            //display: none;
          }
        }

        .quantity {
          
          width: 35%;
          float: right;
          font-size: rem-calc(16px);
          text-align: right;
          min-width: max-content;
          @media(max-width:1200px) and (min-width: 1025px) {
            font-size:13px;
          
        }
          @media (#{$larger-than-phablet}){
            font-size: rem-calc(14px);
          }
          @media (#{$larger-than-desktop}){
            font-size: rem-calc(16px);
            &.Subtitle--margin{
              font-size: rem-calc(15px);
              font-weight: bold;
            }
          }
        }
        .price {
          width: 33%;
          float: right;
          font-size: rem-calc(20px);
          text-align: right;
          @media (max-width: 1025px) {
            font-size: rem-calc(14px);
          }
        }

        &.no-margin-bottom {
          margin-bottom: 0;
        }
      }

      .plan-details {
        width: 100%;
        .cross-off-line {
          width: 25.9px;
          height: 10.4px;
          border-bottom: solid 3px #f58520;
          -webkit-transform: skewY(-25deg);
          transform: skewY(-25deg);
          position: absolute;
        }
        .promo-data {
          color: #f58520;
        }
        .plan-title {
          font-weight: normal;
          margin-bottom: 5px;
          font-family: $base-font-family;
          @media (max-width: 1025px) {
            font-size: rem-calc(13px);
            line-height: 16px;
          }
          @media (max-width: 641px) {
            font-size: rem-calc(15px);
            line-height: 18px;
          }
        }

        .plan-sub-title {
          width: 100%;
          margin-bottom: 3px;
    
          .title {
           
            width: 85%;
            float: left;
            text-align: left;
            line-height: 17px;
            @media (#{$larger-than-mobile}){
              width: 75%;
            }
          }
          @media(max-width:1200px) and (min-width: 1025px) {
            font-size:13px;
          
        }
          .price {
            width: 15%;
            float: left;
            text-align: right;
            font-size: rem-calc(20px);
            font-weight: normal;
            @media (#{$larger-than-mobile}){
              width: 25%;
            }
            &.small {
              font-size: rem-calc(17px);
            }
            @media (max-width: 1025px) {
              font-size: rem-calc(13px);
              font-weight: bold;
              &.small {
                font-size: rem-calc(12px);
                font-weight: bold;
              }
            }
            @media (max-width: 834px) {
              font-size: rem-calc(20px);
              line-height: 18px;
              &.small {
                font-size: rem-calc(15px);
                font-weight: bold;
              }
            }
          }
        }
      }

      &.taxes {
        .plan-category-title {
          @media(#{$larger-than-mobile}) {
            margin-bottom: 13px;
          }
          margin-bottom: 10px;
        }
        hr {
          margin: 0 auto 17px auto;
        }
      }
      &.taxes.telecom{
        hr{
          display: block;
          margin: 0 auto 10px auto;
          @media(#{$larger-than-mobile}) {
            display: block;
          }
        }
      }
    }

    .actions-container {
      margin-bottom: -3px;
      margin-top: 10px;
      text-align: left;
      a {
      //  text-transform: uppercase;
        margin-right: 35px;
        font-size: rem-calc(14px);
        font-family: $base-font-family;
        @media (min-width: 640px) and (max-width: 680px){
          margin-right: 25px;
        }
        @media(#{$larger-than-desktop}) {
          font-size: rem-calc(15px);
        }
        @media(max-width: 1025px) {
          font-size: rem-calc(14px);
          font-weight: bold;
          line-height: 15px;
        }
        @media (max-width: 641px) {
          font-size: rem-calc(14px);
          line-height: 25px;
        }
      }
    }
}

.credit-card-form {
  .card-details {
    height: 40px;
  }
  .custom-checkbox {
    .checkbox-label {
      font-size: rem-calc(17px);
      line-height: 20px;
      font-family: $base-font-family;
      @media (max-width: 1025px) {
        font-size: rem-calc(15px);
      }
    }
  }
  input{
    height: 45px;
  }
  
  fieldset {
    &.five, &.seven, &.twelve {
      padding-left: 0;
      padding-right: 0;

      @media (#{$larger-than-desktop}) {
        padding-left: 0;
        padding-right: 30px;
      }
    }

    &.five {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 33%;
        padding-left: 15px;
        padding-right: 0;
      }
    }

    &.seven {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 66%;
      }
    }

    .select {
      width: 49%;
      float: left;
      padding-right: 0;
      padding-left: 0;
      margin-left: 10px;
      @media (#{$larger-than-desktop}) {
        width: 46%;
      }
      @media (max-width: $desktop-min-width-minus) {
        width: 48%;
      }
      @media (max-width: 375px) {
        width: 100%;
        margin-left: 0;
      }
      &.no-margin-left {
        margin-left: 0
      }

      select {
        height: 45px;
        font-size: rem-calc(16px);
        padding: 0 15px;
        width: 100%;
        border-color: $input-border;
        @media (max-width: 640px) {
          height: 40px;
          font-size: rem-calc(16px);
        }
      }
      .select-bg{
        @media (max-width: 834px) {
          display: block;
          width: 34px;
          right: 0;
          top: 1px;
        }
        &  .select-arrow {
          top: 16px;
          position: absolute;
          right: 12px;
          border-color: #383838;
          border-width: 0 2px 2px 0;
          margin-top: -2px;
          margin-right: 1px;
          width: 8px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          height: 8px;
          display: block;
        }
      }

    }
  }

  &.hidden {
    display: none;
  }

  fieldset {
    &.address-lookup {
      @media (#{$larger-than-mobile}) {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }

      fieldset {
        margin-bottom: 0;
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
        @media (max-width: 1028px){
          &.four{
            width: 100%;
          }
        }

      }
    }
  }
  .address-lookup {
    padding-right: 0;
    &.eight {
      @media (#{$larger-than-desktop}) {
        padding-right: 30px;
      }
    }
    input[type="search"]{
      padding: 10px 15px;
    }
    i.icon-search.input-search-icon {
      position: absolute;
      top: 40px;
      left: 5px;
      display: none;
    }

    .ngui-auto-complete {
      font-size: rem-calc(18px);
    }
  }
}

.credit-card-section-container {
  display: inline-block;
  width: 100%;
  .subtitle{
    @media (#{$larger-than-phablet}) {
      font-size: rem-calc(15px);
      line-height: 20px;
    }
    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(18px);
      line-height: 24px;
    }
    font-size: rem-calc(16px);
    line-height: 21px;
    font-family: $base-font-family;
    font-weight: 500;
  }
  .highlight-card {
    &.payment {
      background-color: transparent;
      padding: 0;
      .custom-checkbox {
        .checkbox-label {
          font-size: rem-calc(20px);
          line-height: 25px;
          font-family: $base-font-family;
          margin-left: 20px;
          &.margin-left {
            margin-left: 24px;
          }
        }
        .address-info-line {
          font-size: rem-calc(15px);
          small {
            font-size: rem-calc(15px);
          }
        }
      }
      @media(max-width: 1025px) {
        .custom-checkbox {
          .checkbox-label {
            font-size: rem-calc(15px);
            line-height: 19px;
            margin-bottom: 10px;
          }
        }
      }
      @media (max-width: 834px) {
        .custom-checkbox {
          .checkbox-label {
            margin-bottom: 0px;
          }
          .address-info-line {
            div {
              display: block;
            }
          }
        }
      }
    }
    &.add-payment-plus {
      margin-top: 30px;
      padding-left: 20px;
      padding-right: 18px;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: $primary-color;
      }
    }
  }
  
  h4, h4.text-color-primary {
    font-size: rem-calc(20px);
    color: $black;
    min-width: max-content;
    @media (max-width: 345px) {
      font-size: rem-calc(17px);
    }

    @media (#{$larger-than-mobile}) {
      /*color: $primary-color;*/
      font-size: rem-calc(20px);
      min-width: min-content;
    }

    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(28px);
    }
  }

  .billing-address-container {
    display: inline-block;
    width: 100%;
    .custom-checkbox{
      height: 32px;
    }
  }
  .hidden {
    display: none;
  }
}

.toggle-arrow-header {
  color: $primary-color;
  font-size: rem-calc(17px);
  border-radius: 11px;
  //background-color: rgba(134, 51, 153, 0.11);
  padding: 15px 30px 15px 0px;
  margin-bottom: 10px;
  @media (#{$larger-than-mobile}) {
    background-color: transparent;
    padding: 0;
    color: $black;
  }

  &.not-card {
    background-color: transparent;
    margin-bottom: 10px;
  }
  &.pay-voucher{
    border: 1px solid #707070;
    height: 36px;
    padding: 8px 22px;
    color: $primary-color;
    font-size: rem-calc(15px);
    font-family: $base-font-family;
    margin-bottom: 25px;
    cursor: pointer;
    .toggle-arrow-button{
    float: right;
    & i{
      color: $primary-color;
    }
   }
    @media (#{$larger-than-phablet}) {
      font-size: rem-calc(15px);
    }
    @media (#{$larger-than-desktop}) {
      font-size: rem-calc(22px);
      height: 56px;
      padding: 16px 22px;
    }
    @media (max-width: 366px) {
      padding: 8px 4px;
      .toggle-arrow-button {
        margin-left: 0;
      }
    }
   
  }

}
.device-section {
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  .phone-info {
    width: 100%;
    display: -webkit-inline-box;
    .phone-image { 
      width: 10%;
      @media (max-width : 639px) {
        width: 12%;
        margin-right: 10px;
      }
      img {
        height: 130px;
        max-height: 130px;
        margin-bottom: 15px;
        @media (max-width : 1024px) {
          height: 105px;
          max-height: 105px;
        }
        @media (max-width : 639px) {
          height: 90px;
          max-height: 90px;
        }
      }
    }
    .phone-details {
      width: 87%;
      .phone-title {
        font-size: rem-calc(20px);
        font-weight: bold;
      }
      .price {
        color: $primary-color;
        float: right;
      }
      .market {
        width: 100%;
        font-size: rem-calc(15px);
      }
      .specs {
        font-size: rem-calc(14px);
        .properties {
          display: -webkit-inline-box;
          width: 70%;
        }
        .skip {
          cursor: pointer;
          float: right;
        }
        .color {
          border: 1px solid $input-border;
          padding: 2px 6px;
          margin-right: 10px;
          .color-swatch {
            width: 14px;
            height: 14px;
            border-radius: 15px;
            display: inline-block;
          }
        }
        .capacity {
          border: 1px solid $input-border;
          padding: 2px 6px;
        }
      }
      @media (max-width : 1024px) {
        .phone-title {
          font-size: rem-calc(16px);
        }
        .market {
          font-size: rem-calc(13px);
        }
        .specs {
          font-size: rem-calc(12px);
          .color {
            .color-swatch {
              width: 11px;
              height: 11px;
            }
          }
        }
      }
      @media (max-width : 639px) {
        .phone-title {
          font-size: rem-calc(14px);
        }
        .market {
          font-size: rem-calc(11px);
        }
        .specs {
          font-size: rem-calc(10px);
          .color {
            .color-swatch {
              width: 9px;
              height: 9px;
            }
          }
        }
      }
    }
  }
}
.device-actions {
  margin-top: 20px;
  button {
    float: right;
    margin-left: 10px;
    @media (max-width : 425px) { 
      float: none;
      margin: 5px 50px;
      width: 67%;
    }
  }
}
.toggle-arrow-button {
  display: inline-block;
  position: relative;
  font-size: rem-calc(11px);
  border-radius: 15px;
  height: 15px;
  width: 15px;
  font-weight: bold;
  padding: 0px;
  margin-left: 10px;
  margin-bottom: 0px;
  float: right;
  background: transparent;
  margin-top: 3px;
  @media (#{$larger-than-mobile}) {
    font-size: 15px;
    float: none;
    /*background-color: $primary-color;*/
  }

  i {
    color: $black;
    @media (#{$larger-than-mobile}) {
      /*color: white;*/
    }
    font-weight: bold;
    position: absolute;
    border-radius: 15px;
    margin-top: -8px;
    margin-left: -10px;
    font-size: 15px;
  }

  &.primary {
    background-color: transparent;
    i {
      color: $primary-color;
    }
  }

}
.need-phone-popUp{
  &.model-container{
    @media (#{$larger-than-desktop}) {
      width: 67%;
    }
    .modal-heading{
      @media (#{$larger-than-desktop}) {
       font-size: rem-calc(38px);
       line-height: 50px;
       width: 75%;
       margin-top: 0;
      }
      font-size: rem-calc(31px);
      width: 100%;
      line-height: 41px;
      margin-top: 35px;
    }
  }
  .modal-content > * {
    padding: 0;
  }
  .modal-content{
    padding: 1em 1em 2em;
  }
  .modal-content .twelve{
    font-size: rem-calc(31px);
    width: 100%;
    font-family: $base-font-family;
    @media (max-width:350px){
      font-size: rem-calc(25px);
    }
  }
  .modal-close{
    background: transparent;
  }
  .button{
    width: 270px;
    margin: 7px 0px;
    padding: 8px 5px;
    font-size: rem-calc(14px);
  }
  .second {
    .button {
      background-color: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;
    }
  }
  .third{
    display: none!important;
  //  margin-top: 20px;
  }
}
.note{
  font-size:13px; 
  font-weight: bold
}

.tooltip {
  .tooltipInfo {
    visibility: hidden;
    position: absolute;
    right: -12px;
    top: 28px;
    border-radius: 8px;
    background: $third-color-dark;
    box-shadow: 0px 5px 14px 0px rgba(0, 61, 107, 0.12), 0px 7px 28px 0px rgba(94, 94, 94, 0.18), 0px 4px 8px 0px rgba(94, 94, 94, 0.12);
    padding: 8px 12px;
    width: 310px;
    text-align: left;
    color: $white;

    @media (max-width: $phablet-min-width-minus) {
        width: 240px;
    }
  }

  .tooltipInfo:after {
      content: "";
      position: absolute;
      right: 58px;
      top: -5px;
      border-top: none;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid $third-color-dark;
  }
  .tooltipInfo-short:after{
    right: 146px;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  border-radius: 6px;
  position: absolute;
  left:-80px;
  top:-100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f2ef;
   margin-left:-80px;
   margin-top:-13px;
   padding-top:15px;
   padding-left:13px;
   padding-right: 11px;
  text-align: left;
  color: black;
  font-size: rem-calc(15px);
  min-width: 80px;
  z-index:1;
  font-weight: normal;
  width: 312px;
  height: 130px;
  object-fit: contain;
  font-family: $base-font-family;
  line-height: 1.54;
  @media (max-width:833.9px) and (min-width:431px)
  {
    width: 280px;
   height: 110px;
   font-size:rem-calc(13px);
  }
  @media (max-width:430.9px) and (min-width:320px)
  {
    width: 240px;
   height: 110px;
   font-size:rem-calc(12px);
  }
  @media (min-width:834px)
  {
    top:-121px;
  }
}

.tooltip:hover .tooltipInfo {
  visibility: visible;
}

.input{
 margin-left:135px
}


.container1{
  // margin:auto;
  display:block;
  max-width: 24px;
  margin:auto;
 
  @media(max-width:834.9px) and (min-width: 564px) {
    //margin-top: -1px;
    max-width:12%;
 }
 @media(max-width:639px) and (min-width: 564px) {
  //margin-top: -1px;
  max-width:8%;
}
@media(max-width:823px) and (min-width: 640px) {
  //margin-top: -1px;
  max-width:6%;
}
 }
.quantity11{
     
        //margin-top: -16px;
        width: 25%;
        float: right;
        font-size: rem-calc(15px);
        text-align: right;
        min-width: max-content;
        @media(max-width:365px) and (min-width: 320px) {
          //margin-top: -18px;
          font-size: rem-calc(13px);
       }
        @media(max-width:834px) and (min-width: 564px) {
          margin-top: 4px;
          
       }
       @media(max-width:1024px) and (min-width: 1003px) {
       // margin-top: -18px;
     }
     @media(max-width:1138px) and (min-width: 1025px) {
          font-size: rem-calc(15px);
          //margin-left: 10px;
          //margin-top: -18px;
   }
        // @media (#{$larger-than-phablet}){
        //   font-size: rem-calc(14px);
        // }
        // @media (#{$larger-than-desktop}){
        //   font-size: rem-calc(20px);
        //   &.Subtitle--margin{
        //     font-size: rem-calc(15px);
        //     font-weight: bold;
        //   }
        // }
      }
      .card-width{
      width:80%;
      height: 60%;
      margin-left:20px; 
      @media (min-width: 1025px) {
        margin-left:99px;
    }
      margin-top:100px;
      display:block;
      font-size: rem-calc(16px);
      font-family: $base-font-family;
      font-weight: bold;
      @media (min-width:550px)and (max-width:1000px){
        width:90%;
        font-size: rem-calc(14px);
      }
      @media (min-width:485px)and (max-width:549px){
        width:90%;
        font-size: rem-calc(12px);
      }
      @media (min-width:320px)and (max-width:484px){
        width:85%;
        font-size: rem-calc(12px);
      }
      }
      .plan-details2{
        font-family: $base-font-family;
        font-weight:bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.32;
        margin-left:23px;
       font-size: rem-calc(20px);
       margin-top:-30px;
       @media (min-width:320px)and (max-width:484px){
        margin-left:-30px;
        font-size: rem-calc(18px);
        margin-top:-75px;
       }
      }
    .t1{
      padding-left:22px;
      margin-top:16px;
      @media (min-width:320px)and (max-width:484px){
        margin-top:7px;
        padding-left:0px;
       }
    }
    .t2{
      float: right;
      margin-top:-38px;
      @media (min-width:485px)and (max-width:549px){
        margin-top:-35px;
      }
      @media (min-width:320px)and (max-width:484px){
       margin-top:-3px;
       float:none;
       //@at-rootmargin-left:20px;
      }
    }
.t-center{
  text-align: center;
  //white-space:nowrap;
  @media (min-width:320px)and (max-width:484px){
    text-align:left;
    font-size:0px; 
   }
}
.p-text{
  text-align: center;
  font-family: $base-font-family;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  margin-top:-15px;
  
  @media (min-width:320px)and (max-width:484px){
    font-size: 16px;
    width:80%;
    line-height: 1.13;
    text-align: left;
   }
  //  @media (min-width:485px)and (max-width:539px){
  //   width:483px;
  //  }
}
.p-text1{
  font-family: $base-font-family;
  font-weight:bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.32;
  margin-left:97px;//23
 font-size: rem-calc(20px);
 margin-top:-47px;
 @media (min-width:320px)and (max-width:484px){
  //margin-left:-30px;
  font-size: rem-calc(18px);
  //margin-top:-75px;
 }
 @media (max-width:1024px)and (min-width:640px){
  //margin-left:-30px;
  margin-top:-32px;
  margin-left:40px;
  //margin-top:-75px;
 }
 @media (max-width:639px)and (min-width:485px){
  //margin-left:-30px;
  margin-top:-33px;
  margin-left:40px;
  //margin-top:-75px;
 }
 @media (max-width:484px){
  //margin-left:-30px;
  margin-top:-32px;
  margin-left:-7px;
  //margin-top:-75px;
 }
}
.p-text2{
  @media (max-width:639px)and (min-width:485px){
    //margin-left:-30px;
    margin-top:-13px;
    margin-left:40px;
    //margin-top:-75px;
   }
   @media (max-width:484px){
    //margin-left:-30px;
    margin-top:-20px;
    margin-left:-7px;
    //margin-top:-75px;
   }
}
.h2-size
{
  @media (max-width:450px){
   font-size:24px;
   }

}
.highlight-card1
{
  @media (min-width:320px)and (max-width:484px){
    margin-left:-30px;
   }
 
}
.mobile-shown{
  display: block;
  @media (#{$larger-than-mobile}) {
    display: none;
  }
}
.padding-bot{
  padding-bottom: 50px;
}


.credit-card-form2 {
  
  .card-details {
    height: 40px;
  }
  .custom-checkbox {
    .checkbox-label {
      font-size: rem-calc(17px);
      line-height: 20px;
      font-family: $base-font-family;
      @media (max-width: 1025px) {
        font-size: rem-calc(15px);
      }
    
    }
  }
  input{
    height: 45px;
    max-width: 360px;
    width: 100%;
    object-fit: contain;
    border: solid 1px #707070;
  }
  
  fieldset {
    &.five, &.seven, &.twelve {
      padding-left: 0;
      padding-right: 0;

      @media (#{$larger-than-desktop}) {
        padding-left: 0;
        padding-right: 30px;
      }
    }

    &.five {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 33%;
        padding-left: 15px;
        padding-right: 0;
      }
    }

    &.seven {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 50%;
        height:100px;
      } 
     
    }

    .select {
      width: 47%;
      float: left;
      padding-right: 0;
      padding-left: 0;
      margin-left: 13px;
      @media (#{$larger-than-desktop}) {
        width: 46%;
      }
      &.no-margin-left {
        margin-left: 0
      }
      @media (max-width: $desktop-min-width) and (min-width:345px){
        width:100%;
        margin-left: 0;
       
      }
      select {
        height: 30px;
        @media (max-width: 1024.9px) {
          height: 40px;
        }
        font-size: rem-calc(16px);
        padding: 0 15px;
        width: 100%;
        -webkit-appearance: menulist;
        @media (min-width:320px) and (max-width:833.9px){
          -webkit-appearance:unset;
        }
        @media (max-width: 640px) {
          height: 40px;
          font-size: rem-calc(16px);
        }
        @media (max-width:$desktop-min-width) and (min-width:345px){
          width:150px;
          height: 40px;
        }
      }
      .select-bg{
        // @media (max-width: 639.9px) {
        //   position: absolute;
        //   background: #dedede;
        //   display: block;
        //   width: 34px;
        //   height: 38px;
        //   right: 0;
        //   top: 1px;
        // }
        &  .select-arrow {
          top: 16px;
          position: absolute;
          right: 12px;
          border-color: #383838;
          border-width: 0 2px 2px 0;
          margin-top: -2px;
          margin-right: 1px;
          width: 8px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          height: 8px;
          display: none;
          @media (max-width: 834px) {
            display: block;
          }
        }
      }

    }
  }

  &.hidden {
    display: none;
  }

  fieldset {
    &.address-lookup {
      @media (#{$larger-than-mobile}) {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }

      fieldset {
        margin-bottom: 0;
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
        @media (max-width: 1028px){
          &.four{
            width: 100%;
          }
        }

      }
    }
  }
  .address-lookup {
    padding-right: 0;
    &.eight {
      @media (#{$larger-than-desktop}) {
        padding-right: 30px;
      }
    }
    input[type="search"]{
      padding: 10px 15px;
    }
    i.icon-search.input-search-icon {
      position: absolute;
      top: 40px;
      left: 5px;
      display: none;
    }

    .ngui-auto-complete {
      font-size: rem-calc(18px);
    }
  }
}
.credit-card-form3{
  .card-details {
    height: 40px;
  }
  .custom-checkbox {
    .checkbox-label {
      font-size: rem-calc(17px);
      line-height: 20px;
      font-family: $base-font-family;
      @media (max-width: 1025px) {
        font-size: rem-calc(15px);
      }
    }
  }
  .button {
    &.large {
      padding: 11px;
      width: 200px;
      font-size: rem-calc(18px);
    }
  }
  input{
    height: 45px;
    width:100%;
    max-width: 418px;
    @media (max-width: 1024.9px) {
      height: 40px;
    }
  }
  
  fieldset {
    &.five, &.seven, &.twelve {
      padding-left: 0;
      padding-right: 0;

      @media (#{$larger-than-desktop}) {
        padding-left: 0;
        padding-right: 30px;
      }
    }

    &.five {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 33%;
        padding-left: 15px;
        padding-right: 0;
      }
    }

    &.seven {
      width: 100%;
      @media (#{$larger-than-desktop}) {
        width: 66%;
      }
    }

    .select {
      width: 47%;
      float: left;
      padding-right: 0;
      padding-left: 0;
      margin-left: 13px;
      @media (#{$larger-than-desktop}) {
        width: 46%;
      }
      &.no-margin-left {
        margin-left: 0
      }

      select {
        height: 45px;
        font-size: rem-calc(16px);
        padding: 0 15px;
        width: 100%;
        @media (max-width: 640px) {
          height: 40px;
          font-size: rem-calc(16px);
        }
      }
      .select-bg{
        @media (max-width: 834px) {
          display: block;
          width: 34px;
          right: 0;
          top: 1px;
        }
        &  .select-arrow {
          top: 16px;
          position: absolute;
          right: 12px;
          border-color: #383838;
          border-width: 0 2px 2px 0;
          margin-top: -2px;
          margin-right: 1px;
          width: 8px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          height: 8px;
          display: none;
          @media (max-width: 834px) {
            display: block;
          }
        }
      }

    }
  }

  &.hidden {
    display: none;
  }

  fieldset {
    &.address-lookup {
      @media (#{$larger-than-mobile}) {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }
      label {
        @media (max-width: 1024.9px) and (min-width: 640px) {
          font-size: rem-calc(12px);
        }
      }
      fieldset {
        margin-bottom: 0;
        padding-left: 0;

        &:last-child {
          padding-right: 0;
        }
        @media (max-width: 1028px){
          &.four{
            width: 100%;
          }
        }

      }
    }
  }
  .address-lookup {
    padding-right: 0;
    &.eight {
      @media (#{$larger-than-desktop}) {
        padding-right: 30px;
      }
    }
    input[type="search"]{
      padding: 10px 15px;
    }
    i.icon-search.input-search-icon {
      position: absolute;
      top: 40px;
      left: 5px;
      display: none;
    }

    .ngui-auto-complete {
      font-size: rem-calc(18px);
    }
  }
}
