.max-content {
  min-width: max-content;
}
.bold {
  font-weight: bold;
}
.flexfullWidth {
  @media (max-width: 1024px) and (min-width: 640px) {
    flex-basis: 100%;
  }
}
.coverage-section {
  .container-flex {
    max-width: 1320px;
    padding: 0 15px 0 25px;
  }
  .header {
    font-size: rem-calc(38px);
    font-family: $base-font-family;
    line-height: 50px;
    max-width: 1060px;
  }
  .description {
    font-size: rem-calc(20px);
    font-family: $base-font-family;
    line-height: 26px;
    font-weight: normal;
    margin: 10px 0 25px;
    max-width: 1250px;
  }
  .actions {
    margin-bottom: 60px;
    .button {
      width: 227px;
      padding: 11px;
      font-size: rem-calc(20px);
      font-family: $base-font-family;
      margin-right: 15px;
    }
  }
  .displayInline {
    display: inline;
  }
  .map-header {
    font-size: rem-calc(17px);
    line-height: 20px;
  }

  .pFontSize {
    font-size: rem-calc(22px);
  }

  .pFontWeight {
    font-weight: normal;
  }
  .readMoreForSmallDevices {
    @media (min-width: $phablet-min-width) {
      display: none;
    }
    @media (max-width: $phablet-min-width) {
      color: $primary-color;
      font-size: rem-calc(17px);
      text-align: left;
      display: block;
    }
  }
  .blockDisplayForDevicesSmallerThanTablet {
    @media (max-width: $phablet-min-width) {
      display: block;
    }
  }
  .blockDisplayForDevicesLargerThanTablet {
    @media (min-width: $phablet-min-width) {
      display: block;
    }
  }
  .hiddenDisplayForDevicesSmallerThanTablet {
    @media (max-width: $phablet-min-width) {
      display: none;
    }
  }
  .map-description {
    font-size: rem-calc(17px);
    color: $primary-color;
    font-family: $base-font-family;
    margin-bottom: 25px;
  }
  .map-legend {
    margin-top: 20px;
    .color-options {
      border: 1px solid $black;
      padding: 10px;
      border-radius: 8px;
      li {
        padding: 0 15px;
      }
    }
  }
  .lte-section {
    padding: 0 15px 0 55px;
    max-width: 1360px;
  }
  .paragraph-section {
    padding-bottom: 20px;
    .lte-title {
      padding-top: 30px;
      font-size: rem-calc(27px);
      line-height: 26px;
      font-family: $base-font-family;
    }
    p {
      font-size: rem-calc(27px);
      font-weight: normal;
    }
    &.lte {
      .lte-description {
        font-size: rem-calc(20px);
        font-family: $base-font-family;
        line-height: 26px;
        max-width: 1196px;
      }
    }
  }
  .disclaimer-container {
    border-top: 1px solid $grey;
    .disclaimer {
      font-size: rem-calc(14px);
      font-family: $base-font-family;
      line-height: 24px;
      margin-bottom: 0;
      max-width: 1226px;
      &.primary-color {
        color: $primary-color;
        padding: 15px 0 70px;
      }
      &:first-child {
        font-weight: bold;
      }
    }
    .paragraph-section {
      padding: 0;
      margin: 20px 0 50px;
    }
  }
  @media (max-width: $desktop-min-width-minus) {
    .container-flex {
      padding: 0 40px;
    }
    .header {
      font-size: rem-calc(27px);
      line-height: 35px;
      max-width: 730px;
    }
    .description {
      font-size: rem-calc(15px);
      line-height: 23px;
      margin: 30px 0;
    }
    .actions {
      .button {
        width: 200px;
        padding: 11px;
        font-size: rem-calc(17px);
      }
    }
    .map-header {
      font-size: rem-calc(12px);
      line-height: 20px;
    }
    .map-legend {
      margin-top: 20px;
    }
    .lte-section {
      padding: 0 50px;
    }
    .paragraph-section {
      .lte-title {
        font-size: rem-calc(20px);
        line-height: 22px;
      }
      &.lte {
        .lte-description {
          font-size: rem-calc(15px);
          line-height: 17px;
        }
      }
    }
    .disclaimer-container {
      .disclaimer {
        font-size: rem-calc(13px);
        line-height: 20px;
        &.primary-color {
          font-size: rem-calc(12px);
          line-height: 20px;
          padding: 15px 0 20px;
        }
      }
      .paragraph-section {
        padding: 0;
        margin: 20px 30px 10px 0;
      }
    }
  }
  @media (max-width: $tablet-min-width-minus) {
    .container-flex {
      padding: 0 20px;
    }
    .header {
      font-size: rem-calc(26px);
      line-height: 35px;
      text-align: center;
      margin: auto;
    }
    .description {
      font-size: rem-calc(17px);
      line-height: 22px;
      text-align: center;
      &.left {
        text-align: left;
        margin-bottom: 15px;
      }
    }
    .actions {
      text-align: center;
      margin-bottom: 40px;
      .button {
        margin: 15px auto;
        display: block;
      }
    }
    .map-header {
      font-size: rem-calc(14px);
      line-height: 20px;
    }
    .map-description {
      font-size: rem-calc(13px);
    }
    .lte-title {
      line-height: 27px;
      font-size: rem-calc(20px);
    }
    .lte-section {
      padding: 0 20px 0 30px;
    }
    .paragraph-section {
      margin-right: 0;
      &.lte {
        .lte-description {
          font-size: rem-calc(17px);
          line-height: 22px;
        }
      }
    }
    .disclaimer-container {
      .disclaimer {
        font-size: rem-calc(14px);
        line-height: 20px;
        &.primary-color {
          font-size: rem-calc(14px);
          line-height: 20px;
        }
      }
      .paragraph-section {
        margin-bottom: 0px;
      }
    }
  }
}

.re-captcha--resizing {
  @media (max-width: 370px) and (min-width: 320px) {
    transform-origin: left;
    @media (max-width: 325px) and (min-width: 320px) {
      transform: scale(0.8);
    }
    @media (max-width: 330px) and (min-width: 325px) {
      transform: scale(0.82);
    }
    @media (max-width: 335px) and (min-width: 330px) {
      transform: scale(0.83);
    }
    @media (max-width: 340px) and (min-width: 335px) {
      transform: scale(0.84);
    }
    @media (max-width: 345px) and (min-width: 340px) {
      transform: scale(0.87);
    }
    @media (max-width: 350px) and (min-width: 345px) {
      transform: scale(0.88);
    }
    @media (max-width: 355px) and (min-width: 350px) {
      transform: scale(0.89);
    }
    @media (max-width: 360px) and (min-width: 355px) {
      transform: scale(0.92);
    }
    @media (max-width: 365px) and (min-width: 360px) {
      transform: scale(0.93);
    }
    @media (max-width: 370px) and (min-width: 365px) {
      transform: scale(0.94);
    }
    @media (max-width: 376px) and (min-width: 370px) {
      transform: scale(0.95);
    }
    @media (max-width: 380px) and (min-width: 376px) {
      transform: scale(0.97);
    }
  }
  @media (max-width: 383px) and (min-width: 380px) {
    transform: scale(0.95);
    transform-origin: center;
  }
  @media (max-width: 640px) and (min-width: 383px) {
    transform: scale(1);
    transform-origin: center;
  }
}

.button--width {
  min-width: max-content !important;
  @media (min-width: 640px) and (max-width: 1024px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.fullWidth {
  @media (min-width: 640px) and (max-width: 1024px) {
    width: 100% !important;
    margin-top: 50px;
  }
}

.fullWidthCenter {
  @media (min-width: 640px) and (max-width: 1024px) {
    width: 100% !important;
    text-align: center !important;
  }
}

.coverage-home-section {
  padding-bottom: 0px;
  @media (max-width: 750px) {
    padding-top: 40px;
  }
  @media (max-width: 414px) {
    padding-top: 15px;
  }
}
.network-section2 {
  width: 100%;
  color: white;
  padding: 0;
  background-repeat: no-repeat;
  background-size: contain;
  @media (min-width: $desktop-min-width) {
    background-position: center;
    background-image: url(/assets/img/USA-map.jpg);
    height: 640px;
  }
  @media (max-width: $desktop-min-width) and (min-width: 834px) {
    background-image: url(/assets/img/USA-map-834.jpg);
    height: 517px;
    max-width: 785px;
  }
  @media (max-width: 834px) {
    background-image: url(/assets/img/USA-map-640.jpg);
    height: 408px;
    max-width: 610px;
  }

  @media (max-width: 639.9px) {
    background-image: url(/assets/img/USA-map.jpg);
    height: 254px;
    max-width: 375px;
  }
  @media (min-width: 1497px) {
    height: 700px;
  }
  @media (min-width: 1640px) {
    height: 860px;
  }
  @media (min-width: 1840px) {
    height: 960px;
  }
  .container-flex {
    max-width: 1120px;
    //min-height: 407px;
  }
  .network-coverage-section {
    display: inline-flex;
    @media (max-width: 750px) {
      padding-top: 50px;
    }
  }
  .content {
    width: 70%;
    padding-top: 5%;
    @media (min-width: $desktop-min-width) {
      padding-top: 33%;
    }
    @media (min-width: 1497px) {
      padding-top: 40%;
    }
    @media (min-width: 1640px) {
      padding-top: 54%;
    }
    @media (min-width: 1840px) {
      padding-top: 50%;
    }
    @media (max-width: $desktop-min-width) {
      width: 100%;
      padding-top: 15px;
    }
  }
  .text-side {
    margin-left: 1.5%;
    margin-top: 6%;
    .title {
      color: white;
      font-family: $base-font-family;
      line-height: 40px;
      font-size: rem-calc(34px);
      margin-bottom: 20px;
    }
    .description {
      width: 90%;
      font-size: rem-calc(20px);
      font-family: $base-font-family;
      font-weight: normal;
      color: white;
      margin-bottom: 45px;
      line-height: 1.4;
    }
    @media (max-width: $desktop-min-width) {
      margin-top: 25px;
      margin-left: 0;
      .title {
        font-size: rem-calc(26px);
        line-height: 30px;
        //  max-width: 200px;
        margin: 0 auto 30px;
      }
      .description {
        font-size: rem-calc(16px);
        line-height: 20px;
        max-width: 420px;
        margin: 0 auto;
      }
      p {
        font-size: rem-calc(16px);
        width: 100%;
      }
    }
  }
  .girl {
    @media (min-width: 641px) {
      display: none;
    }
    /*   background-image: url(/assets/img/girl5@2x.png);
       height: 619px;
       background-repeat: no-repeat;
       background-position: bottom;*/
    img {
      height: 619px;
      //     margin-bottom: 18px;
    }
  }
  .action {
    width: 76%;
    margin-left: 1.5%;
    display: inline-flex;
    .zip {
      width: 53%;
      margin-right: 10%;
      .success-message {
        font-size: rem-calc(18px);
      }
      a {
        font-size: rem-calc(18px);
      }
    }
    .coverage {
      .button {
        padding: 10px 30px;
        margin-bottom: 15px;
        width: 225px;
      }
      a {
        font-size: rem-calc(18px);
        font-family: $base-font-family;
      }
    }
    @media (max-width: $desktop-min-width) {
      width: 100%;
      margin: 30px auto;
      display: block;
      .coverage {
        .button {
          padding: 10px;
          width: 200px;
          font-size: rem-calc(17px);
        }
      }
    }
  }
  @media (max-width: 639.9px) {
    margin-top: 15px;
    margin-bottom: 25px;
    .network-coverage-section {
      display: block;
      .content {
        width: 100%;
        .text-side {
          width: 100%;
          text-align: center;
          margin-bottom: 50px;
        }
        .action {
          width: 100%;
          display: block;
          text-align: center;
          .coverage {
            .button {
              padding: 10px 21px;
              margin-bottom: 15px;
              width: 257px;
              font-size: rem-calc(20px);
            }
            a {
              font-size: rem-calc(18px);
            }
          }
          .zip {
            width: 100%;
            text-align: center;
            margin-right: 0;
          }
        }
      }
      .girl {
        text-align: center;
        img {
          margin-bottom: 0;
        }
      }
    }
  }
}

.network-section {
  width: 100%;
  color: white;
  padding: 0;

  @media (min-width: $desktop-min-width) {
    background-position: center;
    background-image: url(/assets/img/network-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 640px;
  }
  @media (max-width: $desktop-min-width) {
    background-color: #7cbadb;
    background-size: 100%;
    height: 299px;
    text-align: center;
    /*  -webkit-transform: skewY(-5deg);
      -moz-transform: skewY(-5deg);
      -ms-transform: skewY(-5deg);
      -o-transform: skewY(-5deg);
      transform: skewY(-5deg);*/
  }
  @media (min-width: 1497px) {
    height: 700px;
  }
  @media (min-width: 1640px) {
    height: 860px;
  }
  @media (min-width: 1840px) {
    height: 960px;
  }
  .container-flex {
    max-width: 1120px;
    //min-height: 407px;
  }
  .network-coverage-section {
    display: inline-flex;
  }
  .content {
    width: 70%;
    padding-top: 5%;
    @media (min-width: $desktop-min-width) {
      padding-top: 33%;
    }
    @media (min-width: 1497px) {
      padding-top: 40%;
    }
    @media (min-width: 1640px) {
      padding-top: 54%;
    }
    @media (min-width: 1840px) {
      padding-top: 50%;
    }
    @media (max-width: $desktop-min-width) {
      width: 100%;
      padding-top: 15px;
    }
  }
  .text-side {
    margin-left: 1.5%;
    margin-top: 6%;
    .title {
      color: white;
      font-family: $base-font-family;
      line-height: 40px;
      font-size: rem-calc(34px);
      margin-bottom: 20px;
    }
    .description {
      width: 90%;
      font-size: rem-calc(20px);
      font-family: $base-font-family;
      font-weight: normal;
      color: white;
      margin-bottom: 45px;
      line-height: 1.4;
    }
    @media (max-width: $desktop-min-width) {
      margin-top: 25px;
      margin-left: 0;
      .title {
        font-size: rem-calc(26px);
        line-height: 30px;
        //  max-width: 200px;
        margin: 0 auto 30px;
      }
      .description {
        font-size: rem-calc(16px);
        line-height: 20px;
        max-width: 420px;
        margin: 0 auto;
      }
      p {
        font-size: rem-calc(16px);
        width: 100%;
      }
    }
  }
  .girl {
    @media (min-width: 641px) {
      display: none;
    }
    /*   background-image: url(/assets/img/girl5@2x.png);
       height: 619px;
       background-repeat: no-repeat;
       background-position: bottom;*/
    img {
      height: 619px;
      //     margin-bottom: 18px;
    }
  }
  .action {
    width: 76%;
    margin-left: 1.5%;
    display: inline-flex;
    .zip {
      width: 53%;
      margin-right: 10%;
      .success-message {
        font-size: rem-calc(18px);
      }
      a {
        font-size: rem-calc(18px);
      }
    }
    .coverage {
      .button {
        padding: 10px 30px;
        margin-bottom: 15px;
        width: 225px;
      }
      a {
        font-size: rem-calc(18px);
        font-family: $base-font-family;
      }
    }
    @media (max-width: $desktop-min-width) {
      width: 100%;
      margin: 30px auto;
      display: block;
      .coverage {
        .button {
          padding: 10px;
          width: 200px;
          font-size: rem-calc(17px);
        }
      }
    }
  }
  @media (max-width: 640px) {
    margin-top: 15px;
    margin-bottom: 25px;
    .network-coverage-section {
      display: block;
      .content {
        width: 100%;
        .text-side {
          width: 100%;
          text-align: center;
          margin-bottom: 50px;
        }
        .action {
          width: 100%;
          display: block;
          text-align: center;
          .coverage {
            .button {
              padding: 10px 21px;
              margin-bottom: 15px;
              width: 257px;
              font-size: rem-calc(20px);
            }
            a {
              font-size: rem-calc(18px);
            }
          }
          .zip {
            width: 100%;
            text-align: center;
            margin-right: 0;
          }
        }
      }
      .girl {
        text-align: center;
        img {
          margin-bottom: 0;
        }
      }
    }
  }
}

.card-container {
  display: inline-block;
  width: 100%;
  padding: 25px 22px 5px 22px;
  @media (min-width: 639px) {
    padding: 12px 22px 5px 22px;
  }
  @media (#{$larger-than-phablet}) {
    border: 1px solid #707070;
    border-radius: 11px;
    text-align: left;
    padding: 25px 22px 25px 22px;
  }

  &.alt-bg {
    background-color: #f4f4f4;
    border: 0px;
  }

  &.bg-primary {
    background-color: rgba(134, 51, 153, 0.11);
    border: 0px;
  }

  &.border-primary {
    padding: 15px 12px !important;
    border: 1px solid $primary-color;
    border-radius: 11px;
    font-size: rem-calc(17px);

    &.hidden {
      display: none;
    }
  }

  &.border-normal {
    padding: 16.5px 20px !important;
    border: 1px solid $secondary-color;
    border-radius: 11px;
    font-size: rem-calc(17px);
    background: #f4f4f4;
    @media (#{$larger-than-desktop}) {
      width: 69%;
    }
    &.full-width {
      width: 100%;
      font-size: 17px;
      padding: 13px 24px !important;
      @media (#{$larger-than-desktop}) {
        height: auto;
      }
    }
    &.hidden {
      display: none;
    }
  }
  &.estimation-taxes-card {
    .desktop {
      display: block;
      padding-bottom: 15px;
    }
    .responsive {
      display: none;
    }
    .label-section {
      display: flex;
      cursor: pointer;
      .title {
        margin-bottom: 15px;
        font-size: rem-calc(16px);
        font-family: $base-font-family;
        line-height: 28px;
        font-weight: bold;
        flex-basis: 97%;
        .code {
          color: $primary-color;
        }
      }
    }
    .input-section {
      display: flex;
      flex-direction: row;
      .estimate-input {
        height: 45px;
        max-width: 208px;
        padding: 5px 20px;
        display: inline-flex;
        margin-right: 10px;
      }
      .button {
        font-size: rem-calc(18px);
        width: 198px;
        height: 43px;
        padding: 5px;
      }
    }
    .note-section {
      .note {
        font-style: italic;
        font-size: rem-calc(12px);
        line-height: 14px;
        max-width: 235px;
        font-weight: 400;
      }
    }
    @media (min-width: 1025px) and (max-width: 1199px) {
      // min-height: 183px;
      height: 100%;
      .responsive {
        display: block;
        text-align: center;
        padding-bottom: 15px;
      }
      .desktop {
        display: none;
      }
      .input-section {
        flex-direction: column;
        align-items: center;
        .estimate-input {
          height: 45px;
          margin-right: 0;
        }
        .button {
          width: 155px;
          margin-bottom: 20px;
        }
      }
      .note-section {
        .note {
          text-align: center;
          margin: auto;
        }
      }
    }
    @media (max-width: $desktop-min-width-minus) {
      padding: 18px 23px !important;
      .responsive {
        display: block;
        text-align: center;
      }
      .desktop {
        display: none;
      }
      .label-section {
        .title {
          font-size: rem-calc(13px);
          margin-bottom: 0;
        }
      }
      .input-section {
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        .estimate-input {
          width: 100%;
          max-width: 550px;
          text-align: center;
          height: 45px;
          margin-right: 0;
        }
        .button {
          width: 145px;
          font-size: rem-calc(15px);
        }
      }
    }
    @media (max-width: $ipad-min-width-minus) {
      padding: 10px 23px !important;
      .label-section {
        .title {
          margin-bottom: 0;
        }
      }
      .desktop {
        display: block;
        padding-bottom: 15px;
      }
      .responsive {
        display: none;
      }
      .input-section {
        flex-direction: row;
        padding-top: 10px;
        .estimate-input {
          width: 80%;
        }
        .button {
          height: 35px;
          margin-bottom: 15px;
          margin-left: 15px;
          font-size: rem-calc(20px);
        }
      }
    }
    @media (max-width: 414px) {
      .desktop {
        display: none;
      }
      .responsive {
        display: block;
        text-align: center;
      }
      .input-section {
        flex-direction: column;
        .estimate-input {
          width: 100%;
        }
      }
    }
  }
  .card-header {
    margin-bottom: 20px;
  }

  &.mobile-card-only {
    border: 1px solid #707070;
    border-radius: 11px;
    padding: 18px 21px 15px 17px;

    @media (#{$larger-than-mobile}) {
      padding: 0;
      border: none;
    }
  }
}

.highlight-card {
  border-radius: 11px;
  background-color: #d8f1ff;
  padding: 14px 30px 14px 14px;
  margin-bottom: 10px;

  .radio-checkbox input[type="radio"] + .radio-checkbox-label:before {
    top: 2px;
    margin-right: 13px;
  }

  .custom-checkbox {
    width: 100%;
    @media (max-width: 325px) {
      width: 102%;
    }
    .responsive-flex {
      @media (max-width: $desktop-min-width-minus) {
        display: flex;
      }
    }
    .checkbox {
      label {
        top: 4px;
      }
    }

    .checkbox-label {
      float: left;
      margin-left: 5px;
      width: auto; // fallback
      font-size: rem-calc(20px);
      font-weight: bold;
      margin-top: 6px;
      height: 20px;
      @media (min-width: 1025px) {
        width: 46%;
      }
      @media (max-width: $desktop-min-width-minus) {
        height: unset;
      }
      @media (max-width: 680px) {
        font-size: rem-calc(15px);
        margin-top: 6px;
      }
      &.margin-left {
        margin-left: 10px;
      }
    }

    .address-info-line {
      float: left;
      margin-left: 29px;
      font-size: rem-calc(15px);
      margin-top: 3px;
      width: auto;
      width: calc(100% - 30px);
      text-align: left;
      position: relative;
      top: 3px;
      @media (#{$larger-than-desktop}) {
        width: calc(53% - 30px);
        text-align: right;
        float: right;
        font-size: rem-calc(17px);
        margin-left: 5px;
      }

      &.text-left {
        width: calc(100% - 30px);
        text-align: left;

        .edit-action {
          float: right;
          img {
            height: 24px;
            width: 24px;
          }
        }
      }

      div {
        display: inline-block;
      }
    }
  }

  &.hidden {
    display: none;
  }

  &.gray {
    background-color: #f4f4f4;
  }

  &.order-details-section {
    padding: 20px 30px;
  }
}

.calculations-section {
  .description {
    font-size: rem-calc(18px);
    width: 68%;
    padding-top: 20px;
  }
  .calculation {
    width: 90%;
    display: inline-flex;
    margin-top: 40px;
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
    input[type="number"]:hover::-webkit-inner-spin-button,
    input[type="number"]:hover::-webkit-outer-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
    .quantityBtn {
      cursor: pointer;
      display: inline-block;
      font-size: 1em;
      font-weight: 700;
      padding: 13px 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 0;
    }
    .gigsNum {
      display: inline-block;
      height: auto;
      width: 17%;
      padding: 13px 0px;
      text-align: center;
    }
    .quantityNum {
      display: inline-block;
      height: auto;
      padding: 13px 0px;
      width: 30%;
      text-align: center;
    }
    .gigs {
      width: 25%;
      padding: 10px 0;
      font-size: rem-calc(18px);
      p {
        margin-left: 10px;
      }
      .title {
        margin-left: 38px;
      }
    }
    .calculate-action-mobile {
      display: none;
    }
    .hint {
      width: 13%;
      color: $primary-color;
      border: 2px solid $primary-color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      font-size: rem-calc(16px);
      text-align: center;
      height: 125px;
      line-height: 0.3;
      margin-left: 100px;
      p {
        &:first-child {
          margin-top: 36px;
        }
      }
    }
    .action {
      text-align: center;
      width: 37%;
      button {
        float: right;
        margin-top: 50px;
        padding: 10px 45px;
      }
    }
  }
  .calculate-action {
    width: 90%;
    margin-top: 30px;
  }
  @media (max-width: 1024px) {
    margin-top: 100px;
    padding: 60px 0;
    .description {
      width: 100%;
      font-size: rem-calc(16px);
    }
    .calculation {
      margin-top: 20px;
      .gigs {
        width: 35%;
        .title {
          font-size: rem-calc(15px);
          margin-left: 20px;
        }
        p {
          font-size: rem-calc(15px);
          margin-left: 0px;
        }
        .gigsNum {
          font-size: rem-calc(12px);
          width: 30%;
          padding: 9px 0px;
        }
        .quantityBtn {
          padding: 7px 11px;
        }
        .quantityNum {
          width: 46%;
          font-size: rem-calc(12px);
          padding: 8px 0px;
        }
      }
      .hint {
        width: 16%;
        margin-left: 25px;
        height: 100px;
        font-size: rem-calc(12px);
        p {
          &:first-child {
            margin-top: 28px;
          }
        }
      }
      .action {
        width: 44%;
        button {
          margin-top: 42px;
          padding: 8px 32px;
        }
      }
    }
  }
  @media (min-width: 640px) and (max-width: 680px) {
    .calculation {
      .gigs {
        width: 40%;
      }
      .hint {
        width: 20%;
        margin-left: 14px;
        height: 94px;
      }
    }
  }
  @media (max-width: 640px) {
    .calculation {
      width: 100%;
      display: block;
      .gigs {
        width: 100%;
        text-align: center;
        .gigsNum {
          width: 7%;
          padding: 12px 0px;
        }
        .title {
          margin-left: 0px;
        }
        .quantityNum {
          width: 15%;
          padding: 12px;
        }
        .quantityBtn {
          padding: 10px 16px;
        }
      }
      .calculate-action-mobile {
        display: block;
        margin: 12px auto 35px;
        text-align: center;
      }
      .hint {
        width: 20%;
        margin: auto;
        font-size: rem-calc(14px);
        height: 105px;
      }
      .action {
        float: none;
        margin: 30px auto 20px;
        text-align: center;
        button {
          float: none;
          margin-top: 0px;
        }
      }
    }
    .calculate-action {
      display: none;
    }
  }
}

.featured-plans-section {
  .circles {
    margin: 0 auto;
    cursor: pointer;
  }
  .circle {
    //  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 33%;
    background-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid $primary-color;
    text-align: center;
    display: block;
    padding: 5px;
    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -2%;
    }
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
  }

  .buttons-container {
    margin-top: 150px;
    @media (max-width: $phablet-min-width) {
      margin-top: 130px;
      .button {
        width: 200px;
        font-size: rem-calc(20px);
      }
    }
  }
  .mobile-shown {
    display: none;
    @media (max-width: 640px) {
      display: block;
      font-size: rem-calc(16px);
    }
  }
}
.provider-note {
  max-width: 241px;
  width: 241px;
  font-family: "Sansation";
  font-size: rem-calc(13px);
  line-height: 1.15;
  color: #f58520;
  margin-bottom: 16px;
  margin-top: -6px;
}
.tab-nav {
  margin-bottom: 20px;
  font-size: rem-calc(18px);

  ul {
    display: inline-block;

    list-style: none;
    padding: 0;
    margin: 0;

    li {
      float: left;
      @media (#{$larger-than-mobile}) {
        padding: 5px 20px;
      }

      &:first-child {
        padding-left: 0;
      }

      span {
        display: block;
        width: 100%;
        text-align: center;
        cursor: default;
        background-color: #fff;
        color: $primary-color;
        border: 1px solid $primary-color;
        cursor: pointer;
        padding: 10px 20px;

        &.active {
          background-color: $primary-color;
          color: white;
          border: 1px solid $primary-color;
          cursor: pointer;
          padding: 10px 20px;
          border-radius: 5px;
        }
        &.disabled {
          background-color: $primary-color;
          color: white;
          border: 1px solid $primary-color;
          cursor: not-allowed;
          padding: 10px 20px;
          border-radius: 5px;
        }
        &.fet {
          @media (min-width: 426px) and (max-width: 465px) {
            width: 100%;
            text-align: center;
            display: block;
            cursor: default;
            background-color: #fff;
            color: $primary-color;
            border: 1px solid $primary-color;
            cursor: pointer;
            padding: 10px 20px;
            margin-top: -14px;
            margin-left: 22px;
            &.active {
              background-color: $primary-color;
              color: white;
              border: 1px solid $primary-color;
              cursor: pointer;
              padding: 10px 20px;
              border-radius: 5px;
            }
          }
        }
        &.fet2 {
          @media (min-width: 426px) and (max-width: 465px) {
            width: 137%;
            text-align: center;
            display: block;
            cursor: default;
            background-color: #fff;
            color: $primary-color;
            border: 1px solid $primary-color;
            cursor: pointer;
            padding: 10px 20px;
            margin-top: 12px;
            margin-left: 0px;
            &.active {
              background-color: $primary-color;
              color: white;
              border: 1px solid $primary-color;
              cursor: pointer;
              padding: 10px 20px;
              border-radius: 5px;
            }
          }
          @media (min-width: 465px) and (max-width: 499px) {
            width: 122%;
            text-align: center;
            display: block;
            cursor: default;
            background-color: #fff;
            color: $primary-color;
            border: 1px solid $primary-color;
            cursor: pointer;
            padding: 10px 20px;
            margin-left: -3px;
            &.active {
              background-color: $primary-color;
              color: white;
              border: 1px solid $primary-color;
              cursor: pointer;
              padding: 10px 20px;
              border-radius: 5px;
            }
          }
          @media (min-width: 499px) {
            width: 122%;
            text-align: center;
            display: block;
            cursor: default;
            background-color: #fff;
            color: $primary-color;
            border: 1px solid $primary-color;
            cursor: pointer;
            padding: 10px 20px;
            margin-left: -3px;
            &.active {
              background-color: $primary-color;
              color: white;
              border: 1px solid $primary-color;
              cursor: pointer;
              padding: 10px 20px;
              border-radius: 5px;
            }
            &.disabled {
              background-color: $primary-color;
              color: white;
              border: 1px solid $primary-color;
              cursor: not-allowed;
              padding: 10px 20px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
.device-setup {
  font-size: rem-calc(18px);
  max-width: 1310px;
  @media (max-width: 750px) {
    padding-top: 100px;
  }
  .header {
    font-size: rem-calc(38px);
    line-height: 1.32;
    margin-bottom: 15px;
    @media (max-width: $phablet-min-width) {
      font-size: rem-calc(22px);
      line-height: 28px;
    }
    @media (max-width: $mobile-min) {
      max-width: 265px;
    }
  }
  .data-setup-header {
    margin-bottom: 30px;
  }

  .subhead {
    font-size: rem-calc(22px);
    margin-bottom: 20px;
    display: inline-block;
    font-family: $base-font-family;
    font-weight: normal;
    &.compatability {
      display: block;
      .subtitle {
        font-size: rem-calc(18px);
        line-height: 27px;
        font-weight: bold;
        font-family: $base-font-family;
      }
      .help-text {
        color: $primary-color;
        font-size: rem-calc(15px);
        line-height: 27px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .compatibility-form {
        .compatability-card {
          border: 1px solid $input-border;
          border-radius: 11px;
          text-align: left;
          padding: 40px 36px 30px;
          max-width: 794.9px;
          .label {
            font-size: rem-calc(15px);
            font-family: $base-font-family;
            line-height: 17px;
          }
          .help-label {
            display: none;
          }
        }
        .actions-container {
          margin-top: 23px;
          margin-bottom: 50px;
          .form-actions {
            .button {
              width: 192px;
              font-size: rem-calc(20px);
              padding: 10px 30px;
              &.secondary {
                margin-left: 20px;
              }
            }
          }
        }
      }
      @media (max-width: $phablet-min-width) {
        .subtitle {
          font-size: rem-calc(18px);
          line-height: 30px;
        }
        .help-text {
          display: none;
        }
        .compatibility-form {
          .compatability-card {
            border: none;
            padding: 0;
            .label {
              font-size: rem-calc(19px);
              line-height: 18px;
              margin-top: 30px;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .help-label {
              display: block;
              color: $primary-color;
              font-size: rem-calc(16px);
              line-height: 18px;
              font-weight: bold;
              margin-bottom: 30px;
            }
            input[type="tel"] {
              height: 45px;
            }
          }
          .actions-container {
            margin-top: 0px;
            margin-bottom: 30px;
            .form-actions {
              display: flex;
              flex-direction: column;
              align-items: center;
              .button {
                &.primary {
                  margin-bottom: 10px;
                }
                &.secondary {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
      @media (max-width: $mobile-min) {
        .compatibility-form {
          .compatability-card {
            .help-label {
              max-width: 265px;
            }
          }
        }
      }
    }
  }
  .form-actions {
    .button {
      padding: 13px 40px;
      width: 200px;
    }
    @media (max-width: 426px) {
      text-align: center;
      .button {
        margin-bottom: 10px;
      }
    }
  }
  .ios-gsm-container {
    .title {
      font-size: rem-calc(25px);
      line-height: 27px;
    }
    .note {
      font-size: rem-calc(18px);
      line-height: 27px;
      font-family: $base-font-family;
      margin-bottom: 20px;
    }
    .icon-image {
      width: 31px;
      height: 31px;
      padding-right: 10px;
    }
    .link {
      font-size: rem-calc(18px);
      line-height: 27px;
      font-family: $base-font-family;
    }
    .actions-container {
      margin-top: 40px;
      text-align: left;
      margin-bottom: 80px;
      .download-link {
        font-size: rem-calc(20px);
        font-family: $base-font-family;
        font-weight: 500;
        border-bottom: 1px solid $primary-color;
      }
      .button {
        display: block;
        margin-top: 30px;
      }
    }
    @media (max-width: $phablet-min-width) {
      .title {
        font-size: rem-calc(22px);
        line-height: 28px;
      }
      .note {
        line-height: 30px;
        margin-bottom: 25px;
      }
      .icon-image {
        width: 28.5px;
        height: 28.5px;
        padding-right: 10px;
      }
      .link {
        line-height: 30px;
      }
      .actions-container {
        margin-top: 30px;
        margin-bottom: 50px;
        .button {
          margin-top: 40px;
        }
      }
    }
  }
  .android-gsm-container {
    .title {
      font-size: rem-calc(25px);
      line-height: 27px;
      margin-bottom: 0;
    }
    .subtitle {
      font-family: $base-font-family;
      font-size: rem-calc(22px);
      line-height: 27px;
    }
    .section-separator {
      font-size: rem-calc(18px);
      font-family: $base-font-family;
      line-height: 30px;
      margin: 30px 0 45px;
    }
    .list-item {
      display: inline-flex;
      width: 100%;
      margin-bottom: 70px;
      .number {
        background-color: $primary-color;
        border-radius: 50%;
        color: white;
        text-align: center;
        width: 37px;
        height: 37px;
        padding: 5px;
        font-size: rem-calc(23px);
        font-family: $base-font-family;
        font-weight: bold;
        &.even {
          background-color: $secondary-color;
        }
        .number-item {
          padding: 5px;
        }
      }
      .item-content {
        margin-left: 35px;
        .content {
          margin-bottom: 0;
          font-weight: bold;
          font-size: rem-calc(17px);
          line-height: 20px;
          font-family: $base-font-family;
          &.sub {
            margin-top: 20px;
          }
        }
        .sub-list {
          margin: 0;
          padding-left: 16px;
        }
      }
    }
    .main-list {
      margin: 0;
      padding: 0px;
      counter-reset: item;
      list-style: none;
      li {
        font-weight: bold;
        font-size: rem-calc(17px);
        font-family: $base-font-family;
        &.main-item {
          margin-bottom: 60px;
          counter-increment: item;
          &:nth-child(odd) {
            &:before {
              background-color: $primary-color;
            }
          }
          &:nth-child(even) {
            &:before {
              background-color: $secondary-color;
            }
          }
          &:before {
            content: counter(item);
            border-radius: 50%;
            color: white;
            display: inline-block;
            text-align: center;
            width: 37px;
            height: 37px;
            padding: 5px;
            font-size: rem-calc(23px);
            font-family: $base-font-family;
            font-weight: bold;
          }
        }
        .item-content {
          margin-left: 71px;
          margin-bottom: 6px;
          &:first-child {
            margin-left: 30px;
            display: inline;
          }
          &.sub {
            margin-top: 25px;
          }
        }
        .sub-list {
          list-style-type: disc;
          margin-left: 45px;
          .content {
            margin-bottom: 0;
          }
        }
      }
    }
    .actions-container {
      margin-bottom: 60px;
    }
    .manage {
      margin-bottom: 50px;
      .manage-account {
        font-size: rem-calc(18px);
        font-family: $base-font-family;
        line-height: 30px;
      }
    }
    @media (max-width: $phablet-min-width) {
      .title {
        font-size: rem-calc(22px);
        line-height: 28px;
        margin-bottom: 10px;
      }
      .subtitle {
        font-size: rem-calc(18px);
        line-height: 30px;
      }
      .section-separator {
        margin: 20px 0 35px;
      }
      .list-item {
        margin-bottom: 40px;
        .number {
          padding: 6px;
          .number-item {
            padding: 7px;
          }
        }
        .item-content {
          margin-left: 10px;
        }
      }
      .actions-container {
        margin-bottom: 30px;
      }
      .manage {
        margin-bottom: 30px;
        .manage-account {
          font-size: rem-calc(17px);
          line-height: 20px;
        }
      }
    }
  }
  .android-cdma {
    padding: 0 25px;
    .title {
      font-size: rem-calc(25px);
      line-height: 24px;
      margin-bottom: 50px;
    }
    .instructions-section {
      margin-bottom: 40px;
      .header {
        font-size: rem-calc(20px);
        line-height: 27px;
        margin-bottom: 20px;
      }
      .sub-list {
        .sub {
          font-size: rem-calc(18px);
          line-height: 25px;
        }
      }
      .instruction {
        font-size: rem-calc(18px);
        line-height: 25px;
        max-width: 803px;
      }
      .note {
        font-size: rem-calc(15px);
        line-height: 20px;
        max-width: 764px;
        font-weight: normal;
      }
    }
    .actions {
      margin-bottom: 50px;
      .button {
        width: 200px;
        padding: 11px;
        font-size: rem-calc(18px);
      }
    }
    @media (max-width: $desktop-min-width-minus) {
      padding: 0 10px;
      .title {
        font-size: rem-calc(22px);
        line-height: 28px;
        margin-bottom: 35px;
      }
      .instructions-section {
        margin-bottom: 25px;
        .header {
          font-size: rem-calc(18px);
          line-height: 24px;
        }
        .instruction {
          font-size: rem-calc(15px);
          line-height: 20px;
        }
        .sub-list {
          padding-left: 24px;
          .sub {
            font-size: rem-calc(15px);
            line-height: 20px;
          }
        }
        .note {
          font-size: rem-calc(13px);
          line-height: 20px;
        }
      }
      .actions {
        text-align: center;
        margin-bottom: 30px;
        .button {
          margin-top: 25px;
        }
      }
    }
  }
  .ios-cdma {
    .header {
      font-size: rem-calc(25px);
      line-height: 33px;
      max-width: 953px;
      margin-bottom: 40px;
    }
    .list-title {
      font-size: rem-calc(20px);
      line-height: 27px;
    }
    .subtitle {
      font-size: rem-calc(18px);
      line-height: 30px;
    }
    .details-content {
      .list-steps {
        padding-left: 0px;
        max-width: 812px;
        margin-bottom: 30px;
        margin-top: 7px;
        list-style: none;
        li {
          &:before {
            content: url("/assets/img/tick.svg");
            padding-right: 7px;
          }
          font-size: rem-calc(18px);
          line-height: 27px;
          padding: 10px 0;
          // img {
          //   margin-right: 8px;
          // }
          &.sub {
            .sub-content {
              padding-left: 17px;
              margin-bottom: 0;
            }
            @media (max-width: 750px) {
              .sub-content {
                display: inline;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
    .note {
      margin-bottom: 30px;
      font-size: rem-calc(13px);
      font-weight: normal;
      line-height: 22px;
      max-width: 424px;
      padding-left: 20px;
    }
    .action {
      margin-bottom: 50px;
      .button {
        width: 200px;
        padding: 11px;
        font-size: rem-calc(18px);
      }
    }
    @media (max-width: 750px) {
      .header {
        font-size: 22px;
        line-height: 33px;
      }
      .list-title {
        font-size: 18px;
        line-height: 27px;
      }
      .details-content {
        .list-steps {
          li {
            font-size: rem-calc(15px);
            line-height: 22px;
            padding: 5px 0;
          }
        }
      }
      .note {
        font-size: rem-calc(13px);
      }
      .action {
        text-align: center;
      }
    }
  }
  .tab-nav {
    font-weight: bold;

    .tabbed-content {
      margin-bottom: 75px;
    }

    .color-black {
      color: black;
    }

    .section-separator {
      margin: 30px 0;
    }

    p {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0 18px;

      li {
        /*font-weight: bold;*/

        span {
          margin-left: -7px;
          line-height: 1.5;
        }
      }

      &.hyphen {
        list-style: none;
        padding: 0;
        li {
          &:before {
            content: "- ";
          }
          span {
            margin-left: 2px;
            &.margin-left-fix {
              margin-left: 11px;
            }
          }
        }
      }
    }

    ol {
      margin: 0;
      padding: 0 18px;

      li {
        span {
          margin-left: 0;
          line-height: 1.5;
        }
      }
    }

    &.android-cdma {
      color: #000000;
      font-weight: bold;
      p,
      ul {
        margin-bottom: 30px;
        &:first-child {
          margin-top: 30px;
        }
      }
    }

    &.android-gsm {
      color: #000000;
      margin-top: 30px;
      font-size: rem-calc(18px);
      font-weight: normal;
      line-height: 1.67;

      .content-description {
        margin-bottom: 20px;
      }

      ol {
        font-size: rem-calc(17px);
        line-height: 1.18;
        font-weight: bold;

        margin: 0;
        padding: 0 18px;
        counter-reset: item;
        list-style: none;
        &.main-list {
          li {
            &.main-item {
              counter-increment: item;
              &:nth-child(odd) {
                &:before {
                  background-color: $primary-color;
                }
              }
              &:nth-child(even) {
                &:before {
                  background-color: $secondary-color;
                }
              }
              &:before {
                content: counter(item);
                border-radius: 50%;
                color: white;
                display: inline-block;
                text-align: center;
                width: 25px;
              }
            }
          }
        }
        li {
          margin-bottom: 40px;

          span {
            margin-left: 20px;
            line-height: 1.18;
            &.margin-left-fix {
              margin-left: 43px;
            }
          }
        }

        ul {
          list-style: disc;
          margin-left: 50px;
          &.sub {
            li {
              width: 100%;
              padding: 0px 20px;
            }
          }
          li {
            margin-bottom: 0;
            border: none;
            span {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
.hearing-aid-container {
  padding-top: 140px;
  h2,
  h3 {
    margin-bottom: 30px;
    line-height: 37px;
    @media (max-width: 750px) {
      margin-top: 40px;
    }
  }
  p {
    font-size: rem-calc(17px);
    line-height: 22px;
  }
  @media (max-width: $tablet-min-width-minus) {
    padding-top: 115px;
    padding-bottom: 75px;
  }
}

.button-position {
  @media (min-width: 639px) and (max-width: 1025px) {
    margin-left: 28px;
    margin-top: 15px;
  }
  @media (min-width: 319px) and (max-width: 639px) {
    float: right;
  }
}

.float-right {
  @media (min-width: 640px) {
    float: right;
  }
}

.float-none-onWidth {
  @media (max-width: 794px) {
    float: none;
  }
}

.padding-left {
  @media (min-width: 320px) and (max-width: 330px) {
    padding-left: 30px;
  }
}

.text-container {
  @media (max-width: 640px) {
    padding-top: 100px;
  }
  padding-top: 200px;
  @media (#{$larger-than-desktop}) {
    padding-left: 33px;
    .container-flex {
      padding: 0 20px 0 75px;
    }
  }
  .container-flex {
    max-width: 1383px;
    @media (#{$larger-than-desktop}) {
      padding: 0 20px 0 75px;
    }
    .title {
      font-family: $base-font-family;
      .main {
        @media (min-width: $desktop-min-width) {
          font-size: rem-calc(40px);
          line-height: rem-calc(47px);
        }
        @media (max-width: $phablet-min-width) {
          font-size: rem-calc(30px);
          line-height: rem-calc(34px);
          margin-bottom: 5px;
        }
        @media (max-width: 1024px) and (min-width: 640px) {
          font-size: rem-calc(25px);
          line-height: rem-calc(27px);
          margin-bottom: 5px;
        }
      }
      .sub {
        @media (min-width: $desktop-min-width) {
          font-size: rem-calc(40px);
          line-height: rem-calc(47px);
        }
        @media (max-width: $phablet-min-width) {
          font-size: rem-calc(30px);
          line-height: rem-calc(34px);
          margin-bottom: 5px;
        }
        @media (max-width: 1024px) and (min-width: 640px) {
          font-size: rem-calc(25px);
          line-height: rem-calc(27px);
          margin-bottom: 5px;
        }
        @media (max-width: $phablet-min-width) {
          font-size: rem-calc(26px);
          line-height: rem-calc(34px);
        }
      }

      .note {
        @media (min-width: $desktop-min-width) {
          font-size: rem-calc(25px);
          line-height: rem-calc(33px);
        }
        @media (max-width: $phablet-min-width) {
          font-size: rem-calc(20px);
          line-height: rem-calc(27px);
        }
        @media (max-width: 1024px) and (min-width: 640px) {
          font-size: rem-calc(18px);
          line-height: rem-calc(24px);
        }
      }
    }
    .description {
      padding-top: 40px;
      margin-bottom: 7%;
      @media (max-width: 1024px) and (min-width: 640px) {
        padding-top: 0px;
      }
      .span-for-res {
        @media (max-width: 640px) {
          display: inline;
        }
        display: none;
      }
      .block-for-res {
        @media (max-width: 640px) {
          display: block;
        }
        display: none;
      }
      .block-for-large {
        @media (min-width: 640px) {
          display: block;
        }
        display: none;
      }
      .hideonmob {
        @media (max-width: 640px) {
          display: none;
        }
        display: block;
      }
      .hideonlarge {
        @media (min-width: 640px) {
          display: none;
        }
        display: block;
      }
      p {
        font-family: $base-font-family;
        font-size: rem-calc(20px);
        line-height: rem-calc(30px);
        @media (max-width: 1024px) and (min-width: 640px) {
          font-size: rem-calc(15px);
          line-height: rem-calc(23px);
        }
      }
      h1 {
        @media (max-width: $desktop-min-width) {
          font-size: rem-calc(30px);
          line-height: rem-calc(39px);
        }
        @media (min-width: $desktop-min-width) {
          font-size: rem-calc(40px);
          line-height: rem-calc(53px);
        }
        @media (max-width: 1024px) and (min-width: 640px) {
          font-size: rem-calc(25px);
          line-height: rem-calc(33px);
        }
      }
    }
  }
  @media (max-width: 640px) {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 100px;
  }
}

.get-started {
  width: 100%;
  background-color: $secondary-color;
  color: white;
  .description {
    color: white;
    font-size: rem-calc(34px);
    line-height: rem-calc(40px);
    font-family: $base-font-family;
    @media (max-width: 640px) {
      text-align: center;
      margin-bottom: 35px;
      display: inline;
    }
  }
  .button {
    padding: 13px 40px;
    float: right;
    width: 255px;
    font-family: "bryant", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    @media (max-width: 1024px) and (min-width: 640px) {
      font-size: rem-calc(17px);
      line-height: rem-calc(21px);
      width: 200px;
    }
    @media (max-width: 690px) {
      font-size: rem-calc(20px);
      line-height: rem-calc(27px);
      width: 226px;
    }
  }
}
.terms-container {
  @media (#{$larger-than-desktop}) {
    padding-top: 75px;
  }
  .btn-arrow {
    background-color: #863399;
    height: 30px;
    width: 30px;
    right: 7%;
    z-index: 1;
    cursor: pointer;
    display: inline-block;
    margin-top: 22px;
    background-size: 28%;
    background-position: 44% 47%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  }
  .prev-btn.btn-arrow {
    background-color: #f58520;
    transform: rotate(180deg);
  }
  .col-12 {
    order: 2;
  }
  @media (min-width: $desktop-min-width) {
    .display-flex {
      display: flex;
      width: 100%;
      .terms-list-container {
        position: sticky;
        height: fit-content;
        margin-bottom: 160px;
      }
    }
  }
  .terms-list-container {
    order: 1;
    top: 230px;
    @media (#{$larger-than-desktop}) {
      position: fixed;
    }
    .terms-list {
      list-style: none;
      font-size: rem-calc(19px);
      padding: 0;
      @media (#{$larger-than-desktop}) {
        width: 276px;
      }
      &:first-child {
        @media (#{$larger-than-phablet}) {
          border-top: 1px solid $grey;
        }
      }
      li {
        border-bottom: 1px solid $grey;
        padding: 10px 0px;
        :hover {
          color: $grey;
        }
        .active {
          color: $black;
        }
      }
    }
  }
  .terms-data {
    .terms-info {
      margin-bottom: 160px;
      &.margin-left-mobile {
        @media (max-width: 339.9px) {
          margin-left: -20px;
        }
      }
    }
    order: 3;
    .fontSize20 {
      font-size: rem-calc(20px);
    }
    .fontWeightNormal {
      font-weight: normal;
    }
    @media (min-width: $desktop-min-width) {
      margin-left: 3%;
      margin-top: 30px;
    }
    ul.upper-latin {
      list-style-type: upper-latin;
    }
    ul.latin {
      list-style-type: lower-latin;
    }
    ul.numbers {
      list-style-type: decimal;
    }
    p,
    li {
      font-size: rem-calc(16px);
      line-height: 26px;
      color: #9999a6;
      b {
        color: black;
      }
      .word-break {
        word-break: break-all;
      }
    }
    a:hover {
      color: #050c9f;
    }
    table {
      margin-bottom: 45px;
      th {
        height: 40px;
        text-align: center;
      }
      td {
        text-align: center;
        color: #9999a6;
        height: 40px;
        border-bottom: 1px solid $grey;
      }
    }
  }
}
.show-in-big-screens {
  @media (min-width: 1024px) {
    display: block;
  }
  display: none;
}

.show-in-small-screens {
  @media (max-width: 1024px) {
    display: block;
  }
  display: none;
}

.stores-container {
  .stores {
    @media (#{$larger-than-phablet}) {
      margin-bottom: 3em;
    }
  }
  @media (max-width: 750px) {
    padding-top: 100px;
  }
  .pagination ul.pagination {
    width: 55%;
    @media (#{$larger-than-desktop}) {
      width: auto;
    }
    @media (max-width: 640px) {
      margin: 0 auto;
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .container-flex {
    max-width: 1255px;
    .title {
      h1 {
        @media (#{$larger-than-phablet}) {
          font-size: rem-calc(38px);
          line-height: 50px;
        }
        font-size: rem-calc(26px);
        line-height: 30px;
        font-family: $base-font-family;
      }
    }
    .subtitle {
      font-size: rem-calc(17px);
      line-height: 20px;
      @media (#{$larger-than-phablet}) {
        font-weight: bold;
      }
    }
    .form-holder {
      margin-top: 40px;
      margin-bottom: 50px;
      min-height: 100px;
      .search-store-form {
        h2 {
          font-family: $base-font-family;
          font-size: rem-calc(17px);
          font-weight: bold;
        }
        label {
          font-family: $base-font-family;
          font-size: rem-calc(15px);
          @media (#{$larger-than-mobile}) {
            font-weight: bold;
            line-height: 20px;
            font-size: rem-calc(17px);
          }
        }
        input {
          font-size: rem-calc(15px);
          font-family: $base-font-family;
          height: 45px;
          @media (#{$larger-than-mobile}) {
            height: 30px;
            width: 100%;
          }
        }
        .select-arrow {
          position: absolute;
          right: 12px;
          border-color: #383838;
          border-width: 0 2px 2px 0;
          padding: 2px;
          margin-top: 21px;
          margin-right: 10px;
          width: 10px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          height: 10px;
          display: block;
          @media (#{$larger-than-mobile}) {
            height: 8px;
            width: 8px;
            margin-top: 20px;
          }
        }
        select {
          width: 100%;
          height: 40px;
          font-size: rem-calc(16px);
          font-family: $base-font-family;
          border-radius: 0px;
          padding: 0px 15px;
          color: #a4a4a4;
          margin-bottom: 15px;
          -webkit-appearance: none;
          @media (#{$larger-than-mobile}) {
            color: #383838;
            height: 30px;
            font-size: rem-calc(15px);
          }
        }
        .search-button {
          background-color: $primary-color;
          color: white;
          padding: 2px 7px;
          border: 0;
          cursor: pointer;
          @media (min-width: 640px) and (max-width: 1024px) {
            padding: 6.5px 10px;
          }
        }
      }
      @media (#{$larger-than-desktop}) {
        div.two {
          width: 15%;
          margin-right: 25px;
        }
      }
      @media (#{$larger-than-phablet}) {
        .two {
          width: 28%;
          padding-right: 10px;
          padding-left: 0;
          &:last-child {
            width: 8%;
          }
        }
        .search-button {
          img {
            transition: all 0.3s ease;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
          span {
            display: none;
          }
        }
      }
      @media (max-width: 639.9px) {
        .two {
          margin-bottom: 5px;
        }
        .search-section {
          .search-button {
            margin-top: 0;
            height: 30px;
            border-radius: 23px;
            font-weight: 300;
            font-family: $base-font-family;
            letter-spacing: 0.05em;
            font-size: rem-calc(17px);
            padding: 3px 50px;
            height: 43px;
            margin-top: -20px;
            img {
              display: none;
              @media (#{$larger-than-phablet}) {
                display: block;
              }
            }
          }
        }
      }
    }
    .content-holder {
      min-height: 432px;
      .label {
        h3 {
          font-size: rem-calc(17px);
          font-family: $base-font-family;
        }
        @media (max-width: 639px) {
          display: none;
          &.no-result {
            display: block;
            margin-top: 30px;
          }
        }
      }
      .stores {
        .store-icon {
          background: $primary-color;
          height: 26px;
          position: relative;
          display: inline-block;
          width: 26px;
          line-height: 26px;
          text-align: center;
          border-radius: 50%;
          top: -1px;
          margin-right: 10px;
          @media (#{$larger-than-phablet}) {
            top: -1px;
          }
          img {
            margin-top: -2px;
            @media (#{$larger-than-phablet}) {
              padding-top: 0;
              margin-top: -4px;
            }
          }
        }
        .direction-link {
          display: none;
          font-size: rem-calc(15px);
          font-weight: bold;
          color: $primary-color;
          @media (#{$larger-than-phablet}) {
            display: block;
          }
          span {
            margin-right: 10px;
            margin-left: -10px;
          }
          a {
            transition: all 0.4s ease-in;
            img {
              transition: all 0.4s ease-in;
            }
            &:hover {
              color: #7d4f88;
              img {
                transform: scale(1.15);
              }
            }
          }
        }

        .name {
          h4 {
            font-family: $base-font-family;
            font-size: rem-calc(16px);
            color: $primary-color;
            @media (#{$larger-than-phablet}) {
              font-size: rem-calc(18px);
              line-height: 1.4;
            }
          }
          .diff-color {
            font-family: $base-font-family;
            color: $secondary-color;
            @media (min-width: 639px) {
              display: block;
              margin-left: 2em;
            }
            @media (min-width: 800px) {
              display: inline-block;
              margin-left: 0;
            }
          }
        }
        .address {
          p {
            font-size: rem-calc(16px);
            font-family: $base-font-family;
            line-height: 1.4;
            color: #383838;
            @media (#{$larger-than-phablet}) {
              font-size: rem-calc(15px);
            }
          }
        }
        .telephone {
          a {
            font-size: rem-calc(15px);
            margin-right: 10px;
          }
        }
      }
      @media (#{$larger-than-desktop}) {
        div.four {
          width: 25%;
          padding: 0;
        }
      }
      .no-result {
        padding: 0;
      }
      .mobile-hide {
        display: none;
      }
      @media (#{$larger-than-phablet}) {
        .four {
          width: 33.333333%;
          .mobile-shown {
            display: none;
          }
          .mobile-hide {
            display: block;
          }
        }
        .stores {
          .address {
            p {
              padding-right: 15%;
            }
          }
        }
      }
      @media (max-width: 639px) {
        margin-bottom: 70px;
        .stores {
          .row {
            padding: 30px 0;
            border-bottom: 1px solid #707070;
            margin-right: 40px;
            .name {
              padding-bottom: 10px;
            }
          }
          :last-child {
            .row {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
//how it works page
.how-it-works-container {
  width: 100%;
  background-position: center;
  background-image: url(/assets/img/banners/guys-hiking.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 140px;
  min-height: 100%;
  padding-top: 200px;
  height: 497px;
  .hidden {
    display: none;
  }
  .content {
    h1 {
      font-size: rem-calc(46px);
      font-family: $base-font-family;
      margin-bottom: 25px;
      color: white;
    }
    p {
      background-color: $secondary-color;
      color: white;
      font-weight: bold;
      font-size: rem-calc(26px);
      font-family: $base-font-family;
      padding: 5px 0;
      margin: 15px 25px;
    }
  }

  @media (#{$larger-than-tablet}) {
    position: relative;
  }
  @media (#{$larger-than-desktop}) {
    height: 550px;
    background-image: url(/assets/img/banners/guys-hiking@2x.jpg);
    .content {
      h1 {
        font-size: rem-calc(81px);
      }
      p {
        font-size: rem-calc(40px);
      }
    }
  }
}
.steps-container {
  padding-top: 200px;
  .container-flex {
    max-width: 1320px;
    padding: 0 50px;
    @media (max-width: 833.9px) {
      text-align: center;
    }
    @media (max-width: 639.9px) {
      text-align: left;
      padding: 0 30px;
    }
    .title {
      .header {
        font-size: rem-calc(30px);
        font-family: $base-font-family;
        padding-bottom: 20px;
      }
      .subHeader {
        font-family: $base-font-family;
        font-size: rem-calc(22px);
        padding-bottom: 40px;
      }
      p {
        font-size: rem-calc(20px);
        font-family: $base-font-family;
      }
      @media (#{$larger-than-desktop}) {
        h2 {
          font-size: rem-calc(40px);
        }
        h4 {
          font-size: rem-calc(25px);
        }
      }
      @media (max-width: 833.9px) {
        .header {
          font-size: rem-calc(25px);
          line-height: 17px;
          padding-bottom: 0;
          margin-bottom: 15px;
        }
        .subHeader {
          font-size: rem-calc(20px);
          line-height: 17px;
          padding-bottom: 30px;
        }
        p {
          max-width: 508px;
          text-align: center;
          margin: 0 auto;
        }
      }
      @media (max-width: 639.9px) {
        .header {
          font-size: rem-calc(30px);
          line-height: 32px;
        }
        .subHeader {
          font-size: rem-calc(22px);
          line-height: 26px;
        }
        p {
          text-align: left;
          margin: 0;
        }
      }
    }
    .steps-section {
      padding-top: 40px;
      margin-bottom: 50px;
      p {
        font-family: $base-font-family;
        font-size: rem-calc(20px);
      }
      .ltr-step {
        display: flex;
        .description {
          padding-top: 50px;
          width: 66%;
          h3 {
            font-size: rem-calc(30px);
          }
          .step-description {
            padding-top: 40px;
            width: 428px;
          }
          .action {
            display: block;
            margin-top: 60px;
            .button {
              padding: 11px;
              width: 216px;
              margin-right: 25px;
              font-size: rem-calc(18px);
            }
          }
        }
        .mobile {
          display: none;
        }
        .step-image {
          text-align: center;
          margin-top: 50px;
          width: auto;
          height: auto;
          img {
            width: 314px;
            height: 314px;
          }
        }
        @media (#{$larger-than-desktop}) {
          .step-image {
            text-align: start;
            margin-top: 0;
            img {
              width: 428px;
              height: 428px;
            }
          }
        }
        @media (max-width: 1024.9px) {
          .description {
            h3 {
              font-size: rem-calc(25px);
              line-height: 17px;
            }
            p {
              font-size: rem-calc(15px);
              line-height: 17px;
            }
            .first {
              max-width: 300px;
            }
            .second {
              max-width: 345px;
            }
            .action {
              display: block;
              margin-top: 30px;
              .button {
                width: 200px;
                font-size: rem-calc(17px);
                margin-right: 10px;
              }
            }
          }
          .step-image {
            img {
              width: 214px;
              height: 214px;
            }
          }
        }
        @media (max-width: 833.9px) {
          flex-direction: column;
          align-items: center;
          .description {
            padding: 0;
            order: 2;
            h3 {
              font-size: rem-calc(25px);
              font-family: $base-font-family;
              margin-bottom: 10px;
              margin-top: 40px;
            }
            .first {
              margin: 0 auto;
            }
            .second {
              margin: 0 auto 17px;
            }
            .action {
              display: none;
            }
          }
          .mobile {
            order: 3;
            display: block;
            text-align: center;
            margin-top: 40px;
            .button {
              padding: 11px;
              width: 200px;
              margin-bottom: 20px;
              font-size: rem-calc(17px);
              &.secondary {
                margin-right: 10px;
              }
            }
          }
          .step-image {
            order: 1;
            margin-top: 0;
          }
        }
        @media (max-width: 639.9px) {
          align-items: flex-start;
          .description {
            order: 1;
            width: 100%;
            p {
              max-width: none !important;
              width: 100% !important;
            }
          }
          .step-image {
            margin: 30px auto 15px;
          }
          .mobile {
            margin: 40px auto 0;
            .button {
              margin-right: 0;
              width: 216px;
            }
          }
        }
      }

      .rtl-step {
        display: flex;
        align-items: center;
        margin: 50px 0;
        .step-image {
          display: block;
          width: 64%;
          text-align: start;
          margin-top: 0;
          img {
            width: 428px;
            height: 428px;
          }
        }
        .mobile {
          display: none;
        }
        .mobile-image {
          display: none;
        }
        .description {
          h3 {
            font-size: rem-calc(25px);
            font-family: $base-font-family;
            margin-bottom: 40px;
            margin-top: 40px;
          }
          .action {
            margin-top: 60px;
            display: block;
            .button {
              padding: 11px;
              width: 216px;
            }
          }
        }
        @media (#{$larger-than-desktop}) {
          .description {
            width: 414px;
            h3 {
              font-size: rem-calc(30px);
            }
          }
        }
        @media (max-width: 1024.9px) {
          align-items: center;
          margin: 30px 0;
          .description {
            h3 {
              font-size: rem-calc(25px);
              line-height: 17px;
            }
            p {
              font-size: rem-calc(15px);
              line-height: 17px;
              max-width: 345px;
            }
            .action {
              display: block;
              margin-top: 30px;
              .button {
                width: 200px;
                font-size: rem-calc(17px);
              }
            }
          }
          .step-image {
            display: flex;
            align-items: center;
            img {
              width: 214px;
              height: 214px;
            }
          }
        }
        @media (max-width: 833.9px) {
          flex-direction: column;
          align-items: center;
          .description {
            padding: 0;
            order: 2;
            .action {
              display: none;
            }
          }
          .step-image {
            order: 1;
            width: auto;
            height: auto;
            text-align: center;
          }
          .mobile {
            order: 3;
            margin-top: 50px;
            text-align: center;
            display: block;
            .button {
              padding: 10px 20px;
              width: 200px;
              margin-bottom: 25px;
            }
          }
          .mobile-image {
            text-align: center;
            margin-top: 50px;
            img {
              width: 314px;
              height: 314px;
            }
          }
        }
        @media (max-width: 639.9px) {
          align-items: flex-start;
          .description {
            order: 1;
          }
          .step-description {
            max-width: none !important;
            width: 100% !important;
          }
          .step-image {
            margin: 30px auto 15px;
            order: 2;
          }
          .mobile {
            margin: 50px auto 0;
          }
        }
      }
      @media (#{$larger-than-desktop}) {
        //  padding-top: 75px;
        .container-flex {
          padding: 0 0 0 3%;
        }
      }
    }
  }
  .banner-step {
    width: 100%;
    background-position: bottom;
    background-image: url(/assets/img/HIW-img1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 12%;
    .hidden {
      display: none;
    }
    .description {
      h3 {
        font-size: rem-calc(25px);
        font-family: $base-font-family;
      }
      p {
        font-size: rem-calc(20px);
        font-family: $base-font-family;
      }
      .action {
        margin-top: 80px;
        .button {
          padding: 11px;
          width: 200px;
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }
    @media (#{$larger-than-desktop}) {
      background-image: url(/assets/img/HIW-banner-step.jpg);
      background-position: center;
      height: 510px;
      .description {
        width: 565px;
        padding-top: 85px;
        h3 {
          font-size: rem-calc(30px);
          margin-bottom: 56px;
        }
        p {
          max-width: 526px;
        }
        .action {
          text-align: start;
          .button {
            margin-bottom: 0;
            width: 216px;
            padding: 11px;
            font-size: rem-calc(18px);
            &.secondary {
              margin-right: 25px;
            }
          }
        }
      }
    }
    @media (max-width: 1024.9px) {
      background-image: url(/assets/img/HIW-img834.png);
      .description {
        padding-top: 38px;
        padding-bottom: 25px;
        h3 {
          font-size: rem-calc(25px);
          line-height: 30px;
          max-width: 315px;
          margin-bottom: 30px;
        }
        .step-description {
          font-size: rem-calc(15px);
          line-height: 17px;
          max-width: 315px;
        }
        .action {
          margin-top: 50px;
          .button {
            padding: 8px;
            width: 195px;
            margin-bottom: 20px;
            &.secondary {
              margin-right: 10px;
            }
          }
        }
      }
    }
    @media (max-width: 833.9px) {
      background-image: url(/assets/img/HIW-img640.png);
      .description {
        text-align: left;
      }
    }
    @media (max-width: 639.9px) {
      .action {
        text-align: center;
        .button {
          width: 226px;
          margin-right: 0;
          margin-bottom: 10px;
          padding: 11px;
          font-size: rem-calc(20px);
        }
      }
    }
    @media (max-width: 414px) {
      background-image: url(/assets/img/HIW-img1.jpg);
    }
  }
  @media (max-width: #{$tablet-min-width}) {
    padding-top: 65px;
  }
  @media (max-width: 350px) {
    .container-flex {
      padding: 0 25px;
    }
  }
}
.get-started {
  width: 100%;
  background-color: $secondary-color;
  color: white;
  .description {
    @media (min-width: 1300px) {
      flex-basis: 74.85%;
      padding: 0 10px;
    }
    @media (max-width: 1300px) and (min-width: 690px) {
      flex-basis: 51%;
      padding: 0 10px;
      margin-right: 100px;
    }
    span {
      color: white;
      font-size: rem-calc(34px);
      line-height: rem-calc(40px);
      font-family: $base-font-family;
      display: block;
      @media (max-width: 1300px) and (min-width: 690px) {
        font-size: rem-calc(25px);
        line-height: rem-calc(33px);
        display: inline;
      }
      @media (max-width: 690px) {
        font-size: rem-calc(25px);
        line-height: rem-calc(35px);
      }
    }
    @media (max-width: 690px) {
      text-align: center;
      margin-bottom: 35px;
      display: inline;
      width: 89%;
    }
  }
  .button {
    padding: 13px 40px;
    float: right;
    width: 255px;
    font-family: "bryant", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    @media (max-width: 1024px) and (min-width: 640px) {
      font-size: rem-calc(17px);
      line-height: rem-calc(21px);
      width: 200px;
    }
    @media (max-width: 690px) {
      font-size: rem-calc(20px);
      line-height: rem-calc(27px);
      width: 226px;
    }
  }
}
.edit-action {
  & img {
    height: 24px;
    width: 24px;
  }
}
// .coverage_map{
//   width:150%;
// }
.subtitle2 {
  font-family: $base-font-family;
  font-size: rem-calc(20px);
  //font-weight: 600;
}
.subtitle3 {
  font-family: $base-font-family;
  font-size: rem-calc(20px);
  width: 73%;
  //font-weight: 600;
}
.title1 {
  font-size: rem-calc(24px);
}
.ptc {
  width: 100%;
  font-size: 16px;
}
.extra1 {
  font-size: 18px;
  font-weight: bold;
  //text-align: center;
  width: 70%;
  font-family: $base-font-family;
  @media (max-width: 579px) and (min-width: 503px) {
    //margin-left:30px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 375px) and (min-width: 320px) {
    text-align: center;
    width: 100%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 1100px) and (min-width: 901px) {
    width: 100%;
  }
}
.margin-top-50 {
  @media (min-width: $desktop-min-width) {
    margin-top: 50px;
  }
  @media (max-width: $desktop-min-width) and (min-width: $phablet-min-width) {
    width: 100%;
    padding-left: 0;
    max-width: 211px;
    margin: 11px auto;
  }
  @media (max-width: $phablet-min-width) {
    width: 100%;
    padding-left: 0;
    max-width: 211px;
    margin: 11px auto;
  }
}
.replacement-margins {
  @media (min-width: $desktop-min-width) {
    margin-top: 50px;
  }
  @media (max-width: $desktop-min-width) and (min-width: $phablet-min-width) {
    width: 100%;
    padding-left: 0;
    margin: 11px auto;
  }
  @media (max-width: $phablet-min-width) {
    width: 100%;
    padding-left: 0;
    margin: 11px auto;
  }
}
.network-title {
  font-family: $base-font-family;
  object-fit: contain;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #383838;

  @media (min-width: $desktop-min-width) {
    width: 549px;
    height: 30px;
    font-size: rem-calc(34px);
    line-height: 1.18;
    text-align: left;
  }
  @media (max-width: $desktop-min-width) and (min-width: $phablet-min-width) {
    //width: 404px;
    height: 22px;
    font-size: rem-calc(25px);
    line-height: 1;
    text-align: center;
    margin-bottom: 29px;
  }
  @media (max-width: 377px) {
    height: 53px;
    text-align: center;
    width: 100%;
    padding-left: 0;
    max-width: 510px;
    margin-bottom: 30px;
    font-size: rem-calc(20px);
  }
  @media (min-width: 377px) and (max-width: $phablet-min-width) {
    height: 15px;
    text-align: center;
    width: 100%;
    padding-left: 0;
    max-width: 510px;
    margin-bottom: 30px;
    font-size: rem-calc(20px);
  }
}
.network-description {
  font-family: $base-font-family;
  font-size: rem-calc(20px);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #383838;

  @media (min-width: $desktop-min-width) {
    width: 410px;
    height: 66px;
    text-align: left;
  }
  @media (max-width: $desktop-min-width) and (min-width: $phablet-min-width) {
    height: 69px;
    text-align: center;
    width: 100%;
    padding-left: 0;
    max-width: 510px;
    margin: 0 auto;
  }
  @media (max-width: $phablet-min-width) {
    height: 69px;
    text-align: center;
    width: 100%;
    padding-left: 0;
    max-width: 295.4px;
    margin: 0 auto;
    font-size: rem-calc(16px);
  }
}
.maxWidth {
  @media (min-width: $desktop-min-width) {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }
}
.network-coverage-section2 {
  @media (min-width: $desktop-min-width) {
    margin: 0px 46px;
    display: inline-flex;
  }
}
.highlight-none {
  background-color: white;
}
.flex-phone-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
.steps {
  height: 100%;
  font-size: rem-calc(15px);
  font-family: $base-font-family;
  text-align: center;
}
.step-image {
  height: 370px;
  filter: contrast(90%);
  @media (max-width: 640.9px) {
    height: 322px;
  }
}
.tc-txt {
  color: green;
  text-decoration: underline;
}
.int-ol {
  li {
    margin: 12px 0px;
  }
}
.download-icon {
  width: 26px;
  height: 19px;
}
