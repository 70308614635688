// todo rework when needed
.pagination {
  margin-top: 30px;

  ul.pagination {
    border-radius: 5px;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;

    @media (#{$larger-than-mobile}) {
      width: auto;
      float: right;
    }

    .pagination-previous {
      background: $primary-color;
      height: 24px;
      width: 24px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        background: $black-shade-300;
      }

      &.circle {
        border-radius: 50%
      }

      a {
        overflow: hidden;
        text-indent: -99px;
        white-space: nowrap;
        padding: 2px;
        margin-bottom: 3px;
        margin-top: 7px;
        margin-right: 6px;
        margin-left: 0;
        float: right;
        width: 10px;
        height: 10px;
        text-align: center;
        transform: rotate(-45deg);
        -webkit-transform: rotate(45deg);
        border: 2px solid $white;
        border-right: 0;
        border-top: 0;

        &.disabled {
          color: $black-shade-300;
        }
      }
    }

    .pagination-next {
      background: $primary-color;
      height: 24px;
      width: 24px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        background: $black-shade-300;
      }

      &.circle {
        border-radius: 50%
      }

      a {
        overflow: hidden;
        text-indent: -99px;
        white-space: nowrap;
        padding: 2px;
        margin-bottom: 3px;
        margin-top: 7px;
        margin-right: 9px;
        margin-left: 0;
        float: right;
        width: 10px;
        height: 10px;
        text-align: center;
        transform: rotate(-45deg);
        -webkit-transform: rotate(45deg);
        border: 2px solid $white;
        border-left: 0;
        border-bottom: 0;

        &.disabled {
          color: #939191;
        }
      }
    }
  }

  li {
    display: inline-block;
    font-size: rem-calc(17px);
    float: none;
    margin: 0;

    @media (#{$larger-than-phablet}) {
      float: left;
      font-size: rem-calc(13px);
    }

    &.active {
      a {
        color: $primary-color;
      }
    }

    &.disabled {
      a {
        cursor: default;
      }
    }

    a {
      color: $black-shade-300;
      float: left;
      padding: 5px 10px;
      text-decoration: none;

      @media (#{$larger-than-mobile}) {
        padding: 7px 10px;
      }
    }
  }
}