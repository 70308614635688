// @font-face {
//   font-family: 'hero-mobile';
//   src: url('/../../assets/fonts/hero-mobile.eot?awqv3m');
//   src: url('/../../assets/fonts/hero-mobile.eot?awqv3m#iefix') format('embedded-opentype'),
//   url('/../../assets/fonts/hero-mobile.woff2?awqv3m') format('woff2'),
//   url('/../../assets/fonts/hero-mobile.ttf?awqv3m') format('truetype'),
//   url('/../../assets/fonts/hero-mobile.woff?awqv3m') format('woff'),
//   url('/../../assets/fonts/hero-mobile.svg?awqv3m#freeup') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// [class^="icon-"], [class*=" icon-"] {
//   &:not(.mydrpicon) {
//     font-family: 'hero-mobile' !important;
//   }
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
.icon-sim-card:before {
  content: "\e90f";
}
.icon-trophy:before {
  content: "\e911";
}
.icon-award:before {
  content: "\e900";
}
.icon-download-app:before {
  content: "\e904";
}
.icon-us-map:before {
  content: "\e913";
}
.icon-cart-2:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-twitter:before {
  content: "\e905";
}

.icon-instagram:before {
  content: "\e906";
}

.icon-menu-2:before {
  content: "\e907";
}

.icon-left-arrow:before {
  content: "\e908";
}

.icon-caret-down:before {
  content: "\25bc";
}

.icon-caret-up:before {
  content: "\25B2";
}

.icon-settings:before {
  content: "\e90b";
}

.icon-credit-card:before {
  content: "\e90c";
}

.icon-help-circle:before {
  content: "\e90d";
}

.icon-info-circle:before {
  content: "\e90e";
}

.icon-location:before {
  content: "\e90f";
}
.icon-menu:before {
  content: "\e911";
}

.icon-minus:before {
  content: "\e912";
}

.icon-mobile:before {
  content: "\e913";
}

.icon-plus:before {
  content: "\e914";
}

.icon-cart:before {
  content: "\e915";
}

.icon-paypal:before {
  content: "\e916";
}

.icon-chevron-thin-down:before {
  content: "\e917";
}

.icon-chevron-thin-up:before {
  content: "\e917";
  // orient it 180 degrees
}

.icon-close:before {
  content: "\2716";
  font-style: normal;
}

.icon-check:before {
  content: "\2713";
}

.icon-alert:before {
  content: "\e91a";
}

.icon-sim-card:before {
  content: "\e91b";
}

.icon-thin-left-arrow:before {
  content: "\e91c";
}

.icon-thin-right-arrow:before {
  content: "\e91d";
}

.icon-dot:before {
  content: "\e91e";
}

.icon-bell:before {
  content: "\e901";
}

.img-icon-arrow-down {
  /* use on div */
  display: inline-block;
  width: 11px;
  height: 10px;
  background-image: url(/assets/img/arrow-down.svg);
  background-repeat: no-repeat;
  background-position-x: 45%;
  background-position-y: 2px;
}

i.arrow {

  border: solid black;

  &.primary {
    border: solid $primary-color;
  }

  &.secondary {
    border: solid $secondary-color;
  }

  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-bottom: 3px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

